<app-header></app-header>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer></app-footer>


<div class="rightPanel d-none">
  <button type="button" class="btn btn-demo text-white font-weight-bold" data-toggle="modal"
    data-target=".bd-example-modal-lg" (click)="formDisplay = true">
    Contact Us Form
  </button>
</div>



<!-- Modal -->
<div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-header pt-1 pb-1">
        <h4 class="modal-title" id="myModalLabel2">Form</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>

      <div class="modal-body">
        <app-queryform></app-queryform>
      </div>

    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div><!-- modal -->



<!-- Modal -->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pt-1 pb-1 text-white">
        <h4 class="modal-title" id="exampleModalLongTitle">Query Form</h4>
        <button type="button" id="closeButton" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-queryform  (formSuccess)="getSuccess($event)"></app-queryform>
      </div>
      <!-- <div class="modal-footer">
		  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
		  <button type="button" class="btn btn-primary">Save changes</button>
		</div> -->
    </div>
  </div>
</div>
<!-- #006E51 -->
<section class="process pt-5 pb-5" id="process">
    <div class="container">
        <div class="section-title">
            <h2>MSME Cluster Development Process</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                        hic quas.</p> -->
        </div>
        <div class="timeline">
            <div class="customeContainer left">
                <div class="date">Step 1</div>
                <!-- <i class="icon fa fa-home"></i> -->
                <i class="icon fas fa-key"></i>
                <div class="content">
                    <h2>Cluster Identification
                    </h2>
                    <p>
                        Through DICs organize awareness campaigns & workshop for identification of members & clusters.
                    </p>
                </div>
            </div>
            <div class="customeContainer right">
                <div class="date">Step 2</div>
                <!-- <i class="icon fa fa-gift"></i> -->
                <i class="icon far fa-paper-plane"></i>

                <div class="content">
                    <h2>Prepare DSR
                    </h2>
                    <p>
                        Conduct cluster & market Study and identify required Soft interventions.
                    </p>
                </div>
            </div>

            <div class="customeContainer left">
                <div class="date">Step 3</div>
                <!-- <i class="icon fa fa-running"></i> -->
                <i class="icon fas fa-rocket"></i>
                <div class="content">
                    <h2>Assist in approvals & Company formation
                    </h2>
                    <p>
                        Assist members in formation of SPV and support SPV in securing approvals.
                    </p>
                </div>
            </div>
            <div class="customeContainer right">
                <div class="date">Step 4</div>
                <!-- <i class="icon fa fa-cog"></i> -->
                <i class="icon fas fa-bullhorn"></i>

                <div class="content">
                    <h2>Prepare DPR
                    </h2>
                    <p>
                        Identification of technology , cost estimate, financial feasibility etc.
                    </p>
                </div>
            </div>
            <div class="customeContainer left">
                <div class="date">Step 5</div>
                <!-- <i class="icon fa fa-certificate"></i> -->
                <i class="icon fas fa-link"></i>

                <div class="content">
                    <h2>Support in Final Approval
                    </h2>
                    <p>
                        Assitance to SPV on Final approval by State Government.
                    </p>
                </div>
            </div>
            <div class="customeContainer right">
                <div class="date">Step 6</div>
                <!-- <i class="icon fa fa-cog"></i> -->
                <i class="icon fas fa-rocket"></i>

                <div class="content">
                    <h2>Procurement Support

                    </h2>
                    <p>
                        Preparation of Tender documents, bid process management, onboarding of vendors, project monitoring and support in getting grant from State Government.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<form [formGroup]="MsmeForm" (ngSubmit)="onSubmit()">

    <div class="col-md-12">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">

                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="exampleRadios1" formControlName="customerType"
                            value="Existing Enterprenuer" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Existing Enterprenuer </label>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="exampleRadios2" value="New Enterprenuer"
                        formControlName="customerType">
                    <label class="form-check-label" for="exampleRadios2">
                        New Enterprenuer
                    </label>
                </div>
            </div>
        </div>
        <span class="text-danger" *ngIf="(f.customerType.touched || submitted) && f.customerType.errors?.required">
            Enterprenuer type selection is required
            <hr>
        </span>
    </div>



    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">

                <div class="form-group">
                    <label>Name : <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" id="inputName" formControlName="name"
                        [ngClass]="{'is-invalid': submitted && f.name.errors }" placeholder="Name">
                    <span class="text-danger" *ngIf="(f.name.touched || submitted) && f.name.errors?.required">
                        Name is required
                    </span>
                </div>
            </div>

            <div class="col-md-6">

                <div class="form-group">
                    <label>Designation : <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" id="inputDesignation" formControlName="designation"
                        [ngClass]="{'is-invalid': submitted && f.designation.errors }" placeholder="Designation">

                    <span class="text-danger"
                        *ngIf="(f.designation.touched || submitted) && f.designation.errors?.required">
                        Designation is required
                    </span>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Mobile Number : <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" id="mobilenumber" formControlName="mobilenumber"
                        [ngClass]="{'is-invalid': submitted && f.mobilenumber.errors }" maxlength="10" minlength="10"
                        placeholder="Mobile Number"  (keypress)="numberOnly($event)"  (blur)="mobileValidCheck($event)" >

                    <!-- <span class="text-danger"
                        *ngIf="(f.mobilenumber.touched || submitted) && f.mobilenumber.errors?.required">
                        Mobile Number is required
                    </span>
                    <span class="text-danger" *ngIf="f.mobilenumber.errors.minlength">
                        Mobile Number must be at least 10 characters long.
                    </span> -->

                    <div *ngIf="(f.mobilenumber.touched || 
                        f.mobilenumber.dirty || submitted) && f.mobilenumber.invalid">
                            <span class="text-danger" *ngIf="f.mobilenumber.errors.required">
                                Mobile Number is required
                            </span>
                            <span class="text-danger" *ngIf="f.mobilenumber.errors.minlength">
                                Mobile Number must be at least 10 characters long.
                            </span>
                        </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Email Id : <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" id="inputEmail"  [pattern]="emailPattern"  formControlName="email"
                        [ngClass]="{'is-invalid': submitted && f.email.errors }" placeholder="Email Id">

                    <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                        Email Id is required
                    </span>
                    <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.pattern">
                        Enter Valid Email
                    </span>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">

                <div class="form-group">

                    <label>District : <span class="mandatory">*</span></label>
                    <select type="dropdown" class="form-control" id="district" formControlName="district"
                        [ngClass]="{'is-invalid': submitted && f.district.errors }" placeholder="District">
                        <option value="" selected>Select District</option>
                        <option value="ANANTHAPUR">ANANTHAPUR</option>
                        <option value="CHITTOOR">CHITTOOR</option>
                        <option value="EAST GODAVARI">EAST GODAVARI</option>
                        <option value="GUNTUR">GUNTUR</option>
                        <option value="KRISHNA">KRISHNA</option>
                        <option value="KURNOOL">KURNOOL</option>
                        <option value="PRAKASAM">PRAKASAM</option>
                        <option value="SPSR NELLORE">SPSR NELLORE</option>
                        <option value="SRIKAKULAM">SRIKAKULAM</option>
                        <option value="VISAKHAPATNAM">VISAKHAPATNAM</option>
                        <option value="VIZIANAGARAM">VIZIANAGARAM</option>
                        <option value="WEST GODAVARI">WEST GODAVARI</option>
                        <option value="YSR KADAPA">YSR KADAPA</option>
                    </select>

                    <span class="text-danger" *ngIf="(f.district.touched || submitted) && f.district.errors?.required">
                        District is required
                    </span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">

                    <label>Interested Sector : <span class="mandatory">*</span></label>
                    <select type="dropdown" class="form-control" id="sector" formControlName="sector"
                        [ngClass]="{'is-invalid': submitted && f.sector.errors }" placeholder="Interested Sector">
                        <option value="" selected>Choose Sector</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Sector">Sector</option>
                    </select>

                    <span class="text-danger" *ngIf="(f.sector.touched || submitted) && f.sector.errors?.required">
                        Interested Sector is required
                    </span>
                </div>
            </div>

        </div>

        <div class="form-group">
            <label>How can we assit you/Post your Query  : <span class="mandatory">*</span></label>
            <textarea type="text" class="form-control" id="query" formControlName="query"
                [ngClass]="{'is-invalid': submitted && f.query.errors }"
                placeholder="How can we assit you/Post your Query"></textarea>

            <span class="text-danger" *ngIf="(f.query.touched || submitted) && f.query.errors?.required">
                This field is required
            </span>
        </div>

        <!-- <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required></re-captcha> -->
        <!-- <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
          <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
        </div> -->

        <!-- <span class="text-danger"
                                    *ngIf="(f.recaptcha.touched || submitted) && f.recaptcha.errors?.required">
                                    This field is required
                                </span> -->

        <!-- <button class="g-recaptcha" 
        data-sitekey="6LdI-modAAAAANg5rP0ckuZBwx3ZJZcN1SDxVoM9" 
        data-callback='onSubmit' 
        data-action='submit'>Submit</button> -->
    </div>
    <div class="text-center">
        <button type="submit" class="btn btn-primary">Submit</button>
    </div>

</form>
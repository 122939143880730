<section class="services pt-5 pb-5" id="services">
    <div class="container">
        <div class="section-title">
            <h2>Services</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                    hic quas.</p> -->
        </div>
        <div class="row">

            <div class="col-md-4 col-sm-6">

                <div class="serviceBox blue">

                    <div class="service-content">

                        <div class="service-icon">
                            <i class="fa fa-globe"></i>
                        </div>

                        <h3 class="title">Micro Industries</h3>

                        <p class="description">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.
                        </p>
                    </div>
                    <a class="read-more" routerLink="/microindustries">Read More</a>
                </div>
            </div>

            <div class="col-md-4 col-sm-6">

                <div class="serviceBox green">

                    <div class="service-content">

                        <div class="service-icon">
                            <i class="fa fa-users"></i>
                        </div>

                        <h3 class="title">Small Industries</h3>


                        <p class="description">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.
                        </p>
                    </div>
                    <a class="read-more" href="">Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">

                <div class="serviceBox red">

                    <div class="service-content">

                        <div class="service-icon">
                            <i class="fa fa-users"></i>
                        </div>

                        <h3 class="title">Medium Industries</h3>


                        <p class="description">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.
                        </p>
                    </div>
                    <a class="read-more" href="">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
import { Component } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  documentClose: any;
  formDisplay: boolean = false;
  title = 'msmeproject';
  onActivate(event:any) {
    // window.scroll(0,0);
  document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}
ngOnInit() {
  AOS.init();
}
  getSuccess(event){
    console.log(event)
    // this.formDisplay = false
    this.documentClose = document.getElementById('closeButton') as HTMLElement
    this.documentClose.click()
  }
}

<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>aspirational affected districts</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Extremism Affected Districts</a></li>
                        <li><a style="color: #fff;">Aspirational Affected Districts</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description </h2>
                </div>
                <div class="section_content">
                    <p>
                        The following 3 districts have been notified by Government of India as an Aspirational districts and will support
                        with a financial assistance of 90% grant i.e., upto Rs. 2.5 cr.
                    </p>
                </div>
                <style>
                    .AllList {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .list {
                        /* background: red; */
                        width: auto;
                        /* max-width: 170px; */
                        display: flex;
                        /* margin: 0 10px; */
                        /* justify-content: space-between; */
                        padding: 0 10px;
                    }
                    .list .number {
                        color: #fff;
                        background: green;
                        border-radius: 50%;
                        padding: 20px;
                    }
                    .btn {
                        cursor: default !important;
                    }
                </style>
                <style>
                    .btn-label {border-radius: 50%; border: 2px solid #fff;position: relative;left: -12px;
                        display: inline-block;padding: 6px 8px;background: rgba(0,0,0,0.15);
                        /* border-radius: 3px 0 0 3px; */
                    }
.btn-labeled {padding-top: 0;padding-bottom: 0;}
.btn { margin-bottom:10px; }
.list .btn {
    border-radius: 24px;
}
                </style>

                <div class="AllList">
                    <div class="list">
                        <div class="btn btn-labeled btn-ysrkadapa">
                            <span class="btn-label ysrkadapaLabel">01</span>YSR Kadapa</div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled btn-visakhapatnam">
                            <span class="btn-label visakhapatnamLabel">02</span>Visakhapatnam</div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled  btn-vizianagaram">
                            <span class="btn-label vizianagaramLabel">03</span>Vizianagaram</div>
                    </div>
                </div>
                <div class="content d-none">
                    <ul>
                        <li>YSR Kadapa</li>
                        <li>Visakhapatnam</li>
                        <li>Vizianagaram</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Ongoing Clusters</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Projects</a></li>
                        <li><a style="color: #fff;">Ongoing Clusters</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="section_bg py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table
                    table-bordered bg-white">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>CFC Name</th>
                            <th>Place</th>
                            <th>GOI Grant
                                (In Lakh)</th>
                            <th>GoAP Grant
                                (In Lakh)</th>
                            <th>SPV
                                Contribution
                                (In Lakh)
                            </th>
                            <th>Total (In
                                Lakh)</th>
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>1</td>
                            <td>Printing
                                Cluster
                            </td>
                            <td>Kakinada, Dt
                            </td>
                            <td>1033.20</td>
                            <td>295.20</td>
                            <td>147.60</td>
                            <td>1476.00</td>
                            <td>Under 
                                Implementation</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Pulses &
                                pulses
                                products
                                cluster
                            </td>

                            <td>Konaseema Dt </td>
                            <!-- <td>Dr. B.R Ambedkar Konaseema Dt </td> -->
                            <td>1038.14</td>
                            <td>296.61</td>
                            <td>148.31</td>
                            <td>1483.06</td>
                            <td>Under 
                                Implementation</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Furniture Manufacturing Cluster
                            </td>
                            <td>Rajamundry,
                                East Godavari Dt                                
                            </td>
                            <td>1048.88</td>
                            <td>299.68</td>
                            <td>149.85</td>
                            <td>1498.41</td>
                            <td>Under Implementation</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Hosiery and Readymade Garments Cluster
                            </td>
                            <td>
                                Nellore, SPS Nellore Dt
                            </td>
                            <td>576.08</td>
                            <td>164.6</td>
                            <td>82.3</td>
                            <td>822.98</td>
                            <td>Under 
                                Implementation</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Gold Ornaments Cluster
                            </td>
                            <td>Jaggayyapet , NTR Dt
                            </td>
                            <td>614.52</td>
                            <td>80</td>
                            <td>105.48 </td>
                            <td>800</td>
                            <td>Under 
                                Implementation</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
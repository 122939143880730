import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-processfive',
  templateUrl: './processfive.component.html',
  styleUrls: ['./processfive.component.css']
})
export class ProcessfiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

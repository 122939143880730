import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-directors',
  templateUrl: './directors.component.html',
  styleUrls: ['./directors.component.css']
})
export class DirectorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

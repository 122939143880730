<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>RTI Acts</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">About</a></li>
                        <li><a style="color: #fff;">RTI Acts</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>


<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="schemes_content_heading">
                    <h2>Description</h2>
                </div> -->
                <div class="section_content">
                    <p>
                        Under Sec. 6 of the Act a person who desires to obtain any information under this Act shall make a request in writing or through electronic means in Telugu, Hindi or English accompanying such fee as may be prescribed and addressed to State Public Information Officer / Assistant Public Information Officer concerned.
                    </p>
                </div>
                <div class="schemes_content_heading">
                    <h2>Time limit for furnishing information</h2>
                </div>
                <div class="section_content">
                    <p>
                        State Public Information Officer shall on receipt of a request under section 6 shall, as expeditiously as possible and in any case within thirty days of the receipt of the request, either provide the information on payment of such fee as may be prescribed or reject the request for any of the reasons specified in section 8 and 9 of the Act.
                    </p>
                </div>
                <div class="schemes_content_heading">
                    <h2>Appeal Provision</h2>
                </div>
                <div class="section_content">
                    <p>
                        Any person aggrieved by the order of State Public Information Officer can file appeal before appellate authority.
                    </p>
                </div>
                <div class="schemes_content_heading">
                    <h2>Fee Payable</h2>
                </div>
                <div class="section_content">
                    <p>
                        Under the Rule 3 of the A.P. Right to Information (Regulation of fee and cost) Rules 2005, the application fee should 
                        accompany request for obtaining information is as under:
                    </p>
                    <p>
                        A request for obtaining information under Sub-Sec (1) of section 6 shall be accompanied by an application fee
                         of Rs. 10/- by way of cash or by demand draft or by bankers Cheque payable to the Accounts Officers or any other 
                         duly authorized officer of the Public Authority.
                    </p>
                    <p>
                        Under rule 3 of the said rules fee to be charged for providing Information is as under :-
                    </p>
                    <p>
                        For providing information under Sub-Sec (1) or Sub Sec(5) of Section 7, a fee shall be charged by way of cash or demand draft or bankers cheque payable to the Accounts officer or any other duly authorized officer of the Public Authority against proper receipt at the following rates:-
                    </p>
                </div>
                <div class="schemes_content_heading">
                    <h2>A) Priced material:</h2>
                </div>
                <div class="section_content">
                    <p>
                        Publications printed matter, text, maps, plans, floppies, CDs, samples, models, or material in any other form, which are priced. The sale price thereof:
                    </p>
                </div>
                <div class="schemes_content_heading">
                    <h2>B) Other than priced material:</h2>
                </div>
                <div class="section_content">
                    <div class="content">
                        <ul>
                            <li>
                                Material in printed form or text form ( in A4 or A3 size paper) Rs.2/- per each page per copy:
                            </li>
                            <li>
                                Material in printed or text from in larger that A4 or A3 size paper actual cost there-of.
                            </li>
                            <li>
                                Maps and plans – actual cost there of:
                            </li>
                            <li>
                                Information in Electronic format viz. floppy, CD or DVD:
                            </li>
                            <li>
                                Rupees fifty for floppy of 1.44 MB
                            </li>
                            <li>
                                Rupees one hundred for CD of 700 MB
                            </li>
                            <li>
                                Rupees two hundred for DVD
                            </li>
                            <li>
                                Samples and models- actual cost thereof.
                            </li>
                            <li>
                                Inspection of records – no fee for the first hour, and a fee of rupees five for each fifteen minutes ( or fraction thereof) thereafter.
                            </li>
                            <li>
                                Material to be sent by post – the actual postal charges in addition to the charges payable as per these rules.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
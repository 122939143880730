<style>
    .breadcrumb {
        padding: 5px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    
    .breadcrumb>li:first-child {
        padding-left: 10px;
    }
    
    .breadcrumb>li {
        display: inline-block;
        background: #f5f5f5;
        color: #9d9d9d;
        border-radius: 25px;
        padding: 1px 20px 1px 20px;
        /* padding: 1px 15px 1px 20px; */
        margin-left: -17px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
    }
    
    .breadcrumb>.active {
        color: #fff;
        background: #1acc8d;
        /* background: #7ab800; */
    }
    
    .breadcrumb>li+li:after,
    .breadcrumb>li+li:before {
        content: "";
        padding: 0 5px;
        color: #ccc;
        background: #f5f5f5;
        border-radius: 25px;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 0;
    }
    
    .breadcrumb>li+li:after {
        z-index: 1;
        height: 22px;
        top: -1px;
        left: -7px;
        background: #fff;
    }
    
    a {
        text-decoration: none;
    }
</style>

<section class="breadcrumb">
    <div class="container breadcrumb_container">
        <div class="row">
            <div class="hidden-xs col-sm-12 col-md-12">
                <ol class="breadcrumb">
                    <li><a routerLink="/home">Home</a></li>
                    <!-- <li>Careers</li> -->
                    <li class="active">Contact Us</li>
                </ol>
            </div>
            <div class="col-sm-6 col-md-6 d-none">
                <div class="sharethis-inline-share-buttons st-right  st-inline-share-buttons st-animated" id="st-1">
                    <div class="st-total ">
                        <span class="st-label">69</span>
                        <span class="st-shares">
                Shares
                </span>
                    </div>
                    <div class="st-btn st-first" data-network="linkedin" style="display: inline-block;">
                        <img alt="linkedin sharing button" src="https://platform-cdn.sharethis.com/img/linkedin.svg">

                    </div>
                    <div class="st-btn" data-network="twitter" style="display: inline-block;">
                        <img alt="twitter sharing button" src="https://platform-cdn.sharethis.com/img/twitter.svg">

                    </div>
                    <div class="st-btn" data-network="reddit" style="display: inline-block;">
                        <img alt="reddit sharing button" src="https://platform-cdn.sharethis.com/img/reddit.svg">

                    </div>
                    <div class="st-btn" data-network="telegram" style="display: inline-block;">
                        <img alt="telegram sharing button" src="https://platform-cdn.sharethis.com/img/telegram.svg">

                    </div>
                    <div class="st-btn st-last" data-network="email" style="display: inline-block;">
                        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<style>
    .section-title {
        text-align: center;
        /* padding-bottom: 30px; */
    }
    
    .section-title h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        /* color: #2c4964; */
        /* color: #615cfb; */
        color: #11558a;
    }
    
    .section-title h2::before {
        content: '';
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        /* left: calc(5% - 60px); */
        left: calc(50% - 60px);
    }
    
    .section-title h2::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #1977cc;
        bottom: 0px;
        /* left: calc(5% - 20px); */
        left: calc(50% - 20px);
    }
    
    .section-title p {
        margin-bottom: 0;
    }
</style>
<section class="contactus pt-5" id="contactus">
    <div class="container">
        <div class="section-title">
            <h2>Contact Us</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                        hic quas.</p> -->
        </div>
        <div class="row">
            <!-- <h1 class="header-title"> Contact </h1>
                    <hr> -->
            <div class="col-sm-12" id="parent">
                <!-- <div class="col-sm-6">
                            <iframe width="100%" height="320px;" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJaY32Qm3KWTkRuOnKfoIVZws&key=AIzaSyAf64FepFyUGZd3WFWhZzisswVx2K37RFY" allowfullscreen></iframe>
                        </div> -->

                <!-- <div class="col-sm-6">
                            <form action="form.php" class="contact-form" method="post">
            
                                <div class="form-group">
                                    <input type="text" class="form-control" id="name" name="nm" placeholder="Name" required="" autofocus="">
                                </div>
            
            
                                <div class="form-group form_left">
                                    <input type="email" class="form-control" id="email" name="em" placeholder="Email" required="">
                                </div>
            
                                <div class="form-group">
                                    <input type="text" class="form-control" id="phone" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" placeholder="Mobile No." required="">
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control textarea-contact" rows="5" id="comment" name="FB" placeholder="Type Your Message/Feedback here..." required=""></textarea>
                                    <br>
                                    <button class="btn btn-default btn-send"> <span class="glyphicon glyphicon-send"></span> Send </button>
                                </div>
                            </form>
                        </div> -->
            </div>
        </div>
    </div>
</section>

<section class="pb-5">
    <div class="container second-portion">
        <div class="row">
            <!-- Boxes de Acoes -->
            <div class="col-xs-12 col-sm-6 col-lg-4">
                <div class="box">
                    <div class="icon">
                        <div class="image"><i class="fa fa-envelope" aria-hidden="true"></i></div>
                        <div class="info">
                            <h3 class="title">MAIL & WEBSITE</h3>
                            <p>
                                <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp; apmsme[at]gmail[dot]com
                                <br>
                                <br>
                                <i class="fa fa-globe" aria-hidden="true"></i> &nbsp; www.apmsme.com
                            </p>

                        </div>
                    </div>
                    <div class="space"></div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
                <div class="box">
                    <div class="icon">
                        <div class="image"><i class="fa fa-mobile" aria-hidden="true"></i></div>
                        <div class="info">
                            <h3 class="title">CONTACT</h3>
                            <p>
                                <i class="fa fa-mobile" aria-hidden="true"></i> &nbsp; (+91)-9624832108
                                <br>
                                <br>
                                <i class="fa fa-mobile" aria-hidden="true"></i> &nbsp; (+91)-7567068365
                            </p>
                        </div>
                    </div>
                    <div class="space"></div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
                <div class="box">
                    <div class="icon">
                        <div class="image"><i class="fas fa-map-marker-alt"></i></div>
                        <div class="info">
                            <h3 class="title">ADDRESS</h3>
                            <p>
                                <i class="fas fa-map-marker-alt"></i> &nbsp; Arrival Block, Pandit Nehru Bus Station, PUNDIT NEHRU BUS STATION, Vijayawada, Andhra Pradesh - 520013
                            </p>
                        </div>
                    </div>
                    <div class="space"></div>
                </div>
            </div>
            <!-- /Boxes de Acoes -->

            <!--My Portfolio  dont Copy this -->

        </div>
    </div>
</section>
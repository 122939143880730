<div class="container">
    <!-- <h2>&nbsp;</h2> -->
    <!-- Trigger the modal with a button -->
    <button type="button" class="btn btn-info btn-lg btn-position" data-toggle="modal" data-target="#myModal">Important Links</button>

    <!-- Modal -->
    <div class="modal right fade" id="myModal" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <!-- box -->
                    <!-- ribbon -->
                    <button type="button" class="close box" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title linksMainTitle">Important Links</h4>
                </div>
                <div class="modal-body">

                    <div class="content">
                        <ul>
                            <li><i class="fas fa-arrow-circle-right"></i><a href="https://www.udyogaadhar.co.in/" target="_blank">Udyog Aadhar</a></li>
                            <li><i class="fas fa-arrow-circle-right"></i><a href="https://www.apindustries.gov.in/APIndus/Default.aspx" trarget="_blank">AP Industries</a></li>
                            <li><i class="fas fa-arrow-circle-right"></i><a href="https://www.apindustries.gov.in/SPS/" target="_blank">SPS - 2020</a></li>
                            <li><i class="fas fa-arrow-circle-right"></i><a href="https://eoffice.gov.in/" target="_blank">eOffice</a></li>
                            <li><i class="fas fa-arrow-circle-right"></i><a href="https://cfms.ap.gov.in/" target="_blank">APCFMS</a></li>
                            <li><i class="fas fa-arrow-circle-right"></i><a href="https://pfms.nic.in/NewDefaultHome.aspx" target="_blank">PFMS</a></li>
                            <!-- <li><i class="fas fa-arrow-circle-right"></i><a href="https://kpi.apiic.in:8443/KPI/apiicfi/VacantPlots.jsp" target="_blank">Vacant Plots</a></li> -->

                        </ul>
                    </div>

                    <div class="row d-none">

                        <div class="col-sm-6 form-group">
                            <input type="text" class="form-control" placeholder="First Name" name="First Name">
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="text" class="form-control" placeholder="Last Name" name="Last Name">
                        </div>

                        <div class="col-sm-12 form-group">

                            <select class="form-control" style="color:#999">
          <option >Select College</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
      </select>
                        </div>
                        <div class="col-sm-12 form-group">
                            <textarea class="form-control" rows="5" placeholder="Address"></textarea>
                        </div>
                        <div class="col-sm-12 form-group">
                            <input type="text" class="form-control" placeholder="Text" name="text">
                        </div>


                    </div>
                </div>
                <div class="modal-footer d-none">
                    <button type="button" class="btn btn-success">Save</button>
                    <button type="button" class="btn btn-default close-btn" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>

</div>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Scheme of Funds for Regeneration of Traditional
                        Industries (SFURTI) Guidelines</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">Scheme of Funds for
                                Regeneration of Traditional Industries (SFURTI) Guidelines</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>


<section class="section_bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description </h2>
                </div>
                <div class="section_content">
                    <p>The main objective of the scheme is to organize the
                        traditional industries and artisans into clusters to
                        make them competitive and provide support for their
                        long-term sustainability and economy of scale.</p>
                </div>
            </div> -->
            <div class="col-md-12">
                <!-- <div class="schemes_content_heading">
                    <h2>Nature of Assistance</h2>
                </div> -->
                <div class="section_content">
                    <p>
                        The Scheme would cover 3 types of interventions: 'Soft
                        Interventions', 'Hard Interventions' and 'Thematic
                        Interventions'.
                    </p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Soft Interventions</h2>
                </div>
                <div class="content">
                    <ul>
                        <li>
                            General awareness, counselling, motivation and trust
                            building
                        </li>
                        <li>
                            Skill development and capacity building for the
                            entire value chain
                            different skills need to be imparted
                        </li>
                        <li>
                            Institution development
                        </li>
                        <li>
                            Exposure visits
                        </li>
                        <li>
                            Market promotion initiatives
                        </li>
                        <li>
                            Design and product development
                        </li>
                        <li>
                            Participation in seminars, workshops and training programmes on
technology up-gradation, etc.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Hard Interventions</h2>
                </div>
                <div class="content">
                    <ul>
                        <li>
                            Multiple facilities for multiple products and packaging wherever
                            needed
                        </li>
                        <li>
                            Common facility centres (CFCs)
                        </li>
                        <li>
                            Raw material banks (RMBs)
                        </li>
                        <li>
                            Up-gradation of production infrastructure
                        </li>
                        <li>
                            Tools and technological up-gradation such as charkha upgradation, tool-kit distribution, etc
                        </li>
                        <li>
                            Warehousing facility
                        </li>
                        <li>
                            Training center
                        </li>
                        <li>
                            Value addition and processing center/multi-products
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Thematic Interventions</h2>
                </div>
                <div class="content">
                    <ul>
                        <li>
                            Brand building and promotion campaign
                        </li>
                        <li>
                            New media marketing
                        </li>
                        <li>
                            e-Commerce initiatives
                        </li>
                        <li>
                            Innovation
                        </li>
                        <li>
                            Research & development initiatives
                        </li>
                        <li>
                            Developing institutional linkages with the existing & proposed
                            clusters
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th colspan="1">Component</th>
                                <th colspan="2">SFURTI</th>
                                <!-- <th>Type of clusters</th>
                                    <th>Per Cluster Budget Limit</th> -->
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Description</th>
                                <td>The main objective of the scheme is to organize the traditional industries and artisans into clusters to make them competitive and provide support for their long-term sustainability and economy of scale</td>
                            </tr>
                            <tr>
                                <td>Financial Assistance</td>
                                <td>
                                    <table style="width: 100%;">
                                        <thead>
                                            <tr>
                                                <th>Type of clusters</th>
                                       <th>Per Cluster Budget Limit</th>
                                           </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Regular Clusters (upto 500 artisans)</td>
                                                <td>INR 2.50 crore</td>
                                            </tr>
                                            <tr>
                                                <td>Major Clusters (more than 500 artisans)</td>
                                                <td>INR 5.00 crore</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                
                            </tr>
                            <tr>
                                <td>Who can Apply</td>
                                <td>
                                    <ul>
                                        <li>
                                            Non-Government Organizations (NGOs), Institutions of the Central and State Governments and Semi-Central Govt., Panchayati Raj Institutions (PRIs), etc. with suitable expertise to undertake cluster development
                                        </li>
                                        <li>
                                            Private Sector Participation is also encouraged for the implementation of cluster projects. Corporate entities can also take up projects directly by forming cluster-specific SPVs.
                                        </li>
                                        <li>
                                            Corporate and Corporate Social Responsibility (CSR) foundations with expertise in cluster development are encouraged to participate as Implementing Agencies. In case where a private sector entity is the IA, it shall contributes at least 50% of the total project costs excluding the cost of land
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>Whom to Contact</th>
                                <td>
                                    <ul>
                                        <li>
                                            Joint Development Commissioner, <br>
                                            O/o DC, MSME <br>
                                            Nirman Bhawan, New Delhi <br>
                                            Tel.: 011- 23061091 <br>
                                            Email: mandeepkaur[at]ias[dot]nic[dot]in
                                        </li>                                    
                                        <li>
                                            GM, DIC or AP MSMEDC at state level
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2"><a href="assets/documents/schemes/SFURTI_NEW.pdf" target="_blank">Download Scheme Guidelines</a></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3">

                    <h2 class="btn-md btnWidth applyButton">
                        Who Can apply
                    </h2>
                    <div class="section_content">
                        <p class="ml-2">(i) Non-Government Organizations (NGOs),
                            Institutions of the Central and State Governments
                            and Semi-Central Govt., Panchayati Raj Institutions
                            (PRIs), etc. with suitable expertise to undertake
                            cluster development.
                        </p>
                        <p class="ml-2">
                            (ii) Private Sector Participation is also encouraged
                            for the implementation of cluster projects.
                            Corporate entities can also take up projects
                            directly by forming cluster-specific SPVs.
                        </p>
                        <p class="ml-2">
                            (iii) Corporate and Corporate Social Responsibility
                            (CSR) foundations with expertise in cluster
                            development are encouraged to participate as
                            Implementing Agencies. In case where a private
                            sector entity is the IA, it shall contributes at
                            least 50% of the total project costs excluding the
                            cost of land.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

    <section class="sectionpadding testimonial">
        <div class="container">
            <div class="section-title">
                <h2>Success stories across India</h2>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="testistyle">
                        <div id="testimonial2" class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x" data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000">
                
                            <div class="carousel-inner" role="listbox">
                            
                                <div class="carousel-item active">
                                    <div class="testimonial4_slide">
                                        
                                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 3</h4> -->
                                        <!-- <p>Despite multiple safaris in India, Africa was a destination I was sceptical of planning by myself and was looking for help. The Wild Wonders team got me quite comfortable right away  as they understood the photographers’ language quite well and recommended a plan accordingly. Things I particularly liked was their connections with resorts, knowledge of the African safari landscape, access to drivers who have driven and guided celebrated wildlife photographers and photographer friendly vehicles. I could not have asked for a more professional and experienced team for my first trip covering Amboseli and Masai Mara.</p> -->
                                        <!-- <h4>R. Eswar Kumar, India</h4> -->
                                       <div class="testContent">
                                        <p>
                                            Aliquam eget dictum urna. Proin ligula sapien, tristique et gravida nec, fringilla ultricies turpis. Sed vitae efficitur risus, nec venenatis nisl. Sed auctor arcu vel commodo ornare. Etiam vitae dapibus lorem, sit amet tempor mauris. Mauris magna velit, dapibus ac finibus non, molestie in dui. Ut vulputate et massa et gravida. Pellentesque euismod urna quis ante ultrices scelerisque. Aliquam eget risus enim. Pellentesque dignissim augue nec tellus consectetur aliquam.
                                        </p>
                                       </div>
                                       <img src="assets/images/boardmembers/photo1.png" class="img-circle img-fluid" />
                                        <h4>Sri S. Ananda Pardasaradhi</h4>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testimonial4_slide">
                                        
                                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 3</h4> -->
                                        <!-- <p>Despite multiple safaris in India, Africa was a destination I was sceptical of planning by myself and was looking for help. The Wild Wonders team got me quite comfortable right away  as they understood the photographers’ language quite well and recommended a plan accordingly. Things I particularly liked was their connections with resorts, knowledge of the African safari landscape, access to drivers who have driven and guided celebrated wildlife photographers and photographer friendly vehicles. I could not have asked for a more professional and experienced team for my first trip covering Amboseli and Masai Mara.</p> -->
                                        <!-- <h4>R. Eswar Kumar, India</h4> -->
                                       <div class="testContent">
                                        <p>
                                            Aliquam eget dictum urna. Proin ligula sapien, tristique et gravida nec, fringilla ultricies turpis. Sed vitae efficitur risus, nec venenatis nisl. Sed auctor arcu vel commodo ornare. Etiam vitae dapibus lorem, sit amet tempor mauris. Mauris magna velit, dapibus ac finibus non, molestie in dui. Ut vulputate et massa et gravida. Pellentesque euismod urna quis ante ultrices scelerisque. Aliquam eget risus enim. Pellentesque dignissim augue nec tellus consectetur aliquam.
                                        </p>
                                       </div>
                                       <img src="assets/images/boardmembers/photo1.png" class="img-circle img-fluid" />
                                        <h4>Sri S. Ananda Pardasaradhi</h4>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testimonial4_slide">
                                        
                                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 3</h4> -->
                                        <!-- <p>Despite multiple safaris in India, Africa was a destination I was sceptical of planning by myself and was looking for help. The Wild Wonders team got me quite comfortable right away  as they understood the photographers’ language quite well and recommended a plan accordingly. Things I particularly liked was their connections with resorts, knowledge of the African safari landscape, access to drivers who have driven and guided celebrated wildlife photographers and photographer friendly vehicles. I could not have asked for a more professional and experienced team for my first trip covering Amboseli and Masai Mara.</p> -->
                                        <!-- <h4>R. Eswar Kumar, India</h4> -->
                                       <div class="testContent">
                                        <p>
                                            Aliquam eget dictum urna. Proin ligula sapien, tristique et gravida nec, fringilla ultricies turpis. Sed vitae efficitur risus, nec venenatis nisl. Sed auctor arcu vel commodo ornare. Etiam vitae dapibus lorem, sit amet tempor mauris. Mauris magna velit, dapibus ac finibus non, molestie in dui. Ut vulputate et massa et gravida. Pellentesque euismod urna quis ante ultrices scelerisque. Aliquam eget risus enim. Pellentesque dignissim augue nec tellus consectetur aliquam.
                                        </p>
                                       </div>
                                       <img src="assets/images/boardmembers/photo1.png" class="img-circle img-fluid" />
                                        <h4>Sri S. Ananda Pardasaradhi</h4>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testimonial4_slide">
                                        
                                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 3</h4> -->
                                        <!-- <p>Despite multiple safaris in India, Africa was a destination I was sceptical of planning by myself and was looking for help. The Wild Wonders team got me quite comfortable right away  as they understood the photographers’ language quite well and recommended a plan accordingly. Things I particularly liked was their connections with resorts, knowledge of the African safari landscape, access to drivers who have driven and guided celebrated wildlife photographers and photographer friendly vehicles. I could not have asked for a more professional and experienced team for my first trip covering Amboseli and Masai Mara.</p> -->
                                        <!-- <h4>R. Eswar Kumar, India</h4> -->
                                       <div class="testContent">
                                        <p>
                                            Aliquam eget dictum urna. Proin ligula sapien, tristique et gravida nec, fringilla ultricies turpis. Sed vitae efficitur risus, nec venenatis nisl. Sed auctor arcu vel commodo ornare. Etiam vitae dapibus lorem, sit amet tempor mauris. Mauris magna velit, dapibus ac finibus non, molestie in dui. Ut vulputate et massa et gravida. Pellentesque euismod urna quis ante ultrices scelerisque. Aliquam eget risus enim. Pellentesque dignissim augue nec tellus consectetur aliquam.
                                        </p>
                                       </div>
                                       <img src="assets/images/boardmembers/photo1.png" class="img-circle img-fluid" />
                                        <h4>Sri S. Ananda Pardasaradhi</h4>

                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#testimonial2" data-slide="prev">
                                <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#testimonial2" data-slide="next">
                                <span class="carousel-control-next-icon"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

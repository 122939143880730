<style>
    .breadcrumb {
        padding: 5px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    
    .breadcrumb>li:first-child {
        padding-left: 10px;
    }
    
    .breadcrumb>li {
        display: inline-block;
        background: #f5f5f5;
        color: #9d9d9d;
        border-radius: 25px;
        padding: 1px 20px 1px 20px;
        /* padding: 1px 15px 1px 20px; */
        margin-left: -17px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
    }
    
    .breadcrumb>.active {
        color: #fff;
        background: #1acc8d;
        /* background: #7ab800; */
    }
    
    .breadcrumb>li+li:after,
    .breadcrumb>li+li:before {
        content: "";
        padding: 0 5px;
        color: #ccc;
        background: #f5f5f5;
        border-radius: 25px;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 0;
    }
    
    .breadcrumb>li+li:after {
        z-index: 1;
        height: 22px;
        top: -1px;
        left: -7px;
        background: #fff;
    }
    
    a {
        text-decoration: none;
    }
</style>
<section class="breadcrumb">
    <div class="container breadcrumb_container">
        <div class="row">
            <div class="hidden-xs col-sm-12 col-md-12">
                <ol class="breadcrumb">
                    <li><a routerLink="/home">Home</a></li>
                    <!-- <li>Careers</li> -->
                    <li class="active">Quick Links</li>
                </ol>
            </div>
            <div class="col-sm-6 col-md-6 d-none">
                <div class="sharethis-inline-share-buttons st-right  st-inline-share-buttons st-animated" id="st-1">
                    <div class="st-total ">
                        <span class="st-label">69</span>
                        <span class="st-shares">
        Shares
        </span>
                    </div>
                    <div class="st-btn st-first" data-network="linkedin" style="display: inline-block;">
                        <img alt="linkedin sharing button" src="https://platform-cdn.sharethis.com/img/linkedin.svg">

                    </div>
                    <div class="st-btn" data-network="twitter" style="display: inline-block;">
                        <img alt="twitter sharing button" src="https://platform-cdn.sharethis.com/img/twitter.svg">

                    </div>
                    <div class="st-btn" data-network="reddit" style="display: inline-block;">
                        <img alt="reddit sharing button" src="https://platform-cdn.sharethis.com/img/reddit.svg">

                    </div>
                    <div class="st-btn" data-network="telegram" style="display: inline-block;">
                        <img alt="telegram sharing button" src="https://platform-cdn.sharethis.com/img/telegram.svg">

                    </div>
                    <div class="st-btn st-last" data-network="email" style="display: inline-block;">
                        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<style>
    .nav-pills-custom .nav-link {
        /* color: #615cfb; */
        color: #11558a;
        /* background: #615cfb; */
        /* color: #aaa; */
        background: #fff;
        position: relative;
    }
    
    .nav-pills-custom .nav-link.active {
        /* color: #45b649; */
        /* background: #fff; */
        /* color: #11558a;
        background: #615cfb; */
        color: #fff;
        background: #1acc8d;
    }
    /* Add indicator arrow for the active tab */
    
    @media (min-width: 992px) {
        .nav-pills-custom .nav-link::before {
            content: '';
            display: block;
            border-top: 8px solid transparent;
            border-left: 10px solid #1acc8d;
            border-bottom: 8px solid transparent;
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            opacity: 0;
        }
    }
    
    .nav-pills-custom .nav-link.active::before {
        opacity: 1;
    }
    
    .shadow {
        -moz-box-shadow: 0 0 5px #999;
        -webkit-box-shadow: 0 0 5px #999;
        box-shadow: 0 0 5px #999!important;
    }
    
    body {
        min-height: 100vh;
        background: linear-gradient(to left, #dce35b, #45b649);
    }
</style>

<section class="two mt-3" id="two">
    <style>
        .section-title {
            text-align: center;
            /* padding-bottom: 30px; */
        }
        
        .section-title h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            /* color: #2c4964; */
            /* color: #615cfb; */
            color: #11558a;
        }
        
        .section-title h2::before {
            content: '';
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            /* left: calc(5% - 60px); */
            left: calc(50% - 60px);
        }
        
        .section-title h2::after {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: #1977cc;
            bottom: 0px;
            /* left: calc(5% - 20px); */
            left: calc(50% - 20px);
        }
        
        .section-title p {
            margin-bottom: 0;
        }
    </style>
    <div class="container">
        <div class="section-title">
            <h2>Quick Links</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                hic quas.</p> -->
        </div>
    </div>
</section>

<!-- Demo header-->
<section class="py-5 header">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="content">
                    <ul>
                        <li><i class="fas fa-arrow-circle-right"></i><a href="Udyog Aadhar" target="_blank">Udyog Aadhar</a></li>
                        <li><i class="fas fa-arrow-circle-right"></i><a href="https://www.apindustries.gov.in/APIndus/Default.aspx" trarget="_blank">AP Industries</a></li>
                        <li><i class="fas fa-arrow-circle-right"></i><a href="https://www.apindustries.gov.in/SPS/" target="_blank">SPS - 2020</a></li>
                        <li><i class="fas fa-arrow-circle-right"></i><a href="https://eoffice.gov.in/" target="_blank">eOffice</a></li>
                        <li><i class="fas fa-arrow-circle-right"></i><a href="https://cfms.ap.gov.in/" target="_blank">APCFMS</a></li>
                        <li><i class="fas fa-arrow-circle-right"></i><a href="https://pfms.nic.in/NewDefaultHome.aspx" target="_blank">PFMS</a></li>


                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="py-5 header d-none">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="links">
                    <button class="btn linkone" (click)="udyogClick();">
                        Udyog Aadhar
                    </button>

                    <button class="btn linkone" (click)="spsClick();">
                        SPS - 2020
                    </button>
                    <button class="btn linkone" (click)="eofficeClick();">
                        eOffice
                    </button>
                    <button class="btn linkone" (click)="apcfmsClick();">
                        APCFMS
                    </button>

                    <button class="btn linkone" (click)="pfmsClick();">
                        PFMS
                    </button>
                    <button class="btn linkone" (click)="spsClick();">
                        SPS - 2020
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="py-5 header d-none">
    <div class="container-fluid">
        <!-- <header class="text-center mb-5 pb-5 text-white">
            <h1 class="display-4">Bootstrap vertical tabs</h1>
            <p class="font-italic mb-1">Making advantage of Bootstrap 4 components, easily build an awesome tabbed interface.</p>
            <p class="font-italic">
                <a class="text-white" href="">
                    <u></u>
                </a>
            </p>
        </header> -->


        <div class="row">
            <div class="col-md-2">
                <!-- Tabs nav -->
                <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <!-- <i class="fa fa-user-circle-o mr-2"></i> -->
                        <i class="fas fa-link mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Important Links</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <!-- <i class="fa fa-calendar-minus-o mr-2"></i> -->
                        <i class="fas fa-sitemap mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">HODs</span></a>

                    <!-- <a class="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <i class="fa fa-star mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Reviews</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i class="fa fa-check mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Confirm booking</span></a> -->
                </div>
            </div>

            <style>
                .linkone {
                    /* color: #615cfb; */
                    /* color: #11558a; */
                    /* background: #615cfb; */
                    background: #11558a;
                    padding: 0.5em 0.8em;
                    border-radius: 4px;
                    /* cursor: pointer;
                    width: max-content;
                    margin-left: 0.5em; */
                    transition: all .3s ease-out;
                    width: 130px;
                    max-width: 170px;
                    /* background: #1d3c2b8c; */
                    /* height: 65px; */
                    margin: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-items: center;
                    /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
                    /* box-shadow: 2px 2px 16px 0 rgba(255, 255, 255, 0.3), 2px 2px 12px 0 rgba(0, 0, 0, 0.25); */
                    /* box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.3), 2px 2px 12px 0 rgba(0, 0, 0, 0.25); */
                    /* border-radius: 50px; */
                    border-radius: 6px;
                    display: flex;
                    height: 55px;
                    justify-content: center;
                    width: 165px;
                    color: #fff;
                    font-weight: bolder;
                }
                
                .links {
                    display: flex;
                    flex-wrap: wrap;
                }
            </style>
            <div class="col-md-10">
                <!-- Tabs content -->
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade shadow rounded bg-white show active pt-3 pb-3 pl-2 pr-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <!-- <h4 class="font-italic mb-4">Important Links</h4> -->
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="links">
                                        <button class="btn linkone" (click)="udyogClick();">
                                            Udyog Aadhar
                                        </button>

                                        <button class="btn linkone" (click)="spsClick();">
                                            SPS - 2020
                                        </button>
                                        <button class="btn linkone" (click)="eofficeClick();">
                                            eOffice
                                        </button>
                                        <button class="btn linkone" (click)="apcfmsClick();">
                                            APCFMS
                                        </button>

                                        <button class="btn linkone" (click)="pfmsClick();">
                                            PFMS
                                        </button>
                                        <button class="btn linkone" (click)="spsClick();">
                                            SPS - 2020
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <p class="font-italic text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> -->
                    </div>

                    <div class="tab-pane fade shadow rounded bg-white pt-3 pb-3 pl-2 pr-2" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <h4 class="font-italic mb-4">Bookings</h4>
                        <p class="font-italic text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>

                    <div class="tab-pane fade shadow rounded bg-white pt-3 pb-3 pl-2 pr-2" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <h4 class="font-italic mb-4">Reviews</h4>
                        <p class="font-italic text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>

                    <div class="tab-pane fade shadow rounded bg-white pt-3 pb-3 pl-2 pr-2" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <h4 class="font-italic mb-4">Confirm booking</h4>
                        <p class="font-italic text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>












<section class="el-container d-flex-column d-none">
    <div class="p-tb100 d-flex-j-a-center">
        <div class="p-dev-outer">
            <div class="p-dev"><span class="p-dev-tit">Product <span class="d-block">Development</span></span>
            </div>
        </div>
        <ul class="prod-list">
            <li>Requirement Planning</li>
            <li class="yellow">Analysis</li>
            <li class="pink">Design</li>
            <li class="dark-blue">Review &amp; Approval</li>
            <li class="ciyan">Coding</li>
            <li class="purple">QA</li>
            <li class="blue">Go Live &amp; Support</li>
        </ul>
    </div>
    <hr>
    <div class="core-values">
        <h2>Our Core Values</h2>
        <div class="careers-grid el-row" style="margin-left: -15px; margin-right: -15px;">
            <div class="el-col el-col-8" style="padding-left: 15px; padding-right: 15px;">
                <div class="el-card core-bg is-never-shadow">
                    <!---->
                    <div class="el-card__body">
                        <h4>Continue to grow</h4>
                        <p>We keep learning, teaching, and growing individually, as a company and as a community.</p>
                    </div>
                </div>
            </div>
            <div class="el-col el-col-8" style="padding-left: 15px; padding-right: 15px;">
                <div class="el-card core-bg bg-white is-never-shadow">
                    <!---->
                    <div class="el-card__body">
                        <h4>Lead with passion</h4>
                        <p>We love what we do and we do what we love. We are driven, take ownership for our work, and hold each other responsible.</p>
                    </div>
                </div>
            </div>
            <div class="el-col el-col-8" style="padding-left: 15px; padding-right: 15px;">
                <div class="el-card core-bg is-never-shadow">
                    <!---->
                    <div class="el-card__body">
                        <h4>Positive vibes</h4>
                        <p>We are positive, respectful team players. We build each other up, and are always ready to go the extra mile to help one another. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Government Policies</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">GO's & Policies</a></li>
                        <li><a style="color: #fff;">Government Policies</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>


<section class="section_bg py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-bordered bg-white">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th class="text-nowrap" style="width: 130px;">GO No.</th>
                            <th class="text-nowrap" style="width: 130px;">Date</th>
                            <th>Subject</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>MS.No. 34
                            </td>
                            <td>19-05-2021
                            </td>
                            <td>Industries
                                and Commerce
                                Department –
                                Andhra
                                Pradesh
                                Oxygen
                                Manufacturing
                                Policy 2021
                                – 2022- –
                                Orders –
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms34.PDF" target="_blank">View</a></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>MS.No. 10
                            </td>
                            <td>25-02-2021
                            </td>
                            <td>Industrial
                                Promotion –
                                Incentives
                                for the
                                establishment
                                of
                                Industrial
                                Enterprises
                                in Andhra
                                Pradesh
                                under the
                                Industrial
                                Development
                                Policy (IDP)
                                2020-23 –
                                Operational
                                Guidelines
                                for
                                implementation
                                – Orders -
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms10.PDF" target="_blank">View</a></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>MS.No. 9
                            </td>
                            <td>25-02-2021
                            </td>
                            <td>Public
                                Procurement
                                Policy- Make
                                in India
                                (PPP-MII)
                                –Order of
                                Government
                                of Andhra
                                Pradesh 2020
                                - Orders –
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms9.PDF" target="_blank">View</a></td>
                        </tr>


                        <tr>
                            <td>4</td>
                            <td>MS.No. 93
                            </td>
                            <td
                                class="text-nowrap">30-12-2020
                            </td>
                            <td>Industries &
                                Commerce
                                Department –
                                Andhra
                                Pradesh Food
                                Processing
                                Policy
                                2020-2025 –
                                Orders –
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms93.pdf" target="_blank">View</a></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>MS.No. 39
                            </td>
                            <td>10-08-2020
                            </td>
                            <td>Industries &
                                Commerce
                                Department –
                                “Andhra
                                Pradesh
                                Industrial
                                Development
                                Policy
                                2020-23 –
                                Orders –
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms39.PDF" target="_blank">View</a></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>MS.No. 70
                            </td>
                            <td>04-09-2019
                            </td>
                            <td>Mines &
                                Minerals –
                                New Sand
                                Mining
                                Policy, 2019
                                for the
                                State of
                                Andhra
                                Pradesh -
                                Orders –
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms70.PDF" target="_blank">View</a></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>MS.No. 8
                            </td>
                            <td>09-01-2019
                            </td>
                            <td>Industries &
                                Commerce
                                Department –
                                Policy to
                                mitigate
                                stress and
                                to revive
                                Stressed
                                MSMEs–
                                Stressed
                                Enterprises
                                Policy
                                2018-20-
                                Orders-
                                Issued </td>
                            <td
                                class="view"><a href="assets/policies/goms8.PDF" target="_blank">View</a></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>


<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Objectives</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">About</a></li>
                        <li><a style="color: #fff;">Objectives</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- objectives starts -->

<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="content">
                    <ul>
                        <li>
                            <i class="fa fa-check"></i>
                            Acting as single point for all MSME related services
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Implementing new policies/programmes for development of MSME sector
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Strengthening of existing MSME clusters to make them competitive
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Providing requisite infrastructure support viz., land, MSME parks and common facilities as required
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Promoting access to finance for MSME by providing credit support, equity and other financial instruments
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Identify the sickness reasons in MSME sector and addressing sickness in the sector
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Facilitating regulatory compliance including taxation, legal, labour, land requirements, etc
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Promoting entrepreneurship to setup MSMEs
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Partnering with institutions for implementing Government of India MSME programmes
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Partnering with national and international associations to foster the MSME sector
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Organising networking events like conference/exhibitions for growth of sector
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-quicklinks',
  templateUrl: './quicklinks.component.html',
  styleUrls: ['./quicklinks.component.css']
})
export class QuicklinksComponent implements OnInit {

  constructor(private router: Router) { }

  udyogClick= function () {
    
    window.open('https://www.eudyogaadhaar.com/?gclid=CjwKCAjwtJ2FBhAuEiwAIKu19i8Y1KMOqoWuJY_CrlypRHIzsFf7j1qJ3GxmGcoXLG24O8fgYVoZchoCRQ8QAvD_BwE', "_blank");
};
spsClick= function () {
    
  window.open('https://www.apindustries.gov.in/SPS/', "_blank");
};
eofficeClick= function () {
    
  window.open('https://eoffice.gov.in/', "_blank");
};
apcfmsClick= function () {
    
  window.open('https://cfms.ap.gov.in/', "_blank");
};
pfmsClick= function () {
    
  window.open('https://pfms.nic.in/NewDefaultHome.aspx', "_blank");
};
  ngOnInit(): void {
  }

}

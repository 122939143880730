
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Board of Directors</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">About</a></li>
                        <li><a style="color: #fff;">Board of Directors</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>

<section class="section_bg">
    <div class="container">
        <div class="row" data-aos="fade-down" data-aos-duration="1500">
            <div class="col-md-12">
                <div class="bodyHeading">
                    <h6>board chairman</h6>
                </div>
            </div>
            <div class="col-md-4 mt-3 mb-3"></div>
            <div class="col-md-4 mt-3 mb-3">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/vanka ravindranath.png"
                                class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri Vanka Ravindranath</strong>
                        <small>Chairman</small>
                        <small><strong>Mobile: 9848199661</strong></small>
                    </div>
                    <!-- <button class="btn btn-primary profile-button" data-toggle="modal" data-target="#chairmanModal">Profile</button> -->

                </div>
            </div>
            <div class="col-md-4 mt-3 mb-3"></div>

            <div class="col-md-6 d-none">
                <div class="d-flex justify-content-between mt-3">
                    <div class="d-flex">
                        <div class="flex-img">
                            <img
                                src="assets/images/boardmembers/photo-placeholder.png"
                                class="img-fluid" alt="no-img" />
                        </div>
                        <div class="d-flex align-items-start pl-3 flex-column
                            justify-content-between">
                            <div class="strong">CEO NAME
                                <div>
                                    <small>CEO</small>
                                </div>

                            </div>
                            <button class="btn btn-primary profile-button"
                                data-toggle="modal"
                                data-target="#chairmanModal">Profile</button>
                        </div>
                    </div>


                    <!-- <a href="#" class="d-flex align-items-center flex-column">
            <div class="flex-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"></path>
                </svg>
                
            </div>
            <div class="d-flex">
                <div class="strong ">Download</div>
            </div>
            </a>
        -->
                </div>
            </div>

            <div class="col-md-12">
                <div class="bodyHeading">
                    <h6>board of directors</h6>
                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo1.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri S. Ananda
                            Pardasaradhi</strong>
                        <small>Chittor</small>
                        <small><strong>Mobile: 9989945564</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo2.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Smt K. Sharadha</strong>
                        <small>Kurnool</small> <br>
                        <small><strong>Mobile: 8074301266</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo3.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Smt S. Nadiya</strong>
                        <small>Visakhapatnam</small> <br>
                        <small><strong>Mobile: 9182087951</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo4.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri SK. Karimulla</strong>
                        <small>Nellore</small> <br>
                        <small><strong>Mobile: 9160311270</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo5.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Smt B. Vijayalaxmi</strong>
                        <small>Guntur</small> <br>
                        <small><strong>Mobile: 8919158809</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo6.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Smt G. Varalakshmi</strong>
                        <small>Krishna</small> <br>
                        <small><strong>Mobile: 9441958269</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo7.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Smt Nalla Baby
                            Janaki</strong>
                        <small>East Godavari</small>
                            <small><strong>Mobile: 9949595693</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo8.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri N. Raghunath
                            Reddy</strong>
                        <small>Ananthapuramu</small>
                        <small><strong>Mobile: 9059190653</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo9.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri T. Anjaneyulu</strong>
                        <small>Guntur</small>
                        <br>
                        <small><strong>Mobile: 9440249069</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo10.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri M. Gowri
                            Sankara Rao</strong>
                        <small>Vizianagaram</small>
                        <small><strong>Mobile: 9177270112</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/photo11.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri M. Venkata
                            Badri Narayana</strong>
                        <small>Prakasam</small>
                        <small><strong>Mobile: 9885375242</strong></small>
                    </div>

                </div>
            </div>
            <div class="col-md-2 mt-3 mb-3">
                <div href="#" class="d-flex flex-column">
                    <div class="flex-img">
                        <img
                            src="assets/images/boardmembers/venkata-ramarao.png"
                            class="img-fluid" alt="no-img"  style="width: 80%;"/>
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri Y. Venkataramana 
                            Rao</strong>
                        <small>Srikakulam</small>
                        <small><strong>Mobile: 9440702630</strong></small>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>





<!-- Modal -->
<div class="modal fade" id="chairmanModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="chairmanModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h4 class="modal-title" id="myModalLabel">Chairman Profile</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="profileContent">
                              <p>Shri Vanka Ravindra Nath is a well renowned and reputed industrialist having experience of over 35
                                  years in manufacturing sector and international trade.</p>
                              <p>Shri Vanka Ravindranath is the son of Late Shri Vanka Satyanarayana (Freedom fighter and 3 times MLA,
                                  Penugonda). He is born in Tanuku, West Godavari district and studied Sugar Technology at
                                  Visakhapatnam.</p>
                              <p>He Started his own business of Hair exports in 1989 under SSI (Small scale industries), successfully
                                  establishing it as one of the largest units in the country. Later on he established spinning mills and
                                  entered into textile sector.</p>
                          </div>
                          <div class="achievements">
                              <h4 class="font-weight-bold">Achievements</h4>
                              <hr>
                              <div class="modalUl pt-2">
                                  <ul>
                                      <li><i class="fas fa-chevron-circle-right"></i>National Export Award, Ministry of Industries, Govt. of India: 2 times (1997 & 1999)</li>
                                      <li><i class="fas fa-chevron-circle-right"></i>Top Export Award ( National Wide), ( PLEXCONCIL ) : 2 times ( 2000 & 2001)</li>
                                      <li><i class="fas fa-chevron-circle-right"></i>FAPCII award – Best SSI Exporter in AP: 2 times</li>
                                      <li><i class="fas fa-chevron-circle-right"></i>FIEO Award (National wide) – Niryat Ratna award: 3 times ( 2001,2015 & 2016)</li>
                                  </ul>
                              </div>
                          </div>
                          <div class="achievements pt-2">
                              <h4 class="font-weight-bold">Countries Visited</h4>
                              <hr>
                              <div class="countries pt-2">
                                  <p>Australia, Belgium ,Brazil , Burma, Bangladesh, China, Check, France, Germany, Hong Kong, Italy,
                                      Indonesia, Japan, Korea, Nepal, Portugal, Spain, South Africa, Switzerland, UAE,UK, USA & Vietnam.</p>
                              </div>
                          </div>
                          <div class="text-right">
            <button type="button" class="btn btn-primary btn-md" data-dismiss="modal">Close</button>
  
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer d-none">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
</div>

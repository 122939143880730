<style>
    .navbar-toggler-icon {
        background-image: url("assets/images/menu.png");
        background-repeat: no-repeat;
        background-size: contain;
        filter: invert(0);
        width: 42px;
        height: 36px;
    }
</style>
<!-- fixed-top -->
<section class="top-header-section pt-2 pb-2">
    <div class=" container">
        <div class="row align-items-center">
            <!-- <div class="col-md-2"></div> -->
            <div class="col-md-2 col-sm-2">
                <a href="index.html"><img class="img-responsive central-logo animated pulse" src="assets/images/logo/msmenewlogo.png" alt="Central Sainik Welfare LOGO" title="Central Sainik Welfare Logo" style="width: 120px;">
                </a>
            </div>

            <div class="col-md-8 middle-section text-center">
                <h4 class="header2">AP MSME Development Corporation</h4>
                <h6 class="header1 m-0">(A Govt. of Andhra Pradesh Undertaking)</h6>
                <small style="color: #bf000a;
                font-size: 16px;"><strong>An ISO 9001:2015 certified Corporation</strong></small>
                <!-- <h3 class="header3">Amaravati, India</h3> -->
            </div>
            <div class="col-md-2 text-right">
                <a href="index.html">
                    <img class="img-responsive ap-logo animated pulse" src="assets/images/logo/ap.png" alt="Andhra Pradesh State LOGO" title="Andhra Pradesh State Logo" style="width: 90px;">


                </a>
            </div>
            <!-- <div class="col-md-2"></div> -->
        </div>
    </div>
</section>


<section class="" style="background: #0266B4;position: sticky;
top: 0;
z-index: 100;">
    <nav class="navbar navbar-expand-lg pt-1 pb-1" id="mainNav" style="background: #0266B4;">
        <div class="container wrap">
            <div class="row">
                <div class="col-md-12">
            <!-- d-flex -->
           

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"><span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="collapsibleNavbar">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link" routerLink="/home">Home</a>

                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuAbout" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            About</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuAbout">
                            <!--  [queryParams]="{ q: 'societies'}" -->

                            <li><a class="dropdown-item" routerLink="/organizationstructure">Organisation Structure</a></li>
                            <li><a class="dropdown-item" routerLink="/executivebody">Executive Body</a></li>
                            <li><a class="dropdown-item" routerLink="/directors">Board of Directors</a></li>
                            <li><a class="dropdown-item" routerLink="/objectives">Objectives</a></li>
                            <!-- <a class="dropdown-item" routerLink="/boardmembers">Board Members</a></li> -->
                            <!-- <li><a class="dropdown-item" routerLink="/clusterprocess">Approval & Implementation Procedure</a></li> -->
                            <li><a class="dropdown-item" routerLink="/rtiacts">RTI</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuNotifications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Notifications
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuNotifications">
                            <!--  [queryParams]="{ q: 'societies'}" -->
                            <!-- <li><a class="dropdown-item" routerLink="/gos">GO's</a></li> -->
                            <!-- routerLink="/tenders" -->
                            <li><a class="dropdown-item" target="_blank" href="https://tender.apeprocurement.gov.in/login.html">Tenders</a></li>
                            <li><a class="dropdown-item" routerLink="/vacancies">Vacancies</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Schemes</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">MSE-CDP</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" routerLink="/msmecdp">MSE-CDP</a></li>
                                    <li><a class="dropdown-item" routerLink="/clusterprocess">Approval & Implementation Procedure</a></li>
                                </ul>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/pmegp">PMEGP</a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/sfurti">SFURTI</a>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">NSIC</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" routerLink="/marketingsupport">Raw Material Bank and Marketing <br> Support</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">Credit Support</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" routerLink="/sidbi">SIDBI</a></li>
                                    <li><a class="dropdown-item" routerLink="/apsfc">APSFC</a></li>
                                </ul>
                            </li>
                            <li>
                                <a class="dropdown-item" href="assets/documents/schemes/Compendium-of-MSME-Schemes.pdf" target="_blank">Compendium of Schemes</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="assets/documents/schemes/Sample DSR template.pdf" target="_blank">Sample DSR Template</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="assets/documents/schemes/Sample DPR template.pdf" target="_blank">Sample DPR Template</a>
                            </li>
                        </ul>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLink="/policies">Policies</a>
                    </li> -->
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuPolicies" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
GO's & Policies</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuPolicies">
                            <!--  [queryParams]="{ q: 'societies'}" -->

                            <li><a class="dropdown-item" routerLink="/gos">Go's</a></li>
                            <li><a class="dropdown-item" routerLink="/policies">Policies</a></li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuDistricts" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Preferential Districts</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuDistricts">
                            <!--  [queryParams]="{ q: 'societies'}" -->

                            <li><a class="dropdown-item" routerLink="/lwedistricts">LWE Affected Districts</a></li>
                            <li><a class="dropdown-item" routerLink="/aspirationaldistricts">Aspirational Districts</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuProjects" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Projects</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuProjects">
                            <!--  [queryParams]="{ q: 'societies'}" -->

                            <li><a class="dropdown-item" routerLink="/ongoingclusters">Ongoing Clusters</a></li>
                            <li><a class="dropdown-item" routerLink="/potentialclusters">Potential Clusters</a></li>
                            <li><a class="dropdown-item" href="assets/documents/schemes/Empanelled Technical Agencies.pdf" target="_blank">Empanelled Technical Agencies</a></li>
                            <!-- <li><a class="dropdown-item" routerLink="/suggestiveclusters">Special Preference For LWE Effected and Aspirational districts</a></li> -->
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="/importantlinks">Important Links</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/contactus">Contact</a>
                    </li>
                    
                </ul>
            </div>
            <div class="d-none">
                <a href="" class="navbar-brand">
                    <img src="assets/images/logo/ap.png" alt="Logo" class="img-fluid mt-1" style="width: 80px;" />
                </a>
            </div>

</div>
</div>

        </div>
    </nav>
</section>
<style>
    .languages button {
        width: 50px;
    }
</style>

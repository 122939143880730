<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>sidbi
                    </h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">Sidbi</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description</h2>
                </div>
                <div class="section_content">
                    <p>
                        Small Industries Development Bank of India (SIDBI) is acts as the Principal Financial Institution for Promotion, Financing and Development of the Micro, Small and Medium Enterprise (MSME) sector as well as for co-ordination of functions of institutions engaged in similar activities.
                    </p>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Scheme Name</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Partial Risk Sharing Facility for Energy
                                    Efficiency (PRSF)</td>
                                <td>
                                    <ul>
                                        <li>
                                            Partial Risk Sharing Facility (PRSF) provides guarantees to the Participating Financial Institutions (PFIs) i.e., Banks/FIs/NBFCs for the Energy Efficiency loans extended by them through Energy Service Companies (ESCOs)
                                        </li>
                                        <li>
                                            Large industries (excluding thermal power plants), including those notified under the BEE’s energy consumption norms and standards (i.e. through <a href="https://beeindia.gov.in/content/pat-3" target="_blank">PAT scheme </a>).
                                        </li>
                                        <li>
                                            MSMEs
                                        </li>
                                        <li>
                                            Street lighting (Municipalities)
                                        </li>
                                        <li>
                                            Buildings
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit PRSF Website <a href="http://prsf.sidbi.in/" target="_blank">http://prsf.sidbi.in/</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>SIDBI term loan to enhance production of MSMEs (STEP)</td>
                                <td>
                                    <ul>
                                        <li>
                                            To provide medium/short term financial assistance to eligible MSMEs for augmenting NWC and / or to execute urgent confirmed orders
                                        </li>
                                        <li>
                                            Upto INR 300 lakh for existing customers
                                        </li>
                                        <li>
                                            Upto INR 200 lakh for New Customers
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Ubharte Sitaare</td>
                                <td>
                                    <ul>
                                        <li>
                                            Term loans to Export oriented MSMEs forexpansion, modernisation, diversification, technology / capacity upgradation, product R&D, etc, by investment in Land and building, machinery and equipment, etc
                                        </li>
                                        <li>
                                            Need based financial assistance, subject to maximum of 80% of the project cost
                                        </li>
                                        <li>
                                            Joint financing with EXIM Bank available
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Assistance to Re-Energize Capital Investments by SMEs (ARISE)</td>
                                <td>
                                    <ul>
                                        <li>
                                            Financial assistance shall be provided to brownfield / existing entities for undertaking expansion / modernization / capital expenditure in the same line of business
                                        </li>
                                        <li>
                                            TL upto `700 Lakh, subject to maximum of 80% of the project cost
                                        </li>
                                        <li>
                                            Till July 31, 2022 (or) utilization of the corpus as allocated/decided from time to time, whichever is earlier
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>SIDBI assistance to healthcare sector in war against second wave of covid-19 (SHWAS)</td>
                                <td>
                                    <ul>
                                        <li>
                                            Micro, Small & Medium Enterprises engaged in manufacturing of oxygen cylinders, oxy-generators, oxygen concentrators, liquid oxygen or providing services in transportation, storage, refilling to supply of these items
                                        </li>
                                        <li>
                                            Term Loan for purchase of equipment / machines
                                        </li>
                                        <li>
                                            Working Capital Term Loan for purchase of raw materials or executing confirmed orders
                                        </li>
                                        <li>
                                            Maximum: 200 lakh
                                        </li>
                                        <li>
                                            RoI:4.50%-5% p.a
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>SIDBI assistance to msmes for recovery & organic growth during covid-19 pandemic (AROG)</td>
                                <td>
                                    <ul>
                                        <li>
                                            Micro, Small & Medium Enterprises engaged in manufacturing of products or providing services which are directly related to fighting corona virus, such as Pulse Oximeters, Permitted drugs (Remdesivir, Fabiflu, Dexamethasone, Azithromycin, etc), Ventilators, PPEs etc.
                                        </li>
                                        <li>
                                            Term Loan for purchase of equipment / machines
                                        </li>
                                        <li>
                                            Working Capital Term Loan for purchase of raw materials or executing confirmed orders
                                        </li>
                                        <li>
                                            Maximum:200 lakh
                                        </li>
                                        <li>
                                            RoI:5.50%- 6% p.a
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>SIDBI term-loan assistance for rooftop solar PV plants (STAR)</td>
                                <td>
                                    <ul>
                                        <li>
                                            Helping MSMEs to reduce their Power bill
                                        </li>
                                        <li>
                                            Across the segment coverage with 25 KW to 500 KW plants (indicative)
                                        </li>
                                        <li>
                                            Loan Amount: ₹10 lakh to ₹250 lakh
                                        </li>
                                        <li>
                                            Quick sanction and fast disbursement
                                        </li>
                                        <li>
                                            Solar Panels / Equipment’s (Including all accessories) from established suppliers, manufacturers, aggregators, etc
                                        </li>
                                        <li>
                                            Installation cost
                                        </li>
                                        <li>
                                            9.10% to 10.20% p.a
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>SIDBI thematic assistance for purchase of capital assets in new enterprises (STHAPAN)</td>
                                <td>
                                    <ul>
                                        <li>
                                            Financial assistance to Greenfield Units for setting up new units which includes
                                        </li>
                                        <li>
                                            purchase of land
                                        </li>
                                        <li>
                                            construction of factory building
                                        </li>
                                        <li>
                                            purchase of equipment, plant & MFA etc
                                        </li>
                                        <li>
                                            TL upto `2000 Lakh, subject to maximum of 75% of the project cost
                                        </li>
                                        <li>
                                            Till October 31, 2022 (or) utilization of the corpus as allocated/decided from time to time, whichever is earlier
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>SIDBI Cluster Development Fund (SCDF)</td>
                                <td>
                                    <ul>
                                        <li>
                                            SCDF shall extend support to State Governments to create infrastructure towards development of MSME clusters. 
                                        </li>
                                        <li>
                                            Under Soft Intervention, SIDBI considers thematic engagements in clusters and supports in addressing its skilling/reskilling, technology, marketing, credit facilitation gaps etc.
                                        </li>
                                        <li>
                                            Financing pattern for eligible categories are as follows:
                                            <ul>
                                                <li>
                                                    Industrial and Agri-allied sectors in the MSME eco-space
                                                </li>
                                                <li>
                                                    Social sectors in and around MSME clusters
                                                </li>
                                                <li>
                                                    Connectivity (Roads/Bridges) to MSME Clusters
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong class="text-danger">Note : </strong> 
                                        For more Information, please visit SIDBI Website <a href="https://www.sidbi.in/en" target="_blank">https://www.sidbi.in</a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <strong class="text-danger">Note :</strong> The aforementioned schemes of SIDBI provided for dissemination of information. To get more information and other prerequisites, please do visit <a href="https://www.sidbi.in" target="_blank"> https://www.sidbi.in</a>
                    </p>
                </div>
                <div class="table-responsive d-none">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th colspan="2">scheme Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Objective</th>
                                <td>content</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</section>
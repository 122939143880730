import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-swipertest',
  templateUrl: './swipertest.component.html',
  styleUrls: ['./swipertest.component.css']
})
export class SwipertestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}




<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Important Links
                    </h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Important Links</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
  <div class="accordion" id="faq">
                      <div class="card">
                          <div class="card-header" id="apserviceshead">
                              <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#apservices"
                              aria-expanded="true" aria-controls="apservices">State Government's MSME Relevant Website Links</a>
                          </div>
  
                          <div id="apservices" class="collapse show" aria-labelledby="apserviceshead" data-parent="#faq">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <p>For all Industries related information in AP - <a href="https://www.apindustries.gov.in/APIndus/Default.aspx" target="_blank">AP Industries</a></p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <p>For all Industrial parks/sites in AP - <a href="https://www.apiic.in/" target="_blank">APIIC</a></p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <p>For all Food Processing Industries in AP - <a href="https://apfps.ap.gov.in/" target="_blank">APFPS</a></p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <p>For Industrial Financial in AP - <a href="https://esfc.ap.gov.in/" target="_blank">APSFC</a></p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <p>For Principal Finance Institution - <a href="https://www.sidbi.in/en" target="_blank">SIDBI</a></p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                   
                                    
                                </div>
                              </div>
                          </div>
                      </div>
                      <div class="card my-4">
                          <div class="card-header" id="centralserviceshead">
                              <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#centralservices"
                              aria-expanded="true" aria-controls="centralservices">Central Government's MSME Relevant Website Links</a>
                          </div>
  
                          <div id="centralservices" class="collapse" aria-labelledby="centralserviceshead" data-parent="#faq">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <ul>
                                                    <li><i class="fa fa-check"></i><a href="http://dcmsme.gov.in/" target="_blank">MSME-DC</a></li>                                                  
                                                    <li><i class="fa fa-check"></i><a href="https://udyamregistration.gov.in/Government-India/Ministry-MSME-registration.htm" target="_blank">Udyami Registration</a></li>                                                  
                                                    <li><i class="fa fa-check"></i><a href="https://msme.gov.in/zero-defect-zero-effect" target="_blank">ZED Registration</a></li>
                                                    <li><i class="fa fa-check"></i><a href="https://www.india.gov.in/spotlight/government-e-marketplace-procurement-made-smart#tab=tab-1" target="_blank">GeM</a></li>                                                  
                                                    <li><i class="fa fa-check"></i><a href="https://www.nsic.co.in/" target="_blank">NSIC</a></li>
                                                    <li><i class="fa fa-check"></i><a href="http://coirboard.gov.in/" target="_blank">COIR Board</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <ul>
                                                    <li><i class="fa fa-check"></i><a href="https://www.kviconline.gov.in/" target="_blank">KVIC</a></li>
                                                    <li><i class="fa fa-check"></i><a href="https://www.ecgc.in/" target="_blank">ECGC</a></li>
                                                    <li><i class="fa fa-check"></i><a href="https://mnre.gov.in/" target="_blank">MNRE</a></li>                                                  
                                                    <li><i class="fa fa-check"></i><a href="https://nsdcindia.org/" target="_blank">NSDC</a></li>                                                    
                                                    <li><i class="fa fa-check"></i><a href="https://msme.gov.in/" target="_blank">Ministry of MSME</a></li>
                                                    <li><i class="fa fa-check"></i><a href="http://texmin.nic.in/" target="_blank">Ministry of Textiles</a></li>                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="footer-widget">
                                            <div class="content">
                                                <ul>
                                                    <li><i class="fa fa-check"></i><a href="http://nhb.gov.in/" target="_blank">NHB</a></li>
                                                    <li><i class="fa fa-check"></i><a href="https://www.dgft.gov.in/CP/" target="_blank">DGFT</a></li>
                                                    <li><i class="fa fa-check"></i><a href="https://apeda.gov.in/apedawebsite/" target="_blank">APEDA</a></li>                       
                                                    <li><i class="fa fa-check"></i><a href="https://mpeda.gov.in/" target="_blank">MPEDA</a></li>                                                  
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
      </div>
    </section>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { TestingComponent } from './testing/testing.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { MicroindustriesComponent } from './microindustries/microindustries.component';
import { QuicklinksComponent } from './quicklinks/quicklinks.component';
import { PoliciesComponent } from './policies/policies.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { OrganizationstructureComponent } from './organizationstructure/organizationstructure.component';
import { ContactusComponent } from './contactus/contactus.component';

import { ProcessthreeComponent } from './processthree/processthree.component';
import { ProcessfourComponent } from './processfour/processfour.component';
import { ProcessfiveComponent } from './processfive/processfive.component';
import { ServiceoneComponent } from './serviceone/serviceone.component';
import { ServicetwoComponent } from './servicetwo/servicetwo.component';
import { ServicethreeComponent } from './servicethree/servicethree.component';
import { ServicefourComponent } from './servicefour/servicefour.component';
import { ActivitiesComponent } from './activities/activities.component';
import { HomesidebarComponent } from './homesidebar/homesidebar.component';
import { UserService } from './user.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportantLinksComponent } from './important-links/important-links.component';
import { MembersComponent } from './members/members.component';
import { PotentialclustersComponent } from './potentialclusters/potentialclusters.component';
import { QueryformComponent } from './queryform/queryform.component';
// import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BoardmembersComponent } from './boardmembers/boardmembers.component';
import { LatestnotificationsComponent } from './latestnotifications/latestnotifications.component';
import { LatestpoliciesComponent } from './latestpolicies/latestpolicies.component';
import { OngoingclustersComponent } from './ongoingclusters/ongoingclusters.component';
import { SwipertestComponent } from './swipertest/swipertest.component';
import { ClusterprocessComponent } from './clusterprocess/clusterprocess.component';
import { SuggestiveclustersComponent } from './suggestiveclusters/suggestiveclusters.component';
import { NewcontactusComponent } from './newcontactus/newcontactus.component';
import { GosComponent } from './gos/gos.component';
import { TendersComponent } from './tenders/tenders.component';
import { VacanciesComponent } from './vacancies/vacancies.component';
import { MsmecdpComponent } from './msmecdp/msmecdp.component';
import { SfurtiComponent } from './sfurti/sfurti.component';
import { MarketingsupportComponent } from './marketingsupport/marketingsupport.component';
import { LwedistrictsComponent } from './lwedistricts/lwedistricts.component';
import { AspirationaldistrictsComponent } from './aspirationaldistricts/aspirationaldistricts.component';
import { DirectorsComponent } from './directors/directors.component';
import { ExecutivebodyComponent } from './executivebody/executivebody.component';
import { SidbiComponent } from './sidbi/sidbi.component';
import { ApsfcComponent } from './apsfc/apsfc.component';
import { RtiactsComponent } from './rtiacts/rtiacts.component';
import { PmegpComponent } from './pmegp/pmegp.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NotificationsComponent,
    TestingComponent,
    ObjectivesComponent,
    MicroindustriesComponent,
    QuicklinksComponent,
    PoliciesComponent,
    OrganizationstructureComponent,
    ContactusComponent,
    ProcessthreeComponent,
    ProcessfourComponent,
    ProcessfiveComponent,
    ServiceoneComponent,
    ServicetwoComponent,
    ServicethreeComponent,
    ServicefourComponent,
    ActivitiesComponent,
    HomesidebarComponent,
    ImportantLinksComponent,
    MembersComponent,
    PotentialclustersComponent,
    QueryformComponent,
    BoardmembersComponent,
    LatestnotificationsComponent,
    LatestpoliciesComponent,
    OngoingclustersComponent,
    SwipertestComponent,
    ClusterprocessComponent,
    SuggestiveclustersComponent,
    NewcontactusComponent,
    GosComponent,
    TendersComponent,
    VacanciesComponent,
    MsmecdpComponent,
    SfurtiComponent,
    MarketingsupportComponent,
    LwedistrictsComponent,
    AspirationaldistrictsComponent,
    DirectorsComponent,
    ExecutivebodyComponent,
    SidbiComponent,
    ApsfcComponent,
    RtiactsComponent,
    PmegpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule, 
    CarouselModule,
    CommonModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
    // RecaptchaModule,
    // RecaptchaFormsModule
  ],
  providers: [UserService,
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.recaptcha.siteKey,
    //   } as RecaptchaSettings,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

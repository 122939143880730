import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { UserService } from '../user.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-queryform',
  templateUrl: './queryform.component.html',
  styleUrls: ['./queryform.component.css']
})
export class QueryformComponent implements OnInit {
  MsmeForm:FormGroup;
  submitted  =  false;
  token: string|undefined;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  @Output()
  formSuccess = new EventEmitter<string>();

  constructor(private formBuilder: FormBuilder, private userSer: UserService, private toastr: ToastrService) {
    this.token = undefined;
    
   }

  ngOnInit(): void {
    this.MsmeForm  =  this.formBuilder.group({
      customerType: ['', Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      district: ['', Validators.required],
      email:['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
      mobilenumber:['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      sector: ['', Validators.required],
      query: ['', Validators.required],
      recaptcha: ['null', Validators.required]
    });
    // Validators.pattern('^[6-9]\d{9}$')
    
    }
    get f(){
    return this.MsmeForm.controls;
    }
   
    onSubmit()
    {
      console.log(this.MsmeForm.value);
      this.submitted = true;

      if(this.MsmeForm.invalid)
      {
      console.log(this.MsmeForm.value);
      // alert('NOT SUCCESS!! :-)\n\n' + JSON.stringify(this.MsmeForm.value, null, 2));
     
      
        return;
      }
      else{
        if (this.validateSubmit()) {
        console.log(this.MsmeForm.value);
        var input =  {
          "Name": this.MsmeForm.value.name,
          "Designation": this.MsmeForm.value.designation,
          "District":this.MsmeForm.value.district,
          "MobileNo": this.MsmeForm.value.mobilenumber,
          "Email": this.MsmeForm.value.email,
          "InterestedSector": this.MsmeForm.value.sector,
          "QueryDescription": this.MsmeForm.value.query,
          "grecaptcha_": this.MsmeForm.value.customerType
          }
        
        this.userSer.queryFormSubmit(input).subscribe((data: any) => {
          console.log(data)
          if(data.isSuccess){
            Swal.fire('Success', 'Request submitted successfully', 'success')
            // this.toastr.error('Request submitted successfully','Success')
            this.formSuccess.emit('success')
          }
        })
      
      this.submitted = false;
      this.MsmeForm.reset();
    }
    }
      // this.router.navigate(['/admin']);
    } 

    mobileValidCheck(event) {

      if (!this.mobileNumCheck(event.target.value)) {
        Swal.fire('Error', 'Please Enter Valid Mobile Number', 'error');

        return false;
      }
    }
    mobileNumCheck(data): boolean {
      const response = data.match('[6-9]{1}[0-9]{9}');
      if (response) {
        const invalidNumbers = [
          '6666666666',
          '7777777777',
          '8888888888',
          '9999999999'
        ];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < invalidNumbers.length; i++) {
          if (data === invalidNumbers[i]) {
            return false;
          }
        }
        return true;
      }
      else {
        return false;
      }
    }

    validateSubmit(){
      if (!this.mobileNumCheck(this.MsmeForm.get('mobilenumber').value)) {
        Swal.fire('Error', 'Please Enter Valid Mobile Number', 'error');
        return false;
      }
      else {
        return true
      }
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
  }

<style>
    .breadcrumb {
        padding: 5px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    
    .breadcrumb>li:first-child {
        padding-left: 10px;
    }
    
    .breadcrumb>li {
        display: inline-block;
        background: #f5f5f5;
        color: #9d9d9d;
        border-radius: 25px;
        padding: 1px 20px 1px 20px;
        /* padding: 1px 15px 1px 20px; */
        margin-left: -17px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
    }
    
    .breadcrumb>.active {
        color: #fff;
        background: #1acc8d;
        /* background: #7ab800; */
    }
    
    .breadcrumb>li+li:after,
    .breadcrumb>li+li:before {
        content: "";
        padding: 0 5px;
        color: #ccc;
        background: #f5f5f5;
        border-radius: 25px;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 0;
    }
    
    .breadcrumb>li+li:after {
        z-index: 1;
        height: 22px;
        top: -1px;
        left: -7px;
        background: #fff;
    }
    
    a {
        text-decoration: none;
    }
</style>
<section class="breadcrumb">
    <div class="container breadcrumb_container">
        <div class="row">
            <div class="hidden-xs col-sm-12 col-md-12">
                <ol class="breadcrumb">
                    <li><a routerLink="/home">Home</a></li>
                    <!-- <li>Careers</li> -->
                    <li class="active">Members</li>
                </ol>
            </div>
            <div class="col-sm-6 col-md-6 d-none">
                <div class="sharethis-inline-share-buttons st-right  st-inline-share-buttons st-animated" id="st-1">
                    <div class="st-total ">
                        <span class="st-label">69</span>
                        <span class="st-shares">
        Shares
        </span>
                    </div>
                    <div class="st-btn st-first" data-network="linkedin" style="display: inline-block;">
                        <img alt="linkedin sharing button" src="https://platform-cdn.sharethis.com/img/linkedin.svg">

                    </div>
                    <div class="st-btn" data-network="twitter" style="display: inline-block;">
                        <img alt="twitter sharing button" src="https://platform-cdn.sharethis.com/img/twitter.svg">

                    </div>
                    <div class="st-btn" data-network="reddit" style="display: inline-block;">
                        <img alt="reddit sharing button" src="https://platform-cdn.sharethis.com/img/reddit.svg">

                    </div>
                    <div class="st-btn" data-network="telegram" style="display: inline-block;">
                        <img alt="telegram sharing button" src="https://platform-cdn.sharethis.com/img/telegram.svg">

                    </div>
                    <div class="st-btn st-last" data-network="email" style="display: inline-block;">
                        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="mb-5 pt-3 d-none">
<div class="container">
    <div class="section-title">
        <h2>Important Links</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                hic quas.</p> -->
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table" style="width: 100%;">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Photo</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>
                                <img src="assets/images/members/Vanka Ravindranath.jpeg" alt="" width="150" height="150">
                            </td>
                            <td>
                                <div><Strong>Name : </Strong> Vanka Ravindranath</div>
                                <div><strong>Designation : </strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>
                                <img src="assets/images/members/Vanka Ravindranath.jpeg" alt="" width="150" height="150">
                            </td>
                            <td>
                                <div><Strong>Name : </Strong> Vanka Ravindranath</div>
                                <div><strong>Designation : </strong></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</section>

<section class="mb-5 pt-3">
    <div class="container">
        <div class="section-title">
            <h2>Board Members</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                    hic quas.</p> -->
        </div>
        <div class="row pb-3">
            <div class="col-md-3"></div>
            <div class="col-md-3">
                <div class="chairmanProfile">
                    <div class="chairmanImage">
                        <img src="assets/images/members/Vanka Ravindranath.jpeg" alt="image" class="img-fluid">
                    </div>
                    <div class="chairmanDetails">
                        <h6 class="name">Sri Vanka Ravindranath</h6>
                        <h6 class="designation">Chairman</h6>
                    </div>
                    <a class="btn btn-info" data-toggle="modal" data-target="#basicModal" style="cursor: pointer;">Profile</a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="ceoProfile">
                    <div class="ceoImage">
                        <img src="assets/images/members/ceo.png" alt="image" class="img-fluid">
                    </div>
                    <div class="ceoDetails">
                        <h6 class="name">CEO Name</h6>
                        <h6 class="designation">CEO</h6>
                    </div>
                    <a class="btn btn-info" data-toggle="modal" data-target="#basicModal" style="cursor: pointer;">Profile</a>
                </div>
            </div>
            <div class="col-md-3"></div>

        </div>
        <div class="row pt-5">
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="ananda memberImage">
                        <img src="assets/images/members/ananda Parthasaradi.jpeg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri S. Ananda Pardasaradhi</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="memberImage">
                        <img src="assets/images/members/SARADA KASIREDDI.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Smt K. Sharadha</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
               <div class="memberProfile">
                <div class="memberImage">
                    <img src="assets/images/members/Seelam Nadiya.jpg" alt="image" class="img-fluid">
                </div>
                <div class="memberDetails">
                    <h6>Smt S. Nadiya</h6>
                </div>
               </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="memberImage">
                        <img src="assets/images/members/shaik.karimulla.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri SK. Karimulla</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="memberImage">
                        <img src="assets/images/members/Vijayalaxmi.JPG" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Smt B. Vijayalaxmi</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="memberImage">
                        <img src="assets/images/members/varalakshmi.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Smt G. Varalakshmi</h6>
                    </div>
                </div>
            </div>
            </div>
            <div class="row pt-3">
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="janki memberImage">
                        <img src="assets/images/members/Nalla Baby Janki.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Smt. Nalla Baby Janaki</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="raghu memberImage">
                        <img src="assets/images/members/Nituri Raghunatha Reddy.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri N. Raghunath Reddy</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="memberImage">
                        <img src="assets/images/members/anjaney.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri T. Anjaney</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="shankar memberImage">
                        <img src="assets/images/members/shankar.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri N. Gowri Shankar Rao</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="meda memberImage">
                        <img src="assets/images/members/Meda.jpg" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri M. Venkata Badri Narayana</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="memberProfile">
                    <div class="ramana memberImage">
                        <img src="assets/images/members/ramana.png" alt="image" class="img-fluid">
                    </div>
                    <div class="memberDetails">
                        <h6>Sri Y. Venkataramana Rao</h6>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>



<div class="modal fade" id="basicModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-1">
          <h4 class="modal-title" id="myModalLabel">Chairman Profile</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="profileContent">
                            <p>Shri Vanka Ravindra Nath is a well renowned and reputed industrialist having experience of over 35
                                years in manufacturing sector and international trade.</p>
                            <p>Shri Vanka Ravindranath is the son of Late Shri Vanka Satyanarayana (Freedom fighter and 3 times MLA,
                                Penugonda). He is born in Tanuku, West Godavari district and studied Sugar Technology at
                                Visakhapatnam.</p>
                            <p>He Started his own business of Hair exports in 1989 under SSI (Small scale industries), successfully
                                establishing it as one of the largest units in the country. Later on he established spinning mills and
                                entered into textile sector.</p>
                        </div>
                        <div class="achievements">
                            <h4 class="font-weight-bold">Achievements</h4>
                            <hr>
                            <div class="modalUl pt-2">
                                <ul>
                                    <li><i class="fas fa-chevron-circle-right"></i>National Export Award, Ministry of Industries, Govt. of India: 2 times (1997 & 1999)</li>
                                    <li><i class="fas fa-chevron-circle-right"></i>Top Export Award ( National Wide), ( PLEXCONCIL ) : 2 times ( 2000 & 2001)</li>
                                    <li><i class="fas fa-chevron-circle-right"></i>FAPCII award – Best SSI Exporter in AP: 2 times</li>
                                    <li><i class="fas fa-chevron-circle-right"></i>FIEO Award (National wide) – Niryat Ratna award: 3 times ( 2001,2015 & 2016)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="achievements pt-2">
                            <h4 class="font-weight-bold">Countries Visited</h4>
                            <hr>
                            <div class="countries pt-2">
                                <p>Australia, Belgium ,Brazil , Burma, Bangladesh, China, Check, France, Germany, Hong Kong, Italy,
                                    Indonesia, Japan, Korea, Nepal, Portugal, Spain, South Africa, Switzerland, UAE,UK, USA & Vietnam.</p>
                            </div>
                        </div>
                        <div class="text-right">
          <button type="button" class="btn btn-primary btn-md" data-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer d-none">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
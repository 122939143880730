<style>
    .breadcrumb {
        padding: 5px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    
    .breadcrumb>li:first-child {
        padding-left: 10px;
    }
    
    .breadcrumb>li {
        display: inline-block;
        background: #f5f5f5;
        color: #9d9d9d;
        border-radius: 25px;
        padding: 1px 20px 1px 20px;
        /* padding: 1px 15px 1px 20px; */
        margin-left: -17px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
    }
    
    .breadcrumb>.active {
        color: #fff;
        background: #1acc8d;
        /* background: #7ab800; */
    }
    
    .breadcrumb>li+li:after,
    .breadcrumb>li+li:before {
        content: "";
        padding: 0 5px;
        color: #ccc;
        background: #f5f5f5;
        border-radius: 25px;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 0;
    }
    
    .breadcrumb>li+li:after {
        z-index: 1;
        height: 22px;
        top: -1px;
        left: -7px;
        background: #fff;
    }
    
    a {
        text-decoration: none;
    }
</style>
<section class="breadcrumb">
    <div class="container breadcrumb_container">
        <div class="row">
            <div class="hidden-xs col-sm-12 col-md-12">
                <ol class="breadcrumb">
                    <li><a routerLink="/home">Home</a></li>
                    <!-- <li>Careers</li> -->
                    <li class="active">Micro Industries</li>
                </ol>
            </div>
            <div class="col-sm-6 col-md-6 d-none">
                <div class="sharethis-inline-share-buttons st-right  st-inline-share-buttons st-animated" id="st-1">
                    <div class="st-total ">
                        <span class="st-label">69</span>
                        <span class="st-shares">
        Shares
        </span>
                    </div>
                    <div class="st-btn st-first" data-network="linkedin" style="display: inline-block;">
                        <img alt="linkedin sharing button" src="https://platform-cdn.sharethis.com/img/linkedin.svg">

                    </div>
                    <div class="st-btn" data-network="twitter" style="display: inline-block;">
                        <img alt="twitter sharing button" src="https://platform-cdn.sharethis.com/img/twitter.svg">

                    </div>
                    <div class="st-btn" data-network="reddit" style="display: inline-block;">
                        <img alt="reddit sharing button" src="https://platform-cdn.sharethis.com/img/reddit.svg">

                    </div>
                    <div class="st-btn" data-network="telegram" style="display: inline-block;">
                        <img alt="telegram sharing button" src="https://platform-cdn.sharethis.com/img/telegram.svg">

                    </div>
                    <div class="st-btn st-last" data-network="email" style="display: inline-block;">
                        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bodyHeading mt-4 d-none" id="bodyHeading">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <h2>Micro Industries</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="two mt-3" id="two">
    <style>
        .section-title {
            text-align: center;
            /* padding-bottom: 30px; */
        }
        
        .section-title h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            /* color: #2c4964; */
            /* color: #615cfb; */
            color: #11558a;
        }
        
        .section-title h2::before {
            content: '';
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            /* left: calc(5% - 60px); */
            left: calc(50% - 60px);
        }
        
        .section-title h2::after {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: #1977cc;
            bottom: 0px;
            /* left: calc(5% - 20px); */
            left: calc(50% - 20px);
        }
        
        .section-title p {
            margin-bottom: 0;
        }
    </style>
    <div class="container">
        <div class="section-title">
            <h2>Micro Industries</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                hic quas.</p> -->
        </div>
    </div>
</section>
<section class="two mt-2 d-none" id="two">
    <!-- <style>
        .section-title {
            text-align: center;
            padding: 30px 0;
            position: relative;
        }
        
        .section-title span {
            position: absolute;
            top: 30px;
            color: #f0f1f3;
            left: 0;
            right: 0;
            z-index: 1;
            font-weight: 700;
            font-size: 52px;
            text-transform: uppercase;
            line-height: 0;
        }
        
        .section-title h2 {
            font-size: 32px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
            padding-bottom: 0;
            color: #5f687b;
            position: relative;
            z-index: 2;
        }
        
        .section-title p {
            margin-bottom: 0;
            position: relative;
            z-index: 2;
        }
    </style> -->
    <div class="container">
        <div class="section-title">
            <span>Micro Industries</span>
            <h2>Micro Industries</h2>
            <!-- <p>Sit sint consectetur velit quisquam cupiditate impedit suscipit alias</p> -->
        </div>

    </div>
</section>
<section class="bodyContentSection mt-4" id="bodyContentSection">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="bodyContent">
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi
                        quidem hic quas. Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit
                        in iste officiis commodi quidem hic quas.</p>
                    <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>

                </div>
            </div>
            <div class="col-md-5">
                <div class="bodyImage">
                    <img src="assets/images/microindustries/1.png" alt="image" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="bodyContent">
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi
                        quidem hic quas. Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit
                        in iste officiis commodi quidem hic quas.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Potential Clusters</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Projects</a></li>
                        <li><a style="color: #fff;">Potential Clusters</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section_content">
                    <p>Recently, A baseline survey has been conducted in all 26 districts of AP and found 100 clusters are working in various sectors, out of which the following 38 clusters have been identified as potential clusters to take up under MSE-CDP scheme.</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>District Name</th>
                                <th>Address/Location of Cluster</th>
                                <th>Name of Cluster/Sector</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Kakinada</td>
                                <td>Sarpavaram,  Kakinada</td>
                                <td>Mango Jelly Cluster</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Kakinada</td>
                                <td>Samalkota</td>
                                <td>Starch & Sago Cluster</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Kakinada</td>
                                <td>Tuni</td>
                                <td>Cashew Cluster</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Konaseema</td>
                                <!-- <td>Dr. B.R Ambedkar Konaseema</td> -->
                                <td>Mamidikuduru</td>
                                <td>Mamidikuduru Coir Cluster</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Konaseema</td>
                                <!-- <td>Dr. B.R Ambedkar Konaseema</td> -->
                                <td>Amalapuram</td>
                                <td>Amalapuram coir cluster</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Konaseema</td>
                                <!-- <td>Dr. B.R Ambedkar Konaseema</td> -->
                                <td>Mandapeta</td>
                                <td>Gold Jewellery Cluster</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>West Godavari</td>
                                <td>Palakollu</td>
                                <td>Palakollu Coir Cluster</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>ELURU</td>
                                <td>Eluru</td>
                                <td>Gold Ornaments Cluster</td>
                            </tr>
                            <!-- <tr>
                                <td>9</td>
                                <td>Krishna</td>
                                <td>Machilipatnam</td>
                                <td>*Port based Industrial cluster</td>
                            </tr> -->
                            <tr>
                                <td>9</td>
                                <td>NTR</td>
                                <td>Vijayawada</td>
                                <td>Printing Cluster</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>SPS Nellore</td>
                                <td>Udayagiri</td>
                                <td>Wooden Cutlery Cluster</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>SPS Nellore</td>
                                <td>Patur, Kovur (M)</td>
                                <td>Cotton Jari Sarees</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>SPS Nellore</td>
                                <td>Nellore (M)</td>
                                <td>Rice Mill Cluster</td>
                            </tr>
                            
                            <tr>
                                <td>13</td>
                                <td>SPS Nellore</td>
                                <td>Autonagar, Nellore</td>
                                <td>Engineering Cluster</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>SPS Nellore</td>
                                <td>Naidupalem (V), Kodavalur (M)</td>
                                <td>Pottery Cluster</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>SPS Nellore</td>
                                <td>Kavali Town</td>
                                <td>Readymade Garments Cluster</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>Nandyal</td>
                                <td>Shilpakala Nagar, Allagadda</td>
                                <td>Stone Carving (Making of Stone idols)</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>Nandyal</td>
                                <td>Slab Polishing units, Bethamcherla</td>
                                <td>Slab Polishing</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>Ananthapuramu</td>
                                <td>Rayadurgam</td>
                                <td>Ready made garments (Jeans Fants, Cotton Fants)</td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>Ananthapuramu</td>
                                <td>Kalyanadurgam</td>
                                <td>Jewellery</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>Sri Sathya Sai</td>
                                <td>Dharmavaram</td>
                                <td>SilkReeling Cluster, Dharmavaram</td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td>Sri Sathya Sai</td>
                                <td>Hindupur</td>
                                <td>SilkReeling Cluster, Hindupur</td>
                            </tr>
                            <tr>
                                <td>22</td>
                                <td>Sri Sathya Sai</td>
                                <td>Nimmalakunta Village, Dharmavaram Mandal</td>
                                <td>Puppet Making (mfg. of leather Puppets), Nimmlakunta, Near Dharmavaram</td>
                            </tr>
                            <tr>
                                <td>23</td>
                                <td>Sri Sathya Sai</td>
                                <td>Hindupur</td>
                                <td>Automobile Cluster</td>
                            </tr>
                            <tr>
                                <td>24</td>
                                <td>YSR District</td>
                                <td>Proddatur</td>
                                <td>Fly Ash Bricks Cluster</td>
                            </tr>
                            <tr>
                                <td>25</td>
                                <td>YSR District</td>
                                <td>Proddatur</td>
                                <td>Gold jewellery cluster</td>
                            </tr>
                            <tr>
                                <td>26</td>
                                <td>YSR District</td>
                                <td>Proddatur</td>
                                <td>Cloth Producers Cluster</td>
                            </tr>
                            <tr>
                                <td>27</td>
                                <td>YSR District</td>
                                <td>Proddatur</td>
                                <td>Brass & Copper Articles</td>
                            </tr>
                            <tr>
                                <td>28</td>
                                <td>YSR District</td>
                                <td>Yerraguntla</td>
                                <td>Slap Polishing Units</td>
                            </tr>
                            <tr>
                                <td>29</td>
                                <td>Annamayya</td>
                                <td>Madanapalli</td>
                                <td>Bus Body Building</td>
                            </tr>
                            <tr>
                                <td>30</td>
                                <td>Annamayya</td>
                                <td>Madanapalli</td>
                                <td>Power Loom Cluster</td>
                            </tr>
                            <tr>
                                <td>31</td>
                                <td>Annamayya</td>
                                <td>Madanapalli</td>
                                <td>Madanplle Embroidery Applique work</td>
                            </tr>
                            <tr>
                                <td>32</td>
                                <td>Annamayya</td>
                                <td>Settigunta, Lakshmigarpally</td>
                                <td>Wooden Toys Cluster</td>
                            </tr>
                            <tr>
                                <td>33</td>
                                <td>Chittoor</td>
                                <td>Nagari</td>
                                <td>Dyeing Cluster, Nagari</td>
                            </tr>
                            <tr>
                                <td>34</td>
                                <td>Chittoor</td>
                                <td>Nagari</td>
                                <td>Power Loom cluster, Nagari</td>
                            </tr>
                            <tr>
                                <td>35</td>
                                <td>Tirupati</td>
                                <td>Yerramareddy Palyam Village, Settipalli Post, Renigunta Mandal</td>
                                <td>Metal Handicraft Cluster of Tirupati (Handicrafts Sector)</td>
                            </tr>
                            <tr>
                                <td>36</td>
                                <td>Tirupati</td>
                                <td>Srikalahasthi</td>
                                <td>Kalamkari hand paintings</td>
                            </tr>
                            <tr>
                                <td>37</td>
                                <td>Bapatla</td>
                                <td>Vetapalem</td>
                                <td>Cashew cluster</td>
                            </tr>
                            <tr>
                                <td>38</td>
                                <td>Srikakulam</td>
                                <td>Palasa</td>
                                <td>Cashew cluster</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>




<section class="section_bg d-none">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section_content">
                    <p>The following Potential Clusters have been identified by the GM, DIC's and MSME-DI in Andhra Pradesh</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="content">
                    <ul>
                        <li>
                            Bamboo Cluster, Rajam, Srikakulam
                        </li>
                        <li>
                            Cashew cluster Srikakulam
                        </li>
                        <li>
                            Printing Cluster Srikakulam
                        </li>
                        <li>
                            Rice Mill cluster Nellore
                        </li>
                        <li>
                            Woodwork cluster, Nellore
                        </li>
                        <li>
                            Steel furniture cluster Krishna
                        </li>
                        <li>
                            Auto components cluster, Krishna
                        </li>
                        <li>
                            Seeds processing cluster Nandyal, Kurnool
                        </li>
                        <li>
                            Edible oils cluster Adoni, Kurnool
                        </li>
                        <li>
                            Agarbatti cluster, Kurnool
                        </li>
                        <li>
                            Automobile cluster, Anantapur & Tadipatri
                        </li>
                        <li>
                            Rice mill cluster EG
                        </li>
                        <li>
                            Gold jewellery cluster, Raghamundry, EG
                        </li>
                        <li>
                            Granet cluster Prakasam
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="content">
                    <ul>
                        <li>
                            Rice mill cluster, Prakasam
                        </li>
                        <li>
                            Agarbatti Cluster Prakasam
                        </li>
                        <li>
                            Engineering cluster Yerraguntla, Kadapa
                        </li>
                        <li>
                            Gold jewellery cluster Proddatur, Kadapa
                        </li>
                        <li>
                            Spices cluster Guntur
                        </li>
                        <li>
                            Garments cluster Pamidi, Anantapur
                        </li>
                        <li>
                            Silk reeling cluster Dharmavaram/ Hindupur Anantapur
                        </li>
                        <li>
                            Cotton sarees cluster Srikakulam
                        </li>
                        <li>
                            Readymade garments cluster Raidurgh, Anantapur
                        </li>
                        <li>
                            Venkatagiri Sarees cluster Nellore Dt
                        </li>
                        <li>
                            Handloom cluster, Punduru, Srikakulam
                        </li>
                        <li>
                            Rice mill cluster Krishna
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section_bg d-none">
    <div class="container">
        <div class="accordion" id="faq">
            <div class="card">
                <div class="card-header" id="nellorehead">
                    <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#nellore"
                    aria-expanded="true" aria-controls="nellore">Nellore</a>
                </div>

                <div id="nellore" class="collapse show" aria-labelledby="nellorehead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Ancillaries to support manufacturing industries located in Sri City
                                            & Chennai</li>
                                          <li><i class="fa fa-check"></i>Aerospace related Industries (as in Hyderabad) to cater to the
                                            needs of the ISRO & Defense labs in Chennai (AVADI)</li>
                                          <li><i class="fa fa-check"></i>Leather Industries and International Leather Park at
                                            Krishnapatnam</li>
                                            <li><i class="fa fa-check"></i>Textile cluster at Naidupeta</li>
                                            <li><i class="fa fa-check"></i>Food Processing Industry (Aqua culture & Kisan SEZ) by IFFCO</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                    </div>
                </div>
            </div>
            <div class="card my-4">
                <div class="card-header" id="chittoor">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#chittoor"
                    aria-expanded="true" aria-controls="chittoor">Chittoor</a>
                </div>

                <div id="chittoor" class="collapse" aria-labelledby="chittoorhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>NTPC – BHEL Mega Industrial Unit at Mannavaram (YSR Nagar)</li>
                                          <li><i class="fa fa-check"></i>Food processing Industry in Chittoor (Mango, Tomato, etc.,)</li>
                                          <li><i class="fa fa-check"></i>IT & Electronic park at Renigunta</li>
                                          <li><i class="fa fa-check"></i>Food processing cluster at Kuppam</li>
                                          <li><i class="fa fa-check"></i>Textile cluster at Nagari</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>                          
                      </div>
                    </div>
                </div>
            </div>
            <div class="card my-4">
                <div class="card-header" id="prakasamhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#prakasam"
                    aria-expanded="true" aria-controls="prakasam">Prakasam</a>
                </div>
            
                <div id="prakasam" class="collapse" aria-labelledby="prakasamhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Granite SEZ growth centre Prakasam, Ongole</li>
                                          <li><i class="fa fa-check"></i>Building material / State Industry area in Markapuram</li>
                                          <li><i class="fa fa-check"></i>MSME cluster at Singarayakonda</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card my-4">
                <div class="card-header" id="gunturhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#guntur"
                    aria-expanded="true" aria-controls="guntur">Guntur</a>
                </div>
            
                <div id="guntur" class="collapse" aria-labelledby="gunturhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Leather cluster of APACHE in Guntur</li>
                                          <li><i class="fa fa-check"></i>MSME Industrial cluster & Skill development University at
                                            Mangalagiri</li>
                                          <li><i class="fa fa-check"></i>Dairy & Diary products Industry in Guntur, Vadlapudi area</li>
                                          <li><i class="fa fa-check"></i>Mega Industrial cluster and Pharma Industry in the coastal area
                                            (VANPIC)</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card my-4">
                <div class="card-header" id="anantapurhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#anantapur"
                    aria-expanded="true" aria-controls="anantapur">Anantapur</a>
                </div>
            
                <div id="anantapur" class="collapse" aria-labelledby="anantapurhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>MSME cluster at Hindupur</li>
                                          <li><i class="fa fa-check"></i>IT & Electronic Aerospace cluster (Chilamatur) at Lepakshi</li>
                                          <li><i class="fa fa-check"></i>Automobile ancillary cluster near Penukonda</li>
                                          <li><i class="fa fa-check"></i>Garment cluster at Penukonda and Raidurg</li>
                                          <li><i class="fa fa-check"></i>Solar park at Kadiri</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="card my-4">
                <div class="card-header" id="ysrhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#ysr"
                    aria-expanded="true" aria-controls="ysr">YSR Cuddapah</a>
                </div>
            
                <div id="ysr" class="collapse" aria-labelledby="ysrhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Steel industry in Cuddapah</li>
                                          <li><i class="fa fa-check"></i>MSME cluster near Kupparthy</li>
                                          <li><i class="fa fa-check"></i>Animal Biotech, Life sciences cluster at Pulivendula</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="card my-4">
                <div class="card-header" id="kurnoolhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#kurnool"
                    aria-expanded="true" aria-controls="kurnool">Kurnool</a>
                </div>
            
                <div id="kurnool" class="collapse" aria-labelledby="kurnoolhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Food processing cluster at Adoni (Onion & Groundnut)</li>
                                          <li><i class="fa fa-check"></i>Minerals cluster near Kurnool</li>
                                          <li><i class="fa fa-check"></i>Food processing cluster (Dairy) at Nandyal</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="card my-4">
                <div class="card-header" id="krishnahead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#krishna"
                    aria-expanded="true" aria-controls="krishna">Krishna</a>
                </div>
            
                <div id="krishna" class="collapse" aria-labelledby="krishnahead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Port based export oriented cluster near Machilipatnam</li>
                                          <li><i class="fa fa-check"></i>IT park in Gannavaram</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            
            <div class="card my-4">
                <div class="card-header" id="westhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#westgodavari"
                    aria-expanded="true" aria-controls="westgodavari">West Godavari</a>
                </div>
            
                <div id="westgodavari" class="collapse" aria-labelledby="westhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Food Processing cluster near Eluru</li>
                                          <li><i class="fa fa-check"></i>Coir industry MSME cluster near Bhimavaram</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>


            <div class="card my-4">
                <div class="card-header" id="easthead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#eastgodavari"
                    aria-expanded="true" aria-controls="eastgodavari">East Godavari</a>
                </div>
            
                <div id="eastgodavari" class="collapse" aria-labelledby="easthead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>IT cluster at Kakinada</li>
                                          <li><i class="fa fa-check"></i>Port based industry near Kakinada</li>
                                          <li><i class="fa fa-check"></i>Petroleum products industry near Rajahmundry</li>
                                          <li><i class="fa fa-check"></i>Helicopter MRO hub in Rajahmundry</li>
                                          <li><i class="fa fa-check"></i>Ceramic & Building material cluster near Sarpavaram</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card my-4">
                <div class="card-header" id="visakhapatnamhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#visakhapatnam"
                    aria-expanded="true" aria-controls="visakhapatnam">Visakhapatnam</a>
                </div>
            
                <div id="visakhapatnam" class="collapse" aria-labelledby="visakhapatnamhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>MSME cluster at Pedagantyada</li>
                                          <li><i class="fa fa-check"></i>PCPIR Petroleum investment region in Visakhapatnam – Kakinada
                                            region</li>
                                          <li><i class="fa fa-check"></i>Pharma cluster at Nakkapally</li>
                                          <li><i class="fa fa-check"></i>Steel ancillary cluster at RINN</li>
                                          <li><i class="fa fa-check"></i>IT cluster at Kapuluppada</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="card my-4">
                <div class="card-header" id="vizianagaramhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#vizianagaram"
                    aria-expanded="true" aria-controls="vizianagaram">Vizianagaram</a>
                </div>
            
                <div id="vizianagaram" class="collapse" aria-labelledby="vizianagaramhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>MSME cluster at Bobbili</li>
                                          <li><i class="fa fa-check"></i>Manganese based industry near Bobbili</li>
                                          <li><i class="fa fa-check"></i>Coir industry cluster at Cheepurapalli</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="card my-4">
                <div class="card-header" id="srikakulamhead">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#srikakulam"
                    aria-expanded="true" aria-controls="srikakulam">Srikakulam</a>
                </div>
            
                <div id="srikakulam" class="collapse" aria-labelledby="srikakulamhead" data-parent="#faq">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="footer-widget">
                                  <div class="content">
                                      <ul>
                                          <li><i class="fa fa-check"></i>Food processing cluster (Cashew) at Palasa</li>
                                          <li><i class="fa fa-check"></i>Beach sand cluster in Srikakulam</li>
                                          <li><i class="fa fa-check"></i>Coir Industry cluster at Palasa</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Micro & Small Enterprises Cluster Development Programme (MSE-CDP) Guidelines</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">Micro & Small Enterprises
                                Cluster Development Programme (MSE-CDP) Guidelines</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>


<section class="section_bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description </h2>
                </div>
                <div class="section_content">
                    <p>The Scheme supports financial assistance for
                        establishment of Common Facility Centres (CFCs) and to
                        create/upgrade infrastructural facilities (IDs) in the
                        new/existing industrial areas/clusters of MSEs.</p>
                </div>
            </div> -->
            <div class="col-md-12">
                <!-- <div class="schemes_content_heading">
                    <h2>Nature of Assistance: </h2>
                </div> -->
                <div class="section_content">


                    <div class="table-responsive">
                        <table class="table table-bordered bg-white">
                            <thead>
                                <tr>
                                    <th>Component</th>
                                    <th>MSE-CDP</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>The Scheme supports financial assistance for
                                        establishment of Common Facility Centres (CFCs) and to
                                        create/upgrade infrastructural facilities (IDs) in the
                                        new/existing industrial areas/clusters of MSEs</td>
                                </tr>
                                <tr>
                                    <td>Common Facility Center (CFC)</td>
                                    <td>
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th rowspan="2">Funding Pattern (%)</th>
                                                            <th colspan="6">Projects From  Rs. 5-10 Cr</th>
                                                            <th colspan="6">Projects From  Rs. 10-30 Cr</th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="3">General Districts</th>
                                                            <th colspan="3">Aspirational Districts</th>
                                                            <th colspan="3">General Districts</th>
                                                            <th colspan="3">Aspirational Districts</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>GOI Grant</td>
                                                            <td colspan="3">70%</td>
                                                            <td colspan="3">80%</td>
                                                            <td colspan="3">60%</td>
                                                            <td colspan="3">70%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>GoAP Matching Grant</td>
                                                            <td colspan="3">20%</td>
                                                            <td colspan="3">15%</td>
                                                            <td colspan="3">20%</td>
                                                            <td colspan="3">15%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPV Contribution</td>
                                                            <td colspan="3">10%</td>
                                                            <td colspan="3">5%</td>
                                                            <td colspan="3">20%</td>
                                                            <td colspan="3">15%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>                                
                                    </td>
                                </tr>
                                <tr>
                                    <td>Infrastructure Development</td>
                                    <td>
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th rowspan="2">Funding Pattern (%)</th>
                                                            <th colspan="6">Projects From  Rs. 5-15 Cr (New Projects)</th>
                                                            <th colspan="6">Projects From  Rs. 5-10 Cr (Expansion Projects)</th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="3">General Districts</th>
                                                            <th colspan="3">Aspirational Districts</th>
                                                            <th colspan="3">General Districts</th>
                                                            <th colspan="3">Aspirational Districts</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>GOI Grant</td>
                                                            <td colspan="3">60%</td>
                                                            <td colspan="3">70%</td>
                                                            <td colspan="3">50%</td>
                                                            <td colspan="3">60%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>GoAP Matching Grant</td>
                                                            <td colspan="3">40%</td>
                                                            <td colspan="3">30%</td>
                                                            <td colspan="3">50%</td>
                                                            <td colspan="3">40%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Implementation Period</td>
                                    <td>
                                        <ul>
                                            <li>
                                                Duration for Implementation is 18 Months after  Final Sanction
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Eligibility</td>
                                    <td>
                                        <ul>
                                            <li>
                                                Minimum No of Units for 5-10 crores is 10 and above 10 Crores is 20
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Who can Apply</td>
                                    <td>
                                        <ul>
                                            <li>
                                                Clusters, Industrial Associations/Consortia
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>How to Apply</td>
                                    <td>
                                        <ul>
                                            <li>
                                                Online applications can be filed at <a href="https://cluster.dcmsme.gov.in" target="_blank">https://cluster.dcmsme.gov.in</a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Whom to Contact</td>
                                    <td>
                                        <ul>
                                            <li>
                                                <!-- MSME Development Institutes under DC-MSME; ADC, O/o DC (MSME), Nirman Bhawan, New Delhi, Tel: 011-23062694. -->
                                                <address>
                                                    The Director<br>
                                                    Cluster Development Division<br>
                                                    Room No: 724<br>
                                                    Office of DC-MSME, 7th Floor <br>
                                                    Nirman Bhavan, New Delhi <br>
                                                    Phone No: 011-23062230
                                                    </address>    
                                            </li>
                                            <li>
                                                AP MSME DC or GMDIC's/DIOs at state level
                                                <!-- GM, DIC or AP MSMEDC at state level -->
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="2"><a href="assets/documents/schemes/New-Guidelines_MSE CDP.pdf" target="_blank">Download Scheme Guidelines</a></th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p class="d-none">
                        Common Facility Center (CFC): The GOI grant will be
                        restricted to 70% of cost of Project of maximum Rs.20.00
                        Crore. GoI grant will be 90% for CFCs in North-East &
                        Hilly Stated, Island territories, Aspirational
                        Districts/LWE affected Districts, Clusters with more
                        than 50% (a) Micro/Village or (b) Women owned or (c)
                        SC/ST units. The cost of Project included cost of Land
                        (Subject to maximum of 25% of Project Cost) (ii)
                        Infrastructure Development: GoI grant will be restricted
                        to 60% of the cost of project (Rs.10.00 Crore for
                        industrial Estate & Rs.15.00 Crore for Flatted Factory
                        Complex). GoI grant will be 80% for Projects in
                        North-East & Hilly Stated, Island territories,
                        Aspirational Districts/ LWE affected Districts
                        industrial areas/ estated/ Flatted Factory Complex with
                        more than 50% a. micro/village or b. women owned or c.
                        SC/ST units (iii) Marketing Hubs/Exhibition Centres by
                        Associations. The GoI grant will be restricted to 60% of
                        the cost of project of maximum Rs.10.00 crore for
                        Product Specific Associations with BMI rating of Gold
                        Category and above from MABET (QCI) and 80% for
                        Associations of Women Entrepreneurs. Remaining project
                        cost is to be borne by SPV. /State Government. (iv)
                        Thematic Interventions. The GoI grant will be restricted
                        to 50% of total cost of maximum 5 activities not
                        exceeding Rs.5.00 Crore whichever is lower and the
                        assistance would be 90% of project cost on respect of
                        CFC project in North-East/Hilly States, Island
                        territories, Aspirational Districts/ LWE affected
                        Districts, as well as for projects where beneficiaries
                        are SC/ST/Women owned enterprise.
                    </p>
                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3 align-items-center">

                    <h2 class="btn-md btnWidth applyButton">
                        Who Can apply
                    </h2>
                    <div class="section_content">
                        <p class="ml-2"> Clusters, Industrial Associations/Consortia.
                        </p>
                    </div>
    
                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3 align-items-center">

                    <h2 class="btn-md btnWidth howButton">
                        How To apply
                    </h2>
                    <div class="section_content">
                        <p class="ml-2">Online applications can be filed at <a href="https://cluster.dcmsme.gov.in" target="_blank">https://cluster.dcmsme.gov.in</a>.
                        </p>
                    </div>
    
                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3 align-items-center">

                    <h2 class="btn-md btnWidth whomButton">
                        Whom To Contact
                    </h2>
                    <div class="section_content">
                        <p class="ml-2">
                            MSME Development Institutes under DC-MSME; ADC, O/o DC (MSME), Nirman Bhawan, New Delhi, 
Tel: 011-23062694.

                        </p>
                    </div>
    
                </div>
            </div>
        </div>

    </div>
</section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>left wing extremist affected districts</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Extremism Affected Districts</a></li>
                        <li><a style="color: #fff;">LWE Affected Districts</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description </h2>
                </div>
                <div class="section_content">
                    <p>
                    The following 6 districts have been notified by Government of India as Left Wing Extremist affected districts and will support
                         with a financial assistance of 90% grant i.e., INR 2.5 cr.
                    </p>
                </div>
                <style>
                    .AllList {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .list {
                        /* background: red; */
                        width: auto;
                        /* max-width: 170px; */
                        display: flex;
                        /* margin: 0 10px; */
                        /* justify-content: space-between; */
                        padding: 0 10px;
                    }
                    .list .number {
                        color: #fff;
                        background: green;
                        border-radius: 50%;
                        padding: 20px;
                    }
                    .btn {
                        cursor: default !important;
                    }
                </style>
                <style>
                    .btn-label {border-radius: 50%; border: 2px solid #fff;position: relative;left: -12px;
                        display: inline-block;padding: 6px 8px;background: rgba(0,0,0,0.15);
                        /* border-radius: 3px 0 0 3px; */
                    }
.btn-labeled {padding-top: 0;padding-bottom: 0;}
.btn { margin-bottom:10px; }
.list .btn {
    border-radius: 24px;
}
                </style>

                <div class="AllList">
                    <div class="list">
                        <div class="btn btn-labeled btn-srikakulam">
                            <span class="btn-label srikakulamLabel">01</span>Srikakulam</div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled btn-vizianagaram">
                            <span class="btn-label vizianagaramLabel">02</span>Vizianagaram</div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled btn-visakhapatnam">
                            <span class="btn-label visakhapatnamLabel">03</span>Visakhapatnam</div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled btn-eastgodavari">
                            <span class="btn-label eastgodavariLabel">04</span>East Godavari
                        </div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled btn-westgodavari">
                            <span class="btn-label westgodavariLabel">05</span>West Godavari
                        </div>
                    </div>
                    <div class="list">
                        <div class="btn btn-labeled btn-guntur">
                            <span class="btn-label gunturLabel">06</span>Guntur</div>
                    </div>
                </div>

                <div class="AllList d-none">
                    <div class="list">
                        <div class="number">01</div>
                        <div class="districtName">Srikakulam</div>
                    </div>
                    <div class="list">
                        <div class="number">02</div>
                        <div class="districtName">Vizianagaram</div>
                    </div>
                    <div class="list">
                        <div class="number">03</div>
                        <div class="districtName">Visakhapatnam</div>
                    </div>
                    <div class="list">
                        <div class="number">04</div>
                        <div class="districtName">East Godavari</div>
                    </div>
                    <div class="list">
                        <div class="number">05</div>
                        <div class="districtName">West Godavari</div>
                    </div>
                    <div class="list">
                        <div class="number">06</div>
                        <div class="districtName">Guntur</div>
                    </div>
                </div>
                <div class="content d-none">
                    <ul>
                        <li>Srikakulam</li>
                        <li>Vizianagaram</li>
                        <li>Visakhapatnam</li>
                        <li>East Godavari</li>
                        <li>West Godavari</li>
                        <li>Guntur</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

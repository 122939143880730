<!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css /> -->
<section class="services pt-5 pb-5" id="services">
    <div class="container">
        <div class="section-title">
            <h2>Services</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                    hic quas.</p> -->
        </div>
        <div class="row">

            <div class="col-md-4 col-sm-6">

                <div class="serviceBox blue">

                    <div class="service-icon">
                        <span><i class="fa fa-globe"></i></span>
                    </div>

                    <h3 class="title">Micro <span>Industries</span></h3>

                    <p class="description">Lorem ipsum dolor sit amet conse ctetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                    <a routerLink="/microindustries" class="read-more"><i class="far fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>

            <div class="col-md-4 col-sm-6">

                <div class="serviceBox darkgreen">

                    <div class="service-icon">
                        <span><i class="fa fa-rocket"></i></span>
                    </div>

                    <h3 class="title">Small <span>Industries</span></h3>

                    <p class="description">Lorem ipsum dolor sit amet conse ctetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                    <a href="#" class="read-more"><i class="far fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">

                <div class="serviceBox green">

                    <div class="service-icon">
                        <span><i class="fa fa-rocket"></i></span>
                    </div>

                    <h3 class="title">Medium <span>Industries</span></h3>

                    <p class="description">Lorem ipsum dolor sit amet conse ctetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                    <a href="#" class="read-more"><i class="far fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-5 pb-5 services" id="services">
    <div class="container">
        <div class="section-title">
            <h2>Services</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                    hic quas.</p> -->
        </div>
        <div class="row">

            <div class="col-sm-4">

                <div class="serviceBox">
                    <img src="assets/images/services/1.jpg" class="img-fluid" alt="image">
                    <div class="service-content">
                        <span class="service-icon"><i class="fa fa-globe"></i>
                            </span>
                        <h3 class="title">Micro Industries</h3>

                        <p class="description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci id molestiae necessitatibus,possimus quos saepe soluta. Assumenda laborum officia rem
                        </p>
                        <a routerLink="/microindustries" class="read-more">read more</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">

                <div class="serviceBox middle">

                    <div class="service-content">
                        <span class="service-icon"><i class="fa fa-briefcase"></i>
                            </span>
                        <h3 class="title">Small Industries</h3>


                        <p class="description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci id molestiae necessitatibus,possimus quos saepe soluta. Assumenda laborum officia rem
                        </p>
                        <a href="#" class="read-more">read more</a>
                    </div>
                    <img src="assets/images/services/2.jpg" class="img-fluid" alt="image">
                </div>
            </div>

            <div class="col-sm-4">

                <div class="serviceBox">
                    <img src="assets/images/services/3.jpg" class="img-fluid" alt="image">
                    <div class="service-content">
                        <span class="service-icon"><i class="fa fa-globe"></i>
                            </span>
                        <h3 class="title">Medium Industries</h3>


                        <p class="description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci id molestiae necessitatibus,possimus quos saepe soluta. Assumenda laborum officia rem
                        </p>
                        <a href="#" class="read-more">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-processthree',
  templateUrl: './processthree.component.html',
  styleUrls: ['./processthree.component.css']
})
export class ProcessthreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-potentialclusters',
  templateUrl: './potentialclusters.component.html',
  styleUrls: ['./potentialclusters.component.css']
})
export class PotentialclustersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

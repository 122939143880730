import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivitiesComponent } from './activities/activities.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HomeComponent } from './home/home.component';
import { ImportantLinksComponent } from './important-links/important-links.component';
import { MembersComponent } from './members/members.component';
import { MicroindustriesComponent } from './microindustries/microindustries.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { OrganizationstructureComponent } from './organizationstructure/organizationstructure.component';
import { PoliciesComponent } from './policies/policies.component';
import { PotentialclustersComponent } from './potentialclusters/potentialclusters.component';
import { QuicklinksComponent } from './quicklinks/quicklinks.component';
import { TestingComponent } from './testing/testing.component';
import { QueryformComponent } from './queryform/queryform.component';
import { BoardmembersComponent } from './boardmembers/boardmembers.component';
import { ClusterprocessComponent } from './clusterprocess/clusterprocess.component';
import { OngoingclustersComponent } from './ongoingclusters/ongoingclusters.component';
import { NewcontactusComponent } from './newcontactus/newcontactus.component';
import { GosComponent } from './gos/gos.component';
import { TendersComponent } from './tenders/tenders.component';
import { VacanciesComponent } from './vacancies/vacancies.component';
import { MsmecdpComponent } from './msmecdp/msmecdp.component';
import { SfurtiComponent } from './sfurti/sfurti.component';
import { MarketingsupportComponent } from './marketingsupport/marketingsupport.component';
import { LwedistrictsComponent } from './lwedistricts/lwedistricts.component';
import { AspirationaldistrictsComponent } from './aspirationaldistricts/aspirationaldistricts.component';
import { DirectorsComponent } from './directors/directors.component';
import { ExecutivebodyComponent } from './executivebody/executivebody.component';
import { SidbiComponent } from './sidbi/sidbi.component';
import { ApsfcComponent } from './apsfc/apsfc.component';
import { RtiactsComponent } from './rtiacts/rtiacts.component';
import { PmegpComponent } from './pmegp/pmegp.component';
const routes: Routes = [
  
  { path: "", component: HomeComponent },
  {path: 'admin',
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule)
  },
  { path: "home", component: HomeComponent },
  { path: "notifications", component: NotificationsComponent },
  { path: "objectives", component: ObjectivesComponent },
  { path: "microindustries", component: MicroindustriesComponent },
  { path: "quicklinks", component: QuicklinksComponent },
  { path: "policies", component: PoliciesComponent },
  { path: "organizationstructure", component: OrganizationstructureComponent },
  { path: "contactus", component: ContactusComponent },
  { path: "contact", component: NewcontactusComponent },
  { path: "activities", component: ActivitiesComponent },
  { path: "testing", component: TestingComponent },
  { path: "importantlinks", component: ImportantLinksComponent },
  { path: "members", component: MembersComponent },
  { path: "boardmembers", component: BoardmembersComponent },
  { path: "potentialclusters", component: PotentialclustersComponent },
  { path: "ongoingclusters", component: OngoingclustersComponent },
  { path: "queryform", component: QueryformComponent },
  { path: "clusterprocess", component: ClusterprocessComponent },
  { path: "gos", component: GosComponent },
  { path: "tenders", component: TendersComponent },
  { path: "vacancies", component: VacanciesComponent },
  { path: "msmecdp", component: MsmecdpComponent },
  { path: "sfurti", component: SfurtiComponent },
  { path: "marketingsupport", component: MarketingsupportComponent },
  { path: "lwedistricts", component: LwedistrictsComponent },
  { path: "aspirationaldistricts", component: AspirationaldistrictsComponent },
  { path: "directors", component: DirectorsComponent },
  { path: "executivebody", component: ExecutivebodyComponent },
  { path: "sidbi", component: SidbiComponent },
  { path: "apsfc", component: ApsfcComponent },
  { path: "rtiacts", component: RtiactsComponent },
  { path: "pmegp", component: PmegpComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

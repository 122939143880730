<style>
    .cd-breadcrumb, .cd-multi-steps {
    width: 90%;
    max-width: 768px;
    padding: 0.5em 1em;
    margin: 1em auto;
    background-color: #edeff0;
    border-radius: 0.25em;
}
.cd-breadcrumb li, .cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
}
.cd-breadcrumb li > *, .cd-multi-steps li > * {
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
}
.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
    content: '';
    height: 16px;
    width: 16px;
    vertical-align: middle;
}
.cd-breadcrumb li::after, .cd-multi-steps li::after {
    display: inline-block;
    content: '\00bb';
    margin: 0 0.6em;
    color: #959fa5;
}
.cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
    color: #96c03d;
}
.cd-breadcrumb li > *, .cd-multi-steps li > * {
    display: inline-block;
    font-size: 1.4rem;
    /* color: #2c3f4c; */
}
</style>
<section>
	<!-- <h2>2 - Custom Separator</h2> -->

	<nav>
		<ol class="cd-breadcrumb custom-separator">
			<li><a href="#0">Home</a></li>
			<li class="current"><em>Policies</em></li>
		</ol>
	</nav>
</section>


<section class="two pt-5" id="two">
    <style>
        .section-title {
            text-align: center;
            /* padding-bottom: 30px; */
        }
        
        .section-title h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            /* color: #2c4964; */
            /* color: #615cfb; */
            color: #11558a;
        }
        
        .section-title h2::before {
            content: '';
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            /* left: calc(5% - 60px); */
            left: calc(50% - 60px);
        }
        
        .section-title h2::after {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: #1977cc;
            bottom: 0px;
            /* left: calc(5% - 20px); */
            left: calc(50% - 20px);
        }
        
        .section-title p {
            margin-bottom: 0;
        }
    </style>
    <div class="container">
        <div class="section-title">
            <h2>Policies</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                hic quas.</p> -->
        </div>
    </div>
</section>
<section class="bodyContent pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="table-responsive">
                    <table class="table table-bordered" style="width: 100%;text-align: center;">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>GO Number</th>
                                <th>Date</th>
                                <th>Subject</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td style="width: 15%;">MS.No. 93</td>
                                <td style="width: 15%;">30-12-2020</td>
                                <td>Industries & Commerce Department – Andhra Pradesh Food Processing Policy 2020-2025 – Orders – Issued</td>
                                <td><a href="assets/policies/goms93.pdf" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td style="width: 15%;">MS.No. 39</td>
                                <td style="width: 15%;">10-08-2020</td>
                                <td>Industries & Commerce Department – “Andhra Pradesh Industrial Development Policy 2020-23 – Orders – Issued
                                </td>
                                <td><a href="assets/policies/goms39.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td style="width: 15%;">MS.No. 34</td>
                                <td style="width: 15%;">19-05-2021</td>
                                <td>Industries and Commerce Department – Andhra Pradesh Oxygen Manufacturing Policy 2021 – 2022- – Orders – Issued</td>
                                <td><a href="assets/policies/goms34.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td style="width: 15%;">MS.No. 10</td>
                                <td style="width: 15%;">25-02-2021</td>
                                <td>Industrial Promotion – Incentives for the establishment of Industrial Enterprises in Andhra Pradesh under the Industrial Development Policy (IDP) 2020-23 – Operational Guidelines for implementation – Orders - Issued</td>
                                <td><a href="assets/policies/goms10.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td style="width: 15%;">MS.No. 70</td>
                                <td style="width: 15%;">04-09-2019</td>
                                <td>Mines & Minerals – New Sand Mining Policy, 2019 for the State of Andhra Pradesh - Orders – Issued
                                </td>
                                <td><a href="assets/policies/goms70.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td style="width: 15%;">MS.No. 9</td>
                                <td style="width: 15%;">25-02-2021</td>
                                <td>Public Procurement Policy- Make in India (PPP-MII) –Order of Government of Andhra Pradesh 2020 - Orders – Issued
                                </td>
                                <td><a href="assets/policies/goms9.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td style="width: 15%;">MS.No. 8 </td>
                                <td style="width: 15%;">09-01-2019</td>
                                <td>Industries & Commerce Department – Policy to mitigate stress and to revive Stressed MSMEs– Stressed Enterprises Policy 2018-20- Orders- Issued
                                </td>
                                <td><a href="assets/policies/goms8.PDF" target="_blank">View</a></td>
                            </tr>

                        </tbody>
                    </table>
                </div>


                <div class="content d-none">
                    <ul>
                        <li><i class="fas fa-arrow-circle-right"></i>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly
                            believable.
                        </li>
                        <li><i class="fas fa-arrow-circle-right"></i>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</li>
                        <li><i class="fas fa-arrow-circle-right"></i>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Procurement and Marketing Support to MSMEs & it's Guidelines </h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">Procurement and Marketing
                                Support to MSMEs & it's Guidelines</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>


<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table-bordered table bg-white">
                        <tbody>
                            <tr>
                                <th>Scheme</th>
                                <td>Procurement and Marketing Support Scheme (P&MS)</td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td>The Procurement and Marketing support Scheme would cover the following activities.
                                    <ul>
                                        <li>
                                            To encourage Micro and Small Enterprises (MSEs) to develop domestic markets and promotion of new market access initiatives.
                                        </li>
                                        <li>
                                            To facilitate market linkages for effective implementation of Public Procurement Policy for MSEs Order of 2012
                                        </li>
                                        <li>
                                            To educate MSMEs on various facets of business development
                                        </li>
                                        <li>
                                            To create an overall awareness about trade fairs, latest market technique and other such related topics etc
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>Nature of Assistance</th>
                                <td>To know the subsidy pattern, please refer scheme guidelines below.</td>
                            </tr>
                            <tr>
                                <th>Marketing Support</th>
                                <td>For marketing assistance, please do visit <a href="https://www.msmemart.com/" target="_blank">https://www.msmemart.com/</a></td>
                            </tr>
                            <tr>
                                <th>Who can Apply</th>
                                <td>Individual Manufacturing/Service MSEs</td>
                            </tr>
                            <tr>
                                <th>How to Apply</th>
                                <td>Eligible MSEs may submit their application online or system in place</td>
                            </tr>
                            <tr>
                                <th>Whom to Contact</th>
                                <td>
                                    <ul>
                                        <li>
                                            Joint Development Commissioner,
                                            O/o DC, MSME
                                            Nirman Bhawan, New Delhi
                                            Tel.: 011- 23061091
                                            Email: mandeepkaur[at]ias[dot]nic[dot]in
                                        </li>                                    
                                        <li>
                                            GM, DIC or AP MSMEDC at state level
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2"><a href="assets/documents/schemes/Guidelines_PMS.pdf" target="_blank">Download Scheme Guidelines</a></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description </h2>
                </div>
                <div class="section_content">
                    <p>PMS Scheme has been revamped to enhance the marketability
                        of products and services in the MSME sector. The
                        objective is to promote new market access initiatives,
                        create awareness and educate the MSMEs about various
                        marketing relevant topics and development of
                        marketability.</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Nature of Assistance: </h2>
                </div>
                <div class="section_content">
                    <p>
                        Assistance available for following scheme components (A)
                        Participation of Individual MSEs in domestic trade
                        fairs/exhibition across the country (B)
                        Organizing/Participation in trade fairs/exhibitions
                        (Regional/
                        National/International) by the Ministry/ Office of DC
                        (MSME)/ Government organizations (C) Capacity building
                        of MSMEs in modern packaging technique (D) Development
                        of Marketing Haats (E) International/National
                        Workshops/Seminars(F)
                        Vendor Development Programmes: State Level Vendor
                        Development Programmes (SLVDP) and National Level Vender
                        Development Programme (NLVDP) (G) Awareness Programmes.
                    </p>
                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3 align-items-center">

                    <h2 class="btn-md btnWidth applyButton">
                        Who Can apply
                    </h2>
                    <div class="section_content">
                        <p class="ml-2"> Individual Manufacturing/Service MSE.
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3 align-items-center">

                    <h2 class="btn-md btnWidth howButton">
                        How To apply
                    </h2>
                    <div class="section_content">
                        <p class="ml-2"> Eligible MSEs may submit their application online or system in place.
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="d-flex mt-3 align-items-center">

                    <h2 class="btn-md btnWidth whomButton">
                        Whom To Contact
                    </h2>
                    <div class="section_content">
                        <p class="ml-2">
                            JDC/ Deputy Director, O/o DC(MSME).
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>
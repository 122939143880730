
<a id="contactBtn" href="#/contact"></a>

<footer>
	<div class="content">
		<div class="link-boxes">
			<ul class="box">
				<li class="link_name">Navigation</li>
				<li><a routerLink="/home">Home</a></li>
				<li><a routerLink="/policies">Policies</a></li>
				<li><a routerLink="/rtiacts">RTI</a></li>
				<!-- <li><a href="#">Get Started</a></li> -->
			
			</ul>
			<ul class="box">
				<li class="link_name">Contact Us</li>
				<p class="m-0">
                    Phone: 0866-2411459
                </p>
                <p class="m-0">Email:   apmsmedc[at]gmail[dot]com,</p>
				<p><span style="visibility: hidden;">Email:</span> ceo-apmsmedc[at]ap[dot]gov[dot]in</p>
			</ul>
	
			<ul class="box">
				<li class="link_name">Address</li>
				<p>
                    CEO, AP MSME DC, 1st Floor Government Regional Printing Press Building, Mutyalampadu, Vijayawada-520011
				</p>
				<!-- <li><a href="#">+91 8879887262</a></li>
				<li><a href="#">+91 8879887262</a></li>
				<li><a href="#">contact@sitesoch.com</a></li> -->
				
			
			</ul>
			
	
	
	
		</div>
		<hr style="background: #fff;margin: 4px 0;">
	    <div class="bottom-details">
      <div class="bottom_text">
        <span class="copyright_text">
            Copyright © 2022 All Rights Reserved to MSME Development Corportation
		</span>
        <span class="policy_terms">
          <!-- <a href="#">Privacy policy</a> -->
		  <p>Site Design & Developed By <a href="https://e-pragati.in"><img src="assets/images/epalogo.png" alt="image" class="img-fluid" style="width: 50px;"></a>
		  </p>
        </span>
      </div>
	  </div>
    </div>
</footer>







<section class="footer d-none">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="footerContent">
                    <p>Copyright © 2022 All Rights Reserved to Information Technology, Electronics & Communication</p>
                </div>
            </div>
            <div class="col-md-5 text-right">
                <div class="footerright">
                    <p>Site Design & Developed By <img src="assets/images/epalogo.png" alt="image" class="img-fluid" style="width: 50px;">
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

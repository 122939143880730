import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newcontactus',
  templateUrl: './newcontactus.component.html',
  styleUrls: ['./newcontactus.component.css']
})
export class NewcontactusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidbi',
  templateUrl: './sidbi.component.html',
  styleUrls: ['./sidbi.component.css']
})
export class SidbiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

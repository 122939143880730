<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Government Orders</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">GO's & Policies</a></li>
                        <li><a style="color: #fff;">Government Orders</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>


<section class="section_bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="section_heading">
                    <h2>Latest GO's</h2>
                </div>
            </div> -->
            <div class="col-md-12">
                <div class="section_content_bg p-0">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th class="text-nowrap" style="width: 130px;">GO No.</th>
                                <th class="text-nowrap" style="width: 130px;">Date</th>
                                <th>Subject</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>RT.No. 182 </td>
                                <td class="text-nowrap">05-11-2021 </td>
                                <td>Ind. & Com. Department - Andhra Pradesh Micro, Small and Medium Enterprises Development Corporation - Appointment of Board of Directors for Andhra Pradesh Micro, Small and Medium Enterprises Development Corporation - Orders Issued</td>
                                <td class="view"><a href="assets/gos/GORT NO 182.pdf" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>MS.No. 108 </td>
                                <td class="text-nowrap">11-12-2019 </td>
                                <td>Industries, Infrastructure, Investment & Commerce
                                    Department – Structure of A.P. Micro, Small and
                                    Medium Enterprises Development Corporation –Orders -
                                    Issued </td>
                                <td class="view"><a href="assets/gos/structureofapmsmego.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>RT.No. 315 </td>
                                <td>21-11-2019 </td>
                                <td>Industries, Infrastructure, Investment and Commerce
                                    Department -Appointment of Sri R. Pavana Murthy,
                                    ITS., as Chief Executive Officer, A.P. MSME
                                    Development Corporation - Orders - Issued </td>
                                <td class="view"><a href="assets/gos/21112019INDS_RT315.PDF" target="_blank">View</a></td>
                            </tr>
                           
                            <tr>
                                <td>4</td>
                                <td>RT.No. 2194 </td>
                                <td>07-10-2019 </td>
                                <td>CCS Officer – Transfer and Postings – Transfer and
                                    Posting of Sri R. Pavana Murthy, ITS - Orders –
                                    Notified </td>
                                <td class="view"><a href="assets/gos/2019GAD_RT2194.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>MS.No. 130 </td>
                                <td>16-11-2018 </td>
                                <td>Industries & Commerce Department – A.P. Micro Small
                                    and Medium Enterprises Development Corporation –
                                    Creation of post of Chief Executive Officer(CEO) to
                                    A.P. MSME Development Corporation- Amendment Orders-
                                    Issued </td>
                                <td class="view"><a href="assets/gos/2018INDS_MS130.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>MS.No. 109 </td>
                                <td>19-09-2018 </td>
                                <td>Industries and Commerce Department – A.P. Micro
                                    Small and Medium Enterprise Development Corporation
                                    –Creation of certain temporary posts in various
                                    categories in A.P. Micro Small and Medium Enterprise
                                    Development Corporation Orders Issued </td>
                                <td class="view"><a href="assets/gos/19092018INDS_MS109.PDF" target="_blank">View</a></td>
                            </tr>
                           
                            <tr>
                                <td>7</td>
                                <td>MS.No. 72 </td>
                                <td>07-06-2018 </td>
                                <td>Incorporation of the A.P. MSME Development
                                    Corporation -Orders –Issued </td>
                                <td class="view"><a href="assets/gos/07062018INDS_MS72.PDF" target="_blank">View</a></td>
                            </tr>
                            
                            <tr>
                                <td>8</td>
                                <td>MS.No. 71 </td>
                                <td>01-06-2018 </td>
                                <td>Industries and Commerce Department – A.P. Micro
                                    Small and Medium Enterprise Development Corporation
                                    –Creation of certain temporary posts in various
                                    categories in A.P. Micro Small and Medium Enterprise
                                    Development Corporation Orders Issued </td>
                                <td class="view"><a href="assets/gos/01062018FIN_MS71.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>MS.No. 70 </td>
                                <td>31-05-2018 </td>
                                <td>Industries & Commerce Department – Policy for Establishment of Micro, Small and
                                    Medium Enterprises (MSME) Parks M- Parks in Each Assembly Constituency – M-Parks
                                    Policy 2018-23 - Orders – Issued.</td>
                                <td class="view"><a href="assets/gos/31052018INDS_MS70.PDF" target="_blank">View</a></td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>MS.No. 91 </td>
                                <td>22-06-2017 </td>
                                <td>Ind. & Com. Dept., - Formation of A.P.MSME Development Corporation –
                                    Orders – Issued.</td>
                                <td class="view"><a href="assets/gos/22062017INDS_MS91.PDF" target="_blank">View</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                                    </div>
                                </div>
                            </div>
                        </section>
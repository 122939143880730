import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class UserService {
  baseUrl: string = "https://winter-2017-phortonssf.c9users.io:8080/api/appUsers";
  waynesWorld:string = "https://wayne-spring-2018-phortonssf.c9users.io:8080/api/appUsers"
  returnUrl: string = "home";
  loginPage: string = "";
  userData: any;
  apiurl: any;
  constructor( 
    private http: HttpClient,
    private router: Router,
    
  ) 
  {
    // this.apiurl = environment.apiURL
   }

   httpOptions1 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  toLogin(){
    this.router.navigate([this.loginPage])  
  }

  registerUser(userData){
    this.http.post(`${this.baseUrl}`, userData)
      .subscribe( resData => {
        this.toHomePage(resData)
    })
  }


  loginUser(userData){
    this.http.post(`${this.waynesWorld}/login`, userData)
     
      .subscribe( resData => {
        console.log("$ data", resData)
        this.toHomePage(resData)
      })
  }
   
  toHomePage(resData){
    //Save data from our succesfull login in sessionStorage
    window.sessionStorage.setItem( "token", resData.token)
    window.sessionStorage.setItem( "userId", resData.userId)
    this.router.navigate([this.returnUrl])   
  }

  queryFormSubmit(data){
    return this.http.post(this.apiurl + 'SaveQueryForm', data, this.httpOptions1).pipe(catchError(this.handleError));
  }
}
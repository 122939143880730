
<!-- home alert starts -->



<div class="modal fade alertModal" id="myModal" *ngIf="closeBtn" style="display: block;opacity: 1; background: rgba(0,0,0,0.8);" id="modal-subscribe" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="align-items: flex-end;">
        <div class="modal-content">
            <div class="modal-header border-0 p-0" style="background: #004982;">
                <button type="button" class="close" data-dismiss="modal" 
                (click)="closeBtn = false" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="myModalLabel"></h4>
            </div>
            <style>
                .modal::before {
                    vertical-align: middle;
                    content: " ";
                    height: 100%;
                }
                .modalContent h3 {
                    font-size: 16px;
                }
                .knowmore h3 {
                    font-size: 20px;
                    color: #fff;
                }
                
                    .modal-header1 {
background: #11558a;
color: white;
padding: 20px 50px;
text-align: center;
box-shadow: 0 1px 1px #666;
position: absolute;
top: 0;
left: -20px;
width: calc(100% + 80px);
}
.modal-header1:before,
.modal-header1:after {
content: "";

display: block;
position: absolute;
border: 10px solid darkred;
}
.modal-header1:before {
border-left-color: transparent;
border-bottom-color: transparent;
left: 0;
bottom: -20px;
}
.modal-header1:after {
border-right-color: transparent;
border-bottom-color: transparent;
right: 0;
bottom: -20px;
}


.modal-header {
background: #11558a;
}
.modal-content {
border:none;
}
.modal-dialog .modal-content .close span {
margin: 0;
display: block;
}
.modal-dialog .modal-content .close {
color: #11558a;
background-color: #1beba1;
font-size: 33px;
line-height: 22px;
height: 50px;
width: 50px;
opacity: 1;
border-radius: 50%;
box-shadow: 0 0 5px #555;
position: absolute;
left: auto;
right: -7px;
top: -7px;
z-index: 1;
transition: all 0.3s;
}
                
            </style>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <a href="https://harghartiranga.com/" target="_blank">
                                <img src="assets/images/azadi-ka-mahostav.jpeg" alt="image" class="img-fluid">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- home alert ends -->

<section data-aos="fade-down" data-aos-duration="1000" class="heroSection">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="homebanner text-white">
                   <div class="homecontent pb-4">
                        <h3 class="animated animate__slideInLeft animation1">
                            "Coming together is Beginning..
                            <!-- Big Things often have small Beginnings! -->
                        </h3>
                        <h3 class="animated animate__slideInLeft animation2">
                        Working together is Progress..
                        </h3>
                        <h3 class="animated animate__slideInLeft animation3">
                            Winning together is Success!"
                            </h3>
                            <h3 class="animated animate__slideInLeft animation4">
                                That is the Mantra of a Cluster!!
                                </h3>
                    </div>
                   
                   <div class="profiles d-flex align-items-center pt-2">
                    <img src="assets/images/minister.png" alt="image" class="img-fluid">
                    <div class="profileContent px-2">
                        <h4>Sri Gudivada Amarnath</h4>
                        <p>Hon'ble Minsiter of Industries, Infrastructure, <br> Investment & Commerce and Information Technology</p>
                    </div>
                </div>
                    <div class="profiles d-flex align-items-center py-3">
                        <img src="assets/images/CS.png" alt="image" class="img-fluid">
                        <div class="profileContent px-2">
                            <h4>Sri R.Karikal Valaven, IAS</h4>
                            <p>Special Chief Secretary to Govt <br>
                                Industries and Commerce</p>
                        </div>
                    </div>
                    <div class="profiles d-none align-items-center">
                        <img src="assets/images/ravindhranath.png" alt="image" class="img-fluid">
                        <div class="profileContent px-2">
                            <h4>Sri Vanka Ravindranath</h4>
                            <p>Chairman</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" data-aos-duration="1500">
                <img src="assets/images/Banner-for-msme.png" alt="image" class="img-fluid">
            </div>
        </div>
    </div>

</section>

<!-- <app-testing></app-testing> -->



<!-- About section starts -->

<section class="sectionpadding">
    <div class="container">
        <div class="section-title">
            <h2>About Us</h2>
        </div>
        <div class="row">
            <div class="col-md-5" data-aos="fade-right" data-aos-duration="1500">
                <div class="content aboutcontent">
                    <ul>
                        <li>
                            <i class="fa fa-arrow-circle-right"></i>
                            APMSMEDC was incorporated under Industries and Commerce Department of the Government of Andhra Pradesh on 16th July, 2018 created by GO MS No.91 Dated 22 June 2017 and Structure is ordered by GO MS no. 108 dated 11-12-2019, Industries, Infrastructure, Investment & Commerce Department.
                        </li>
                        <li>
                            <i class="fa fa-arrow-circle-right"></i>
                            The Government of AP has notified AP MSME Development Corporation as an Implementing Agency for Cluster Development Programme in the state. 
                        </li>

                        <!-- <li>
                            <i class="fa fa-arrow-circle-right"></i>
                            MSME has been identified as a significant driver for achieving substantial growth and AP government has issued orders for establishment of AP MSME Development Corporation to bring dedicated focus to MSME sector.
                        </li> -->
                        <li>
                            <i class="fa fa-arrow-circle-right"></i>
                            The envisaged corporation will act as one-stop agency to enhance MSME operations and this will be supported by state government.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-7">
                <ng-image-slider #nav [images]="imageObject" [infinite]="true" [autoSlide]="10" [imageSize]="{width: '100%', height: 350}" slideImage="1"></ng-image-slider>
            </div>
        </div>
    </div>
</section>

<!-- About section ends -->



<!-- Vision & Mission section starts -->

<section class="sectionpadding pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="imagecontent d-flex align-items-center">
                    <div class="divimage">
                        <img src="assets/images/vision.png" alt="image" class="p-2">
                    </div>
                    <div class="divcontent px-3">
                        <h5>Vision</h5>
                        <p class="mb-0">To  create/upgrade infrastructure facilities  in  the  new/existing  industrial areas/  clusters  of  MSEs, and to  set  up  common  facility  centres  (for  testing,  training  centre,  raw material  depot,  effluent  treatment,  complementing  production  processes, etc) for Micro and small entreprises in the State of Andhra Pradesh.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <hr>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-12">
                <div class="imagecontent d-flex align-items-center">
                    <div class="divimage">
                        <img src="assets/images/mission.png" alt="image" class="p-2">
                    </div>
                    <div class="divcontent px-3">
                        <h5>Mission</h5>
                        <p>To  support  the  sustainability  and  growth  of  MSEs  by  addressing common  issues  such  as  Technology upgradation,  skilling, market  access,  access  to  finance
                             through various schemes of GOI and Govt of Andhra Pradesh.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Vision & Mission section ends -->

<section class="section_bg">
    <div class="container">
        <div class="section-title">
            <h2>CDP support strategy</h2>
        </div>
        <div class="row">
            <div class="col-md-4" data-aos="fade-right" data-aos-duration="1500">
                <div class="box">
                    <div class="boxImage">
                        <img src="assets/images/strategy/faciloitation.png" alt="image" class="img-fluid">                        
                        <img src="assets/images/strategy/faciloitation-white.png" alt="image" class="img-fluid">
                        <!-- <img src="assets/images/icon2.png" alt="image" class="img-fluid"> -->
                    </div>
                    <div class="boxHeading">
                        <h3>FACILITATION</h3>
                    </div>
                    <div class="boxUl content pb-2">
                        <ul>
                            <li>
                                <i class="fa fa-check"></i>
                                Establishing help desk for MSME Entrepreneurs at Districts.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Assign Relationship Managers.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>                                
                                Funding options for MSMEs.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Project Appraisal Support.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Entrepreneur Training.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1500">
                <div class="box">
                    <div class="boxImage">
                        <img src="assets/images/strategy/infrastructure.png" alt="image" class="img-fluid">                        
                        <img src="assets/images/strategy/infrastructure-white.png" alt="image" class="img-fluid">
                    </div>
                    <div class="boxHeading">
                        <h3>INFRASTRUCTURE</h3>
                    </div>
                    <div class="boxUl content">
                        <ul>
                            <li>
                                <i class="fa fa-check"></i>
                                Identify potential sectors.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Industrial cluster development.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Common facility centres like Design Center, common ETP, testing labs.. etc.
                            </li>
                            <!-- <li>
                                <i class="fa fa-check"></i>                                
                                Quality testing labs.
                            </li> -->

                            <li>
                                <i class="fa fa-check"></i>
                                Project Facilitation.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Handholding support for implementation.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-left" data-aos-duration="1500">
                <div class="box">
                    <div class="boxImage">
                        <img src="assets/images/strategy/trade.png" alt="image" class="img-fluid">                        
                        <img src="assets/images/strategy/trade-white.png" alt="image" class="img-fluid">
                    </div>
                    <div class="boxHeading">
                        <h3>MARKETING SUPPORT</h3>
                    </div>
                    <div class="boxUl content pb-4">
                        <ul>
                            <li>
                                <i class="fa fa-check"></i>
                                Identification of specific products in all districts.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                Arranging marketing linkages with various Agencies.
                            </li>
                            <li>
                                <i class="fa fa-check"></i>                                
                                Working with Global Trade organizations to improve trade.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- test starts -->

<!-- <app-testing></app-testing> -->
<app-swipertest></app-swipertest>
<!-- test ends -->

<!-- owl starts -->
<section class="sectionpadding testimonialsSection">
    <div class="container">
        <section class="sectionpadding testimonial">
            <div class="container">
                <div class="section-title">
                    <h2>Success Stories across India</h2>
                    <p class="pb-4">Successfull implementation of Common Facility Centres (CFCs)</p>
                </div>
        <div class="row">
            <div class="col-md-12">
                <owl-carousel-o [options]="customOptions">

                    <ng-template carouselSlide>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <div class="slide">
                                    <div class="testimonialBox">
                                        <!-- <iframe width="1200" height="409" src="https://www.youtube.com/embed/zRvL4ngSqJs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                        <!-- <iframe src="assets/videos/v1.mp4" frameborder="0"></iframe> -->
                                        <video width="500" height="350" controls>
                                            <source src="assets/videos/v1.mp4" type="video/mp4">
                                          </video>
                                        <!-- d-flex -->
                                    </div>
                                 </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>


                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <div class="slide">
                                    <div class="testimonialBox">
                                        <!-- <iframe width="1200" height="409" src="https://www.youtube.com/embed/zRvL4ngSqJs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                        <!-- <iframe src="assets/videos/v1.mp4" frameborder="0"></iframe> -->
                                        <video width="500" height="350" controls>
                                            <source src="assets/videos/v2.mp4" type="video/mp4">
                                          </video>
                                        <!-- d-flex -->
                                    </div>
                                 </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>


                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <div class="slide">
                                    <div class="testimonialBox">
                                        <video width="500" height="350" controls>
                                            <source src="assets/videos/v3.mp4" type="video/mp4">
                                          </video>
                                        <!-- d-flex -->
                                    
                                    </div>
                                 </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>


                    </ng-template>
                    
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- owl ends -->



<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Contact Us</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Contact Us</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>


<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930170.9957223376!2d80.77681604820552!3d15.85356901618443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35e529153e27e3%3A0xfc153e499e20c240!2sAP%20MSME%20Development%20Corporation%2C!5e0!3m2!1sen!2sin!4v1649763258094!5m2!1sen!2sin" width="550" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="col-md-6">
                <div class="d-flex align-items-center">
                    <i class="fa fa-map-marker"></i>
                    <div class="map_data">
                        <h4>Address</h4>
                        <p>CEO, AP MSME Development Corporation, 1st Floor, Government Printing Press Building, Mutyalampadu, Vijayawada-520011</p>
                    </div>            
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa fa-envelope pt-2"></i>
                    <div class="map_data">
                        <h4>e-Mail</h4>
                        <p class="m-0">apmsme-dc[at]ap[dot]gov[dot]in,</p>
                        <!-- <h4 style="visibility: hidden;">e-Mail</h4> -->
                        <p>ceo-apmsmedc[at]ap[dot]gov[dot]in</p>
                    </div>            
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa fa-phone pt-2"></i>
                    <div class="map_data">
                        <h4>Phone Numbers</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Office</th>
                                    <th>:</th>
                                    <td>0866-2411459</td>
                                </tr>
                                <tr>
                                    <th>Chairman</th>
                                    <th>:</th>
                                    <td>0866-2437676</td>
                                </tr>
                                <tr>
                                    <th>CEO</th>
                                    <th>:</th>
                                    <td>0866-2411460</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <p>CEO, AP MSME Development Corporation, 1st Floor, Government Printing Press, Mutyalampadu, Vijayawada-520011</p> -->
                    </div>            
                </div>
                <div class="dicbutton btn mt-2">
                    <a href="https://www.apindustries.gov.in/APIndus/UserInterface/RawMaterials/DIC.aspx?DistID=06" target="_blank">
                        For Information at District Level, please contact GM, DIC of  your district
                    </a>
                </div>
               <div class="content py-3 d-none">
                   <p>
                    For Preparation of DSR and DPR, you may contact : 
                   </p>
                   <ul>
                       <li>
                        APITCO, 9989334055 (Gopal Rao, Vijayawada)
                       </li>
                       <li>
                        ITCOT, 9840928298 (Perumal Ramasamy, Chennai)
                       </li>
                   </ul>
               </div>
            </div>
        </div>
    </div>
</section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Organisation Structure</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">About</a></li>
                        <li><a style="color: #fff;">Organisation Structure</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>



<section class="two section_bg" id="two">
    <style>
        .section-title {
            text-align: center;
            /* padding-bottom: 30px; */
        }
        
        .section-title h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            /* color: #2c4964; */
            /* color: #615cfb; */
            color: #11558a;
        }
        
        .section-title h2::before {
            content: '';
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            /* left: calc(5% - 60px); */
            left: calc(50% - 60px);
        }
        
        .section-title h2::after {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: #1977cc;
            bottom: 0px;
            /* left: calc(5% - 20px); */
            left: calc(50% - 20px);
        }
        
        .section-title p {
            margin-bottom: 0;
        }
    </style>
    <div class="container">
       
        <div class="row">
            <div class="col-md-12">
                <div class="organiationstructureimage">
                    <img src="assets/images/aboutus/organization-structure.png" alt="image" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
        <br><br><br>
        <div class="d-flex justify-content-between align-items-center">
            <h2>e-Pragati</h2>
            <ol class="breadcumbsRight">
                <li><a routerLink="/home">Home</a></li>
                <li>e-Pragati</li>
            </ol>
        </div>

    </div>
</section> -->
<!-- <br><br><br> -->
<style>
    .breadcrumb {
        padding: 5px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    
    .breadcrumb>li:first-child {
        padding-left: 10px;
    }
    
    .breadcrumb>li {
        display: inline-block;
        background: #f5f5f5;
        color: #9d9d9d;
        border-radius: 25px;
        padding: 1px 20px 1px 20px;
        /* padding: 1px 15px 1px 20px; */
        margin-left: -17px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
    }
    
    .breadcrumb>.active {
        color: #fff;
        background: #1acc8d;
        /* background: #7ab800; */
    }
    
    .breadcrumb>li+li:after,
    .breadcrumb>li+li:before {
        content: "";
        padding: 0 5px;
        color: #ccc;
        background: #f5f5f5;
        border-radius: 25px;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 0;
    }
    
    .breadcrumb>li+li:after {
        z-index: 1;
        height: 22px;
        top: -1px;
        left: -7px;
        background: #fff;
    }
    
    a {
        text-decoration: none;
    }
</style>
<section class="breadcrumb">
    <div class="container breadcrumb_container">
        <div class="row">
            <div class="hidden-xs col-sm-12 col-md-12">
                <ol class="breadcrumb">
                    <li><a routerLink="/home">Home</a></li>
                    <!-- <li>Careers</li> -->
                    <li class="active">Notifications</li>
                </ol>
            </div>
            <div class="col-sm-6 col-md-6 d-none">
                <div class="sharethis-inline-share-buttons st-right  st-inline-share-buttons st-animated" id="st-1">
                    <div class="st-total ">
                        <span class="st-label">69</span>
                        <span class="st-shares">
        Shares
        </span>
                    </div>
                    <div class="st-btn st-first" data-network="linkedin" style="display: inline-block;">
                        <img alt="linkedin sharing button" src="https://platform-cdn.sharethis.com/img/linkedin.svg">

                    </div>
                    <div class="st-btn" data-network="twitter" style="display: inline-block;">
                        <img alt="twitter sharing button" src="https://platform-cdn.sharethis.com/img/twitter.svg">

                    </div>
                    <div class="st-btn" data-network="reddit" style="display: inline-block;">
                        <img alt="reddit sharing button" src="https://platform-cdn.sharethis.com/img/reddit.svg">

                    </div>
                    <div class="st-btn" data-network="telegram" style="display: inline-block;">
                        <img alt="telegram sharing button" src="https://platform-cdn.sharethis.com/img/telegram.svg">

                    </div>
                    <div class="st-btn st-last" data-network="email" style="display: inline-block;">
                        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="two pt-5" id="two">
    <style>
        .section-title {
            text-align: center;
            /* padding-bottom: 30px; */
        }
        
        .section-title h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            /* color: #2c4964; */
            /* color: #615cfb; */
            color: #11558a;
        }
        
        .section-title h2::before {
            content: '';
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            /* left: calc(5% - 60px); */
            left: calc(50% - 60px);
        }
        
        .section-title h2::after {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: #1977cc;
            bottom: 0px;
            /* left: calc(5% - 20px); */
            left: calc(50% - 20px);
        }
        
        .section-title p {
            margin-bottom: 0;
        }
    </style>
    <div class="container">
        <div class="section-title">
            <h2>Notifications</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                hic quas.</p> -->
        </div>
    </div>
</section>
<!-- Intro Courses -->
<section class="pb-5 intro-section gray-bg pt-94 pb-100 md-pt-64 md-pb-70 py-3" style="background: #f3f8f9;position: relative;">
    <div class="container">
        <div class="row pb-2 d-none">
            <div class="col-md-12">
                <div class="societyImage">
                    <img src="assets/images/societies/epragati.png" alt="image" class="img-fluid" style="width: 20%;">
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <!-- Content Column -->
            <div class="col-lg-12 md-mb-50">
                <!-- Intro Info Tabs-->
                <div class="intro-info-tabs">
                    <ul class="nav nav-tabs intro-tabs tabs-box" id="myTab" role="tablist">
                        <li class="nav-item tab-btns">
                            <a class="nav-link tab-btn active" id="prod-overview-tab" data-toggle="tab" href="#prod-overview" role="tab" aria-controls="prod-overview" aria-selected="true">Tenders</a>
                        </li>
                        <li class="nav-item tab-btns">
                            <a class="nav-link tab-btn" id="prod-curriculum-tab" data-toggle="tab" href="#prod-curriculum" role="tab" aria-controls="prod-curriculum" aria-selected="false">Vacancies</a>
                        </li>
                        <!-- <li class="nav-item tab-btns">
                            <a class="nav-link tab-btn" id="prod-instructor-tab" data-toggle="tab" href="#prod-instructor" role="tab" aria-controls="prod-instructor" aria-selected="false">Policies</a>
                        </li> -->
                        <li class="nav-item tab-btns">
                            <a class="nav-link tab-btn" id="prod-faq-tab" data-toggle="tab" href="#prod-faq" role="tab" aria-controls="prod-faq" aria-selected="false">GO's</a>
                        </li>
                        <!-- <li class="nav-item tab-btns">
                            <a class="nav-link tab-btn" id="prod-reviews-tab" data-toggle="tab" href="#prod-reviews" role="tab" aria-controls="prod-reviews" aria-selected="false">Objectives</a>
                        </li> -->
                    </ul>
                    <div class="tab-content tabs-content" id="myTabContent">
                        <div class="tab-pane tab fade show active" id="prod-overview" role="tabpanel" aria-labelledby="prod-overview-tab">
                            <div class="content white-bg pt-30">
                                <!-- Cource Overview -->
                                <div class="course-overview">
                                    <!-- section-title -->
                                    <div class="inner-box">
                                        <h4>Tenders</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                        <p class="d-none">e-Pragati is a society of Government of Andhra Pradesh which supports the areas of e-Governance Solutions & Digital transformation. It aims to be a catalyst for enhancing the effectiveness of implementing various
                                            developmental projects and welfare schemes undertaken by the Government and ensure the right balance between information security and privacy of personal data.</p>

                                        <div class="content d-none">
                                            <ul>

                                                <li><i class="fas fa-arrow-circle-right"></i>e-Pragati is a society of Government of Andhra Pradesh which supports the areas of e-Governance digital transformation.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>It aims to be a catalyst for enhancing the effectiveness of implementing various developmental projects and welfare schemes undertaken by the Government and ensure
                                                    the right balance between information security and privacy of personal data.</li>

                                            </ul>
                                        </div>

                                        <ul class="student-list d-none">
                                            <li>23,564 Total Students</li>
                                            <li><span class="theme_color">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span> (1254 Rating)
                                            </li>
                                            <li>256 Reviews</li>
                                        </ul>
                                        <!-- <h3>What you’ll learn?</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                        <!-- <h3>Requirements</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>





                        </div>
                        <div class="tab-pane fade" id="prod-curriculum" role="tabpanel" aria-labelledby="prod-curriculum-tab">

                            <div class="content white-bg pt-30">
                                <!-- Cource Overview -->
                                <div class="course-overview">
                                    <!-- section-title -->
                                    <div class="inner-box">
                                        <h4>Vacancies</h4>
                                        <div class="content">
                                            <ul>
                                                <li><i class="fas fa-arrow-circle-right"></i>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which
                                                    don't look even slightly believable.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</li>

                                            </ul>
                                        </div>
                                        <div class="content d-none">
                                            <ul>
                                                <li><i class="fas fa-arrow-circle-right"></i>Help enable Digital Transformation and e-Governance of departmental processes to make delivery of citizen services simple, convenient and transparent</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>Maintain HCM Core Dashboard to publish metrics for Government benefit schemes as directed by CMO</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>Promulgate the published ITE&C standards and best practices across GoAP departments</li>

                                            </ul>
                                        </div>
                                        <!-- <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus. Sed consequat
                                            justo non mauris pretium at tempor justo sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque penatibus.</p>
                                        <p>Eleifend euismod pellentesque vel Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus. Sed consequat
                                            justo non mauris pretium at tempor justo sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque penatibus.</p> -->
                                        <ul class="student-list d-none">
                                            <li>23,564 Total Students</li>
                                            <li><span class="theme_color">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span> (1254 Rating)
                                            </li>
                                            <li>256 Reviews</li>
                                        </ul>
                                        <!-- <h3>What you’ll learn?</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                        <!-- <h3>Requirements</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="content d-none">
                                <div id="accordion" class="accordion-box">
                                    <div class="card accordion block">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link acc-btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Vision</button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body acc-content current">
                                                <div class="content">
                                                    <p>Eleifend euismod pellentesque vel Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus.
                                                        Sed consequat justo non mauris pretium at tempor justo sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque penatibus.</p>
                                                </div>
                                                <div class="content d-none">
                                                    <div class="clearfix">
                                                        <div class="pull-left">
                                                            <a class="popup-videos play-icon" href="javascript:void(0)"><i class="fa fa-play"></i>What is Vission</a>
                                                        </div>
                                                        <div class="pull-right">
                                                            <!-- <div class="minutes">35 Minutes</div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content d-none">
                                                    <div class="clearfix">
                                                        <div class="pull-left">
                                                            <a href="https://www.youtube.com/watch?v=kxPCFljwJws" class="popup-videos play-icon"><span class="fa fa-play"><i class="ripple"></i></span>What is UI/ UX Design?</a>
                                                        </div>
                                                        <div class="pull-right">
                                                            <div class="minutes">35 Minutes</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content d-none">
                                                    <div class="clearfix">
                                                        <div class="pull-left">
                                                            <a href="https://www.youtube.com/watch?v=kxPCFljwJws" class="popup-videos play-icon"><span class="fa fa-play"></span>What is UI/ UX Design?</a>
                                                        </div>
                                                        <div class="pull-right">
                                                            <div class="minutes">35 Minutes</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="prod-instructor" role="tabpanel" aria-labelledby="prod-instructor-tab">

                            <div class="content white-bg pt-30">
                                <!-- Cource Overview -->
                                <div class="course-overview">
                                    <!-- section-title -->
                                    <div class="inner-box">
                                        <h4>Policies</h4>
                                        <div class="content">
                                            <ul>
                                                <li><i class="fas fa-arrow-circle-right"></i>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which
                                                    don't look even slightly believable.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</li>

                                            </ul>
                                        </div>

                                        <ul class="student-list d-none">
                                            <li>23,564 Total Students</li>
                                            <li><span class="theme_color">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span> (1254 Rating)
                                            </li>
                                            <li>256 Reviews</li>
                                        </ul>
                                        <!-- <h3>What you’ll learn?</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                        <!-- <h3>Requirements</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="prod-faq" role="tabpanel" aria-labelledby="prod-faq-tab">
                            <div class="content white-bg pt-30">
                                <!-- Cource Overview -->
                                <div class="course-overview">
                                    <!-- section-title -->
                                    <div class="inner-box">
                                        <h4>GO's</h4>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered" style="width: 100%;text-align: center;">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>GO Number</th>
                                                                    <th>Date</th>
                                                                    <th>Subject</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td style="width: 15%;">MS.No. 108</td>
                                                                    <td style="width: 15%;">11-12-2019</td>
                                                                    <td>Industries, Infrastructure, Investment & Commerce Department – Structure of A.P. Micro, Small and Medium Enterprises Development Corporation –Orders - Issued</td>
                                                                    <td><a href="assets/gos/structureofapmsmego.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td style="width: 15%;">MS.No. 130</td>
                                                                    <td style="width: 15%;">16-11-2018</td>
                                                                    <td>Industries & Commerce Department – A.P. Micro Small and Medium Enterprises Development Corporation – Creation of post of Chief Executive Officer(CEO) to A.P. MSME Development Corporation-
                                                                        Amendment Orders- Issued</td>
                                                                    <td><a href="assets/gos/2018INDS_MS130.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3</td>
                                                                    <td style="width: 15%;">RT.No. 2194</td>
                                                                    <td style="width: 15%;">07-10-2019</td>
                                                                    <td>CCS Officer – Transfer and Postings – Transfer and Posting of Sri R. Pavana Murthy, ITS - Orders – Notified</td>
                                                                    <td><a href="assets/gos/2018INDS_MS130.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>4</td>
                                                                    <td style="width: 15%;">MS.No. 71</td>
                                                                    <td style="width: 15%;">01-06-2018</td>
                                                                    <td>Industries and Commerce Department – A.P. Micro Small and Medium Enterprise Development Corporation –Creation of certain temporary posts in various categories in A.P. Micro Small and
                                                                        Medium Enterprise Development Corporation –– Orders – Issued</td>
                                                                    <td><a href="assets/gos/01062018FIN_MS71.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>5</td>
                                                                    <td style="width: 15%;">MS.No. 72</td>
                                                                    <td style="width: 15%;">07-06-2018</td>
                                                                    <td>Incorporation of the A.P. MSME Development Corporation -Orders –Issued
                                                                    </td>
                                                                    <td><a href="assets/gos/2019GAD_RT2194.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>6</td>
                                                                    <td style="width: 15%;">MS.No. 109</td>
                                                                    <td style="width: 15%;">19-09-2018</td>
                                                                    <td>Industries & Commerce Department – A.P. Micro Small and Medium Enterprises Development Corporation – Creation of post of Chief Executive Officer(CEO) to A.P. MSME Development Corporation-
                                                                        Orders - Issued
                                                                    </td>
                                                                    <td><a href="assets/gos/19092018INDS_MS109.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>7</td>
                                                                    <td style="width: 15%;">RT.No. 315 </td>
                                                                    <td style="width: 15%;">21-11-2019</td>
                                                                    <td>Industries, Infrastructure, Investment and Commerce Department -Appointment of Sri R. Pavana Murthy, ITS., as Chief Executive Officer, A.P. MSME Development Corporation - Orders - Issued
                                                                    </td>
                                                                    <td><a href="assets/gos/21112019INDS_RT315.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>8</td>
                                                                    <td style="width: 15%;">MS.No. 91</td>

                                                                    <td style="width: 15%;">22-06-2017</td>
                                                                    <td>Ind. & Com. Dept., - Formation of A.P.MSME Development Corporation – Orders – Issued
                                                                    </td>
                                                                    <td><a href="assets/gos/22062017INDS_MS91.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>9</td>
                                                                    <td style="width: 15%;">MS.No. 70</td>

                                                                    <td style="width: 15%;">31-05-2018</td>
                                                                    <td>Industries & Commerce Department – Policy for Establishment of Micro, Small and Medium Enterprises (MSME) Parks M- Parks in Each Assembly Constituency – M-Parks Policy 2018-23 - Orders
                                                                        – Issued
                                                                    </td>
                                                                    <td><a href="assets/gos/31052018INDS_MS70.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>10</td>
                                                                    <td style="width: 15%;">RT No. 182</td>

                                                                    <td style="width: 15%;">15-11-2021</td>
                                                                    <td>Ind. & Com. Department - Andhra Pradesh Micro, Small and Medium Enterprises Development Corporation - Appointment of Board of Directors for Andhra Pradesh Micro, Small and Medium Enterprises Development - Orders - Issued.
                                                                    </td>
                                                                    <td><a href="assets/gos/GORT NO 182.PDF" target="_blank">View</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container d-none">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h5>GSWS Department</h5>
                                                    <div class="content">
                                                        <ul>

                                                            <li><i class="fas fa-arrow-circle-right"></i>Software platform developed to deliver citizen services from village/ ward secretariats; transitioned to their new vendor</li>
                                                            <!-- <li><i class="fas fa-arrow-circle-right"></i>Maintain HCM Core Dashboard publishing key metrics per CMO (starting with Navaratnalu and Manifesto programs).</li>
                                                            <li><i class="fas fa-arrow-circle-right"></i>Quality Assurance CoE to provide QA and testing services to GoAP Departments.</li>
                                                            <li><i class="fas fa-arrow-circle-right"></i>CMMi 3 Certification in progress to ensure data security and best practices in software development.</li>
                                                            <li><i class="fas fa-arrow-circle-right"></i>Provide integration services and consulting in SDLC best practices to user departments (BA, Architecture, QA, etc).</li>
                                                            <li><i class="fas fa-arrow-circle-right"></i>Provide Digital Transformation of Government services and other such software development related support.</li> -->

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container d-none">
                                            <!-- <h4>Timeline Style : Demo-8</h4> -->
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="main-timeline8">
                                                        <div class="timeline">
                                                            <span class="timeline-icon timeline-icon1"></span>
                                                            <span class="year">GSWS Department</span>
                                                            <div class="timeline-content">
                                                                <!-- <h3 class="title">Web Desginer</h3> -->
                                                                <p class="description">
                                                                    Software platform developed to deliver citizen services from village/ ward secretariats; transitioned to their new vendor

                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="timeline">
                                                            <span class="timeline-icon timeline-icon2"></span>
                                                            <span class="year">HCM Core Dashboard</span>
                                                            <div class="timeline-content">
                                                                <!-- <h3 class="title">Web Developer</h3> -->
                                                                <p class="description">
                                                                    Informs the citizens of HCM’s benefit schemes and programs by showcasing metrics of all key schemes up to Mandal level.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="timeline">
                                                            <span class="timeline-icon timeline-icon3"></span>
                                                            <span class="year">Planning Department</span>
                                                            <div class="timeline-content">
                                                                <!-- <h3 class="title">Web Desginer</h3> -->
                                                                <p class="description">
                                                                    Developed Connect Andhra website to enable people to contribute for various Government of Andhra Pradesh projects.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="timeline">
                                                            <span class="timeline-icon timeline-icon4"></span>
                                                            <span class="year">Agri Marketing Department</span>
                                                            <div class="timeline-content">
                                                                <!-- <h3 class="title">Web Developer</h3> -->
                                                                <p class="description">
                                                                    Developed and supported Agri Marketing services such as Rythu Bazar, e-Permit, Rythu Bandhu Pathakam (RBP), Warehouse Management System, etc.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="timeline">
                                                            <span class="timeline-icon timeline-icon5"></span>
                                                            <span class="year">Fisheries Department</span>
                                                            <div class="timeline-content">
                                                                <!-- <h3 class="title">Web Developer</h3> -->
                                                                <p class="description">
                                                                    Designed and developed applications such as registration of fishermen, beneficiary schemes (YSR Matsyakara Bharosa, Diesel Subsidy)(INR 10.01 Cr. availed).
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="timeline">
                                                            <span class="timeline-icon timeline-icon6"></span>
                                                            <span class="year">Law Department</span>
                                                            <div class="timeline-content">
                                                                <!-- <h3 class="title">Web Developer</h3> -->
                                                                <p class="description">
                                                                    Delivered YSR Law Nestham application for managing the benefit scheme (INR 12.24 Cr. availed so far).
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="student-list d-none">
                                            <li>23,564 Total Students</li>
                                            <li><span class="theme_color">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span> (1254 Rating)
                                            </li>
                                            <li>256 Reviews</li>
                                        </ul>
                                        <!-- <h3>What you’ll learn?</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                        <!-- <h3>Requirements</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="prod-reviews" role="tabpanel" aria-labelledby="prod-reviews-tab">
                            <div class="content white-bg pt-30">
                                <!-- Cource Overview -->
                                <div class="course-overview">
                                    <div class="inner-box section-title">
                                        <h4>Objecttives</h4>
                                        <div class="content">
                                            <ul>

                                                <li><i class="fas fa-arrow-circle-right"></i>Help GoAP departments to comply with IT standards and policies published by IT,E&C Department.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>Maintain HCM Core Dashboard publishing key metrics per CMO (starting with Navaratnalu and Manifesto programs).</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>Quality Assurance CoE to provide QA and testing services to GoAP Departments.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>CMMi 3 Certification in progress to ensure data security and best practices in software development.</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>Provide integration services and consulting in SDLC best practices to user departments (BA, Architecture, QA, etc).</li>
                                                <li><i class="fas fa-arrow-circle-right"></i>Provide Digital Transformation of Government services and other such software development related support.</li>

                                            </ul>
                                        </div>
                                        <ul class="student-list d-none">
                                            <li>23,564 Total Students</li>
                                            <li><span class="theme_color">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span> (1254 Rating)
                                            </li>
                                            <li>256 Reviews</li>
                                        </ul>
                                        <!-- <h3>What you’ll learn?</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                                            <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                        <!-- <h3>Requirements</h3> -->
                                        <ul class="review-list d-none">
                                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                                            <li>Phasellus enim magna, varius et commodo ut.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="content pt-30 pb-30 white-bg d-none">
                                <div class="cource-review-box mb-30">
                                    <h4>Stephane Smith</h4>
                                    <div class="rating">
                                        <span class="total-rating">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span>&ensp; 256 Reviews
                                    </div>
                                    <div class="text">Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus.</div>
                                    <div class="helpful">Was this review helpful?</div>
                                    <ul class="like-option">
                                        <li><i class="fa fa-thumbs-o-up"></i></li>
                                        <li><i class="fa fa-thumbs-o-down"></i></li>
                                        <li><a class="report" href="#">Report</a></li>
                                    </ul>
                                </div>
                                <div class="cource-review-box mb-30">
                                    <h4>Anna Sthesia</h4>
                                    <div class="rating">
                                        <span class="total-rating">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span>&ensp; 256 Reviews
                                    </div>
                                    <div class="text">Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus.</div>
                                    <div class="helpful">Was this review helpful?</div>
                                    <ul class="like-option">
                                        <li><i class="fa fa-thumbs-o-up"></i></li>
                                        <li><i class="fa fa-thumbs-o-down"></i></li>
                                        <li><a class="report" href="#">Report</a></li>
                                    </ul>
                                </div>
                                <div class="cource-review-box mb-30">
                                    <h4>Petey Cruiser</h4>
                                    <div class="rating">
                                        <span class="total-rating">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span>&ensp; 256 Reviews
                                    </div>
                                    <div class="text">Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus.</div>
                                    <div class="helpful">Was this review helpful?</div>
                                    <ul class="like-option">
                                        <li><i class="fa fa-thumbs-o-up"></i></li>
                                        <li><i class="fa fa-thumbs-o-down"></i></li>
                                        <li><a class="report" href="#">Report</a></li>
                                    </ul>
                                </div>
                                <div class="cource-review-box">
                                    <h4>Rick O'Shea</h4>
                                    <div class="rating">
                                        <span class="total-rating">4.5</span> <span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span><span class="fa fa-star"></span>&ensp; 256 Reviews
                                    </div>
                                    <div class="text">Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus.</div>
                                    <div class="helpful">Was this review helpful?</div>
                                    <ul class="like-option">
                                        <li><i class="fa fa-thumbs-o-up"></i></li>
                                        <li><i class="fa fa-thumbs-o-down"></i></li>
                                        <li><a class="report" href="#">Report</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Video Column -->
            <div class="video-column col-lg-4 d-none">
                <div class="rightSide d-none">
                    <ul class="rightUl">
                        <li>
                            <a href="">Course 2</a>
                        </li>
                        <li>
                            <a href="">Course 3</a>
                        </li>
                        <li>
                            <a href="">Course 4</a>
                        </li>
                    </ul>
                </div>
                <div class="inner-column row justify-content-center align-items-end d-none">
                    <!-- <h4 style="color: #626262;">Gallery</h4> -->
                    <!-- Video Box -->
                    <div class="col-md-12">
                        <div class="societyLogo">
                            <img src="assets/images/societies/epragati.png" alt="image" class="img-fluid">
                        </div>
                    </div>

                    <!-- <div class="col-md-12">
                        <div class="societyUrl text-right" style="color: #004982;">
                            <strong>Visit : </strong>
                            <a href="http://e-pragati.in/" class="" target="_blank" style="color: #1acc8d;text-decoration: none;font-weight: bold;">http://e-pragati.in/</a>
                        </div>
                    </div> -->
                    <div class="intro-video media-icon orange-color2 d-none">
                        <img class="video-img img-fluid" src="assets/images/about-video-bg2.png" alt="Video Image">
                        <a class="popup-videos" href="javascript:void(0)">
                            <i class="fa fa-play"></i>
                        </a>
                        <!-- <h4>Preview this course</h4> -->
                    </div>
                    <!-- End Video Box -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End intro Courses -->

<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Executive Body</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">About</a></li>
                        <li><a style="color: #fff;">Executive Body</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>


<section class="sectionpadding">
    <div class="container">


        <div class="row">
            <!-- <div class="col-md-2"></div> -->
            <!-- data-aos="fade-right" data-aos-duration="1500" -->
            <div class="col-md-4 mt-3 mb-3">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/ias.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri R. Karikal Valaven, IAS</strong>
                        <small>Special Chief Secretary to Govt</small>
                            <small>Industries and Commerce</small>
                    </div>

                </div>
            </div>

            <!-- <div class="col-md-4 mt-3 mb-3" data-aos="fade-down" data-aos-duration="1500">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/subramanyam.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri J.V.N Subramanyam, IAS</strong>
                        <small>VC & MD APIIC</small>
                    </div>

                </div>
            </div> -->
            <!-- data-aos="fade-left" data-aos-duration="1500" -->
            <div class="col-md-4 mt-3 mb-3">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/srijana gummalla.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Dr. Srijana Gummalla, IAS</strong>
                        <small>Director of Industries & CEO</small>
                        <small>AP MSME Development Corporation</small>
                    </div>

                </div>
            </div>
            <!-- <div class="col-md-2"></div> -->
            <!-- <div class="col-md-12">
                <div class="bodyHeading text-center">
                    <h6>board of director</h6>
                </div>
            </div> -->
            <div class="row d-none">
                <div class="col-md-4 mt-3 mb-3"></div>
                <div class="col-md-4 mt-3 mb-3" data-aos="fade-up" data-aos-duration="1500">
                    <div href="#" class="d-flex flex-column mainmembers">
                        <div class="flex-img">
                            <img src="assets/images/boardmembers/gopala krishna.png"
                                class="img-fluid" alt="no-img" />
                        </div>
                        <div class="d-flex flex-column">
                            <strong>Sri B. Gopala Krishna, B.Tech</strong>
                            <small>CEO</small>
                        </div>
    
                    </div>
                </div>
                <!-- <div class="col-md-4 mt-3 mb-3"></div> -->
            </div>



        <!-- </div>
        <div class="row"> -->
            <!-- <div class="col-md-2"></div> -->
            <!-- data-aos="fade-right" data-aos-duration="1500" -->
            
            <div class="col-md-4 mt-3 mb-3">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/gopala krishna.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri B. Gopala Krishna, B.Tech</strong>
                        <small>Executive Director</small>
                        <small><strong>Mobile: 9491039358</strong></small>
                    </div>

                </div>
            </div>

            <!-- <div class="col-md-4 mt-3 mb-3" data-aos="fade-down" data-aos-duration="1500">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/subramanyam.png"
                            class="img-fluid" alt="no-img" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri J.V.N Subramanyam, IAS</strong>
                        <small>VC & MD APIIC</small>
                    </div>

                </div>
            </div> -->
            <!-- data-aos="fade-left" data-aos-duration="1500" -->
            <div class="col-md-4"></div>
            <div class="col-md-4 mt-3 mb-3">
                <div href="#" class="d-flex flex-column mainmembers">
                    <div class="flex-img">
                        <img src="assets/images/boardmembers/assistantdirector.png"
                            class="img-fluid" alt="no-img" style="width: 54%;" />
                    </div>
                    <div class="d-flex flex-column">
                        <strong>Sri M. Srinivasulu Reddy</strong>
                        <small>Assistant Director</small>
                        <small><strong>Mobile: 8978566599</strong></small>
                    </div>

                </div>
            </div>
            <!-- <div class="col-md-2"></div> -->
            <!-- <div class="col-md-12">
                <div class="bodyHeading text-center">
                    <h6>board of director</h6>
                </div>
            </div> -->
            <div class="row d-none">
                <div class="col-md-4 mt-3 mb-3"></div>
                <div class="col-md-4 mt-3 mb-3" data-aos="fade-up" data-aos-duration="1500">
                    <div href="#" class="d-flex flex-column mainmembers">
                        <div class="flex-img">
                            <img src="assets/images/boardmembers/gopala krishna.png"
                                class="img-fluid" alt="no-img" />
                        </div>
                        <div class="d-flex flex-column">
                            <strong>Sri B. Gopala Krishna, B.Tech</strong>
                            <small>CEO</small>
                        </div>
    
                    </div>
                </div>
                <div class="col-md-4 mt-3 mb-3"></div>
            </div>



        </div>

    </div>
</section>






<!-- Modal -->
<div class="modal fade" id="chairmanModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="chairmanModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h4 class="modal-title" id="myModalLabel">Chairman Profile</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="profileContent">
                              <p>Shri Vanka Ravindra Nath is a well renowned and reputed industrialist having experience of over 35
                                  years in manufacturing sector and international trade.</p>
                              <p>Shri Vanka Ravindranath is the son of Late Shri Vanka Satyanarayana (Freedom fighter and 3 times MLA,
                                  Penugonda). He is born in Tanuku, West Godavari district and studied Sugar Technology at
                                  Visakhapatnam.</p>
                              <p>He Started his own business of Hair exports in 1989 under SSI (Small scale industries), successfully
                                  establishing it as one of the largest units in the country. Later on he established spinning mills and
                                  entered into textile sector.</p>
                          </div>
                          <div class="achievements">
                              <h4 class="font-weight-bold">Achievements</h4>
                              <hr>
                              <div class="modalUl pt-2">
                                  <ul>
                                      <li><i class="fas fa-chevron-circle-right"></i>National Export Award, Ministry of Industries, Govt. of India: 2 times (1997 & 1999)</li>
                                      <li><i class="fas fa-chevron-circle-right"></i>Top Export Award ( National Wide), ( PLEXCONCIL ) : 2 times ( 2000 & 2001)</li>
                                      <li><i class="fas fa-chevron-circle-right"></i>FAPCII award – Best SSI Exporter in AP: 2 times</li>
                                      <li><i class="fas fa-chevron-circle-right"></i>FIEO Award (National wide) – Niryat Ratna award: 3 times ( 2001,2015 & 2016)</li>
                                  </ul>
                              </div>
                          </div>
                          <div class="achievements pt-2">
                              <h4 class="font-weight-bold">Countries Visited</h4>
                              <hr>
                              <div class="countries pt-2">
                                  <p>Australia, Belgium ,Brazil , Burma, Bangladesh, China, Check, France, Germany, Hong Kong, Italy,
                                      Indonesia, Japan, Korea, Nepal, Portugal, Spain, South Africa, Switzerland, UAE,UK, USA & Vietnam.</p>
                              </div>
                          </div>
                          <div class="text-right">
            <button type="button" class="btn btn-primary btn-md" data-dismiss="modal">Close</button>
  
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer d-none">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
</div>

<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>APSFC
                    </h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">APSFC</a></li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="schemes_content_heading">
                    <h2>Description</h2>
                </div>
                <div class="section_content">
                    <p>
                        Andhra Pradesh State Financing Corporation (APSFC) is a Government Enterprise which provides credit support to MSME units in the state.
                        The Corporation is focusing on the credit support which foster the growth and addressing the emerging issues such as 
                        Term loan and Working capital for the MSME units to enhance their economies of scale.
                        This will generate and boost both direct and indirect employment in the MSME sector.
                    </p>
                    <div class="content">
                        <ul>
                            <li>
                                general loan scheme
                            </li>
                            <li>
                                practicing doctors scheme
                            </li>
                            <li>
                                MSME-MTL scheme for working capital & other business needs
                            </li>
                            <li>
                                marketing assistance scheme
                            </li>
                            <li>
                                young entrepreneurs scheme
                            </li>
                            <li>
                                MSME scheme for SC/ST entrepreneurs
                            </li>
                            <li>
                                covid top up loan scheme 2.0 (CTLS-2.0)
                            </li>
                            <li>
                                medium term loans to good entrepreneurs at concessional terms
                            </li>
                            <li>
                                single window scheme
                            </li>
                        </ul>
                    </div>
                    <strong class="text-danger">Note :</strong> The aforementioned schemes of APSFC provided for dissemination of information. To get more information and other prerequisites, please do visit <a href="https://esfc.ap.gov.in/" target="_blank">https://esfc.ap.gov.in/</a>
                </div>
            </div>
        </div>
    </div>    
</section>
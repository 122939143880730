import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ongoingclusters',
  templateUrl: './ongoingclusters.component.html',
  styleUrls: ['./ongoingclusters.component.css']
})
export class OngoingclustersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-latestpolicies',
  templateUrl: './latestpolicies.component.html',
  styleUrls: ['./latestpolicies.component.css']
})
export class LatestpoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

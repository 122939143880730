<section class="process pt-5 pb-5" id="process">
    <!-- Takes an ordered list and renders a responsive timeline try resizing the viewport -->
    <!-- <h1>Timeline of HTML</h1> -->
    <div class="container">
        <div class="section-title">
            <h2>MSME Cluster Development Process</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                        hic quas.</p> -->
        </div>
        <div class="row">
            <ol>
                <li>
                    <p class="event-date">Cluster Identification</p>
                    <p class="event-description">Through DICs organize awareness campaigns & workshop for identification of members & clusters.</p>
                </li>
                <li>
                    <p class="event-date">Prepare DSR
                    </p>
                    <p class="event-description">Conduct cluster & market Study and identify required Soft interventions.
                    </p>
                </li>
                <li>
                    <p class="event-date">Assist in approvals & Company formation
                    </p>
                    <p class="event-description">Assist members in formation of SPV and support SPV in securing approvals.
                    </p>
                </li>
                <li>
                    <p class="event-date">Prepare DPR
                    </p>
                    <p class="event-description">Identification of technology , cost estimate, financial feasibility etc.
                    </p>
                </li>
                <li>
                    <p class="event-date">Support in Final Approval
                    </p>
                    <p class="event-description">Assitance to SPV on Final approval by State Government. </p>
                </li>
                <li>
                    <p class="event-date">Procurement Support
                    </p>
                    <p class="event-description">Preparation of Tender documents, bid process management, onboarding of vendors, project monitoring and support in getting grant from State Government.
                    </p>
                </li>
                <!-- <li>
                    <p class="event-date">May 1996</p>
                    <p class="event-description">RFC 1942 (tables)</p>
                </li>
                <li>
                    <p class="event-date">August 1996</p>
                    <p class="event-description">RFC 1980 (client-side image maps)</p>
                </li>
                <li>
                    <p class="event-date">January 1997</p>
                    <p class="event-description">RFC 2070 (internationalization)</p>
                </li>
                <li>
                    <p class="event-date">14 January 1997</p>
                    <p class="event-description">HTML 3.2 was published as a W3C Recommendation.</p>
                </li>
                <li>
                    <p class="event-date">18 December 1997</p>
                    <p class="event-description">HTML 4.0 was published as a W3C Recommendation.</p>
                </li>
                <li>
                    <p class="event-date">24 April 1998</p>
                    <p class="event-description">HTML 4.0 was reissued with minor edits</p>
                </li>
                <li>
                    <p class="event-date">24 December 1999</p>
                    <p class="event-description">HTML 4.01 was published as a W3C Recommendation</p>
                </li>
                <li>
                    <p class="event-date">26 January 2000</p>
                    <p class="event-description">XHTML 1.0 was published as a W3C Recommendation</p>
                </li>
                <li>
                    <p class="event-date">May 2000</p>
                    <p class="event-description">"ISO HTML", based on HTML 4.01 Strict was published as an ISO/IEC international standard.</p>
                </li>
                <li>
                    <p class="event-date">31 May 2001</p>
                    <p class="event-description">XHTML 1.1 was published as a W3C Recommendation.</p>
                </li>
                <li>
                    <p class="event-date">28 October 2014</p>
                    <p class="event-description">HTML5 was published as a W3C Recommendation.</p>
                </li>
                <li>
                    <p class="event-date">1 November 2016</p>
                    <p class="event-description">HTML 5.1 was published as a W3C Recommendation.</p>
                </li>
                <li>
                    <p class="event-date">14 December 2017</p>
                    <p class="event-description">HTML 5.2 was published as a W3C Recommendation.</p>
                </li> -->
            </ol>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizationstructure',
  templateUrl: './organizationstructure.component.html',
  styleUrls: ['./organizationstructure.component.css']
})
export class OrganizationstructureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

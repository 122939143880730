<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Vacancies</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Notifications</a></li>
                        <li><a style="color: #fff;">Vacancies</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Position</th>
                                <th>No.of Vacancies</th>
                                <th>Method of Filling</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Superintending Engineer (GM)</td>
                                <td>1</td>
                                <td>On deputation basis from Engineering departments of GoAP</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Executive Engineer</td>
                                <td>3</td>
                                <td>On deputation basis from Engineering departments of GoAP</td>
                            </tr>
                            <!-- <tr>
                                <td>3</td>
                                <td>Joint Director (Industries)</td>
                                <td>1</td>
                                <td>On Re-Distrubution basis from Industries department</td>
                            </tr> -->
                            <tr>
                                <td>3</td>
                                <td>Assistant Director (Industries)</td>
                                <td>2</td>
                                <td>On Re-Distrubution basis from Industries department</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Joint Director (Treasury)</td>
                                <td>1</td>
                                <td>On deputation basis from Treasury department of GoAP</td>
                            </tr>
                            <!-- <tr>
                                <td>6</td>
                                <td>Account Officer/Assistant Director (Treasury)</td>
                                <td>1</td>
                                <td>On deputation basis from Treasury department of GoAP</td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row d-none">
            <div class="col-md-12">
                <div class="section_heading">
                    <h2>Latest Vacancies</h2>
                </div>
            </div>
            <div class="col-md-12">
                <div class="section_content_bg">
                    <div class="d-flex mt-3">

                        <svg xmlns="http://www.w3.org/2000/svg"
                            width="30" height="30"
                            fill="currentColor" class="bi
                            bi-check2-square" viewBox="0 0 16
                            16">
                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5
                                13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5
                                0 0 1 0 1H3a.5.5 0 0
                                0-.5.5v10a.5.5 0 0 0
                                .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5
                                0 0 1 1 0v5a1.5 1.5 0 0 1-1.5
                                1.5H3z" />
                                <path d="m8.354 10.354 7-7a.5.5
                                    0 0 0-.708-.708L8 9.293
                                    5.354 6.646a.5.5 0 1
                                    0-.708.708l3 3a.5.5 0 0 0
                                    .708 0z" />
                                </svg>
                                <p class="ml-2">They are many
                                    vacancies of There are many
                                    variations of passages of
                                    Lorem Ipsum available, but
                                    the majority have suffered
                                    alteration in some form, by
                                    injected humour, or
                                    randomised words which don't
                                    look even slightly
                                    believable.</p>
                            </div>
                            <div class="d-flex mt-3 mb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="20"
                                    fill="currentColor"
                                    class="bi bi-check2-square"
                                    viewBox="0 0 16 16">
                                    <path d="M3 14.5A1.5 1.5 0 0
                                        1 1.5 13V3A1.5 1.5 0 0 1
                                        3 1.5h8a.5.5 0 0 1 0
                                        1H3a.5.5 0 0
                                        0-.5.5v10a.5.5 0 0 0
                                        .5.5h10a.5.5 0 0 0
                                        .5-.5V8a.5.5 0 0 1 1
                                        0v5a1.5 1.5 0 0 1-1.5
                                        1.5H3z" />
                                        <path d="m8.354 10.354
                                            7-7a.5.5 0 0
                                            0-.708-.708L8 9.293
                                            5.354 6.646a.5.5 0 1
                                            0-.708.708l3 3a.5.5
                                            0 0 0 .708 0z" />
                                        </svg>
                                        <p class="ml-2"> All the
                                            Lorem Ipsum
                                            generators on the
                                            Internet tend to
                                            repeat predefined
                                            chunks as necessary,
                                            making this the
                                            first true generator
                                            on the Internet</p>
                                    </div>
                                    <div class="d-flex mt-3
                                        mb-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            class="bi
                                            bi-check2-square"
                                            viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5
                                                1.5 0 0 1 1.5
                                                13V3A1.5 1.5 0 0
                                                1 3 1.5h8a.5.5 0
                                                0 1 0 1H3a.5.5 0
                                                0 0-.5.5v10a.5.5
                                                0 0 0
                                                .5.5h10a.5.5 0 0
                                                0 .5-.5V8a.5.5 0
                                                0 1 1 0v5a1.5
                                                1.5 0 0 1-1.5
                                                1.5H3z" />
                                                <path d="m8.354
                                                    10.354
                                                    7-7a.5.5 0 0
                                                    0-.708-.708L8
                                                    9.293 5.354
                                                    6.646a.5.5 0
                                                    1
                                                    0-.708.708l3
                                                    3a.5.5 0 0 0
                                                    .708 0z" />
                                                </svg>
                                                <p class="ml-2">
                                                    It uses a
                                                    dictionary
                                                    of over 200
                                                    Latin words,
                                                    combined
                                                    with a
                                                    handful of
                                                    model
                                                    sentence
                                                    structures,
                                                    to generate
                                                    Lorem Ipsum
                                                    which looks
                                                    reasonable.
                                                </p>
                                            </div>
                </div>
                                    </div>
                                </div>
                            </div>
                        </section>
<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>Approval & Implementation Procedure</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">Approval & Implementation Procedure</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="section_bg py-5">
    <div class="container">
        <div class="row pb-3">
            <div class="col-md-4
                mb-3">
                <div class="card">
                    <div
                        class="d-flex
                        justify-content-between">
                        <img
                            src="assets/images/clusterprocess/icon1.png"
                            alt="icon1">
                        <strong
                            class="step">01
                            </strong>
                    </div>
                    <div
                        class="card-body">
                        <h5
                            class="card-title">Cluster
                            Identification
                        </h5>
                        <p
                            class="card-text">Through
                            DICs
                            organize
                            awareness
                            campaigns
                            &
                            workshop
                            for
                            identification
                            of
                            members
                            &
                            clusters.
                            .</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4
                mb-3">
                <div class="card">
                    <div
                        class="d-flex
                        justify-content-between">
                        <img
                            src="assets/images/clusterprocess/icon2.png"
                            alt="icon2">
                        <strong
                            class="step1
                            step">02
                            </strong>
                    </div>

                    <div
                        class="card-body">
                        <h5
                            class="card-title">Preparation of
                            DSR
                        </h5>
                        <p
                            class="card-text">
                            Identify the common needs of the cluster, As-is analysis of cluster and suggestion of hard and soft interventions.
                            <!-- Conduct
                            cluster
                            & market
                            Study
                            and
                            identify
                            required
                            Soft
                            interventions. -->
                        </p>

                    </div>
                </div>
            </div>
            <div class="col-md-4
                mb-3">
                <div class="card">
                    <div
                        class="d-flex
                        justify-content-between">
                        <img
                            src="assets/images/clusterprocess/icon3.png"
                            alt="icon3">
                        <strong
                            class="step2
                            step">03
                            </strong>
                    </div>


                    <div
                        class="card-body">
                        <h5
                            class="card-title">Assistance
                            for
                            approvals
                            &
                            Special Purpose Vehicle (SPV)
                            formation
                        </h5>
                        <p
                            class="card-text">
                            Assist
                            members
                            in
                            formation
                            of SPV
                            and
                            to make an application for financial assistance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4
            mb-3">
            <div class="card">
                <div
                    class="d-flex
                    justify-content-between">
                    <img
                        src="assets/images/clusterprocess/icon4.png"
                        alt="icon4">
                    <strong
                        class="step3
                        step">04
                        </strong>
                </div>

                <div
                    class="card-body">
                    <h5
                        class="card-title">Preparation of
                        DPR
                    </h5>
                    <p
                        class="card-text">Identification
                        of
                        technology, cost
                        estimate,
                        financial
                        feasibility and plan of action
                        etc.
                    </p>

                </div>
            </div>
        </div>
        <div class="col-md-4
            mb-3">
            <div class="card">
                <div
                    class="d-flex
                    justify-content-between">
                    <img
                        src="assets/images/clusterprocess/icon5.png"
                        alt="icon5">
                    <strong
                        class="step4
                        step">05
                        </strong>
                </div>

                <div
                    class="card-body">
                    <h5
                        class="card-title">Support
                        in Final
                        Approval
                    </h5>
                    <p
                        class="card-text">Assitance
                        to SPV
                        on Final
                        approval
                        by State
                        Government.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4
            mb-3">
            <div class="card">
                <div
                    class="d-flex
                    justify-content-between">
                    <img
                        src="assets/images/clusterprocess/icon6.png"
                        alt="icon6">
                    <strong
                        class="step5
                        step">06
                        </strong>
                </div>
                <div
                    class="card-body">
                    <h5 class="card-title">Procurement Support</h5>
                    <p class="card-text">Preparation of Tender documents, bid
                        process
                        management,
                        onboarding
                        of
                        vendors,
                        project
                        monitoring
                        and
                        release of grant from state and central Governments.</p>
                </div>
            </div>
        </div>
        </div>
    </div>
</section>
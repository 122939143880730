<style>
    .breadcrumb {
        padding: 5px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    
    .breadcrumb>li:first-child {
        padding-left: 10px;
    }
    
    .breadcrumb>li {
        display: inline-block;
        background: #f5f5f5;
        color: #9d9d9d;
        border-radius: 25px;
        padding: 1px 20px 1px 20px;
        /* padding: 1px 15px 1px 20px; */
        margin-left: -17px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
    }
    
    .breadcrumb>.active {
        color: #fff;
        background: #1acc8d;
        /* background: #7ab800; */
    }
    
    .breadcrumb>li+li:after,
    .breadcrumb>li+li:before {
        content: "";
        padding: 0 5px;
        color: #ccc;
        background: #f5f5f5;
        border-radius: 25px;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 0;
    }
    
    .breadcrumb>li+li:after {
        z-index: 1;
        height: 22px;
        top: -1px;
        left: -7px;
        background: #fff;
    }
    
    a {
        text-decoration: none;
    }
</style>
<section class="breadcrumb">
    <div class="container breadcrumb_container">
        <div class="row">
            <div class="hidden-xs col-sm-12 col-md-12">
                <ol class="breadcrumb">
                    <li><a routerLink="/home">Home</a></li>
                    <!-- <li>Careers</li> -->
                    <li class="active">Activities</li>
                </ol>
            </div>
            <div class="col-sm-6 col-md-6 d-none">
                <div class="sharethis-inline-share-buttons st-right  st-inline-share-buttons st-animated" id="st-1">
                    <div class="st-total ">
                        <span class="st-label">69</span>
                        <span class="st-shares">
        Shares
        </span>
                    </div>
                    <div class="st-btn st-first" data-network="linkedin" style="display: inline-block;">
                        <img alt="linkedin sharing button" src="https://platform-cdn.sharethis.com/img/linkedin.svg">

                    </div>
                    <div class="st-btn" data-network="twitter" style="display: inline-block;">
                        <img alt="twitter sharing button" src="https://platform-cdn.sharethis.com/img/twitter.svg">

                    </div>
                    <div class="st-btn" data-network="reddit" style="display: inline-block;">
                        <img alt="reddit sharing button" src="https://platform-cdn.sharethis.com/img/reddit.svg">

                    </div>
                    <div class="st-btn" data-network="telegram" style="display: inline-block;">
                        <img alt="telegram sharing button" src="https://platform-cdn.sharethis.com/img/telegram.svg">

                    </div>
                    <div class="st-btn st-last" data-network="email" style="display: inline-block;">
                        <img alt="email sharing button" src="https://platform-cdn.sharethis.com/img/email.svg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="activities pt-5 pb-5" id="activities">
    <div class="container">
        <div class="section-title">
            <h2>Activities</h2>
            <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem
                hic quas.</p> -->
        </div>
        <div class="row">
            <div class="col-md-12">
                <img src="assets/images/activities/1.png" alt="image" class="img-fluid">
            </div>
        </div>
    </div>
</section>
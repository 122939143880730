import { Component, OnInit, ViewChild, ElementRef, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { NgbCarousel, NgbCarouselConfig, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  closeBtn: boolean = true;



  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  
  imageObject: Array<object> = [

    {
      image: 'assets/images/gallery/21.jpeg',
      thumbImage: 'assets/images/gallery/21.jpeg',
      title: "AP MSME Development Corporation has signed a Memorandum of Understanding with National Small Industries Corporation for Raw materials Bank and Marketing support for our MSME Clusters on 21-7-2022 in the presence of our Special Chief Secretary and our Chairman",
      alt: "AP MSME Development Corporation has signed a Memorandum of Understanding with National Small Industries Corporation for Raw materials Bank and Marketing support for our MSME Clusters on 21-7-2022 in the presence of our Special Chief Secretary and our Chairman"
    },
    {
      image: 'assets/videos/v5.mp4',
      thumbImage: 'assets/videos/v5.mp4',
      title: "AP MSME Development Corporation has signed a Memorandum of Understanding with National Small Industries Corporation for Raw materials Bank and Marketing support for our MSME Clusters on 21-7-2022 in the presence of our Special Chief Secretary and our Chairman",
      alt: "AP MSME Development Corporation has signed a Memorandum of Understanding with National Small Industries Corporation for Raw materials Bank and Marketing support for our MSME Clusters on 21-7-2022 in the presence of our Special Chief Secretary and our Chairman"
    },
    {
      image: 'assets/videos/v4.mp4',
      thumbImage: 'assets/videos/v4.mp4',
      title: "11th Board meeting of AP MSME Development Corporation on 07-07-2022",
      alt: "11th Board meeting of AP MSME Development Corporation on 07-07-2022"
    },

    {
      image: 'assets/images/gallery/g17.jpeg',
      thumbImage: 'assets/images/gallery/g17.jpeg',
      title: "11th Board meeting of AP MSME Development Corporation on 07-07-2022",
      alt: "11th Board meeting of AP MSME Development Corporation on 07-07-2022"
    },

    {
      image: 'assets/images/gallery/g20.jpeg',
      thumbImage: 'assets/images/gallery/g20.jpeg',
      title: "Launching of MSME Development Corporation Website on 27-06-2022 by Sri Gudivada Amarnath, Hon'ble Minister for , Infrastructure, Investment & Commerce, Information Technology on the occasion of International MSME Day Celebrations at Visakhapatnam",
      alt: "Launching of MSME Development Corporation Website on 27-06-2022 by Sri Gudivada Amarnath, Hon'ble Minister for , Infrastructure, Investment & Commerce, Information Technology on the occasion of International MSME Day Celebrations at Visakhapatnam",
    },

    {
      image: 'assets/images/gallery/g12.jpeg',
      thumbImage: 'assets/images/gallery/g12.jpeg',
      title: "Greeting Hon'ble Chief Minister by the Chairman",
      alt: "Greeting Hon'ble Chief Minister by the Chairman"
    },
    {
      image: 'assets/images/gallery/g11.jpeg',
      thumbImage: 'assets/images/gallery/g11.jpeg',
      title: "Greeting Hon'ble Minister by the CEO",
      alt: "Greeting Hon'ble Minister by the CEO"
    },
    {
      image: 'assets/images/gallery/g7.jpeg',
      thumbImage: 'assets/images/gallery/g7.jpeg',
      title: 'Oath taking ceremony by Board of Directors',
      alt: 'Oath taking ceremony by Board of Directors'
    },
    {
      image: 'assets/images/gallery/g9.jpeg',
      thumbImage: 'assets/images/gallery/g9.jpeg',
      title: 'Visit to TANSIDCO, Chennai by Chairman and CEO',
      alt: 'Visit to TANSIDCO, Chennai by Chairman and CEO'
    },
    {
    image: 'assets/images/gallery/g1.jpeg',
    thumbImage: 'assets/images/gallery/g1.jpeg',
    alt: 'Exposure visit to TANSIDCO, Chennai',
    title: 'Exposure visit to TANSIDCO, Chennai'
  }, {
    image: 'assets/images/gallery/g2.jpeg',
    thumbImage: 'assets/images/gallery/g2.jpeg',
    title: 'Visit to Flatted Factory Complex, Chennai',
    alt: 'Visit to Flatted Factory Complex, Chennai'
  },
  {
    image: 'assets/images/gallery/g3.jpeg',
    thumbImage: 'assets/images/gallery/g3.jpeg',
    title: 'Motivation on formation of Auto Cluster, Rajahmundry',
    alt: 'Motivation on formation of Auto Cluster, Rajahmundry'
  },
  {
    image: 'assets/images/gallery/g4.jpeg',
    thumbImage: 'assets/images/gallery/g4.jpeg',
    title: 'Site visit to Auto Engineering Cluster, Sarpavaram (Kakinada)',
    alt: 'Site visit to Auto Engineering Cluster, Sarpavaram (Kakinada)'
  },
  {
    image: 'assets/images/gallery/g5.jpeg',
    thumbImage: 'assets/images/gallery/g5.jpeg',
    title: 'Interaction with Printing Cluster, Kakinada',
    alt: 'Interaction with Printing Cluster, Kakinada'
  },
  {
    image: 'assets/images/gallery/g6.jpeg',
    thumbImage: 'assets/images/gallery/g6.jpeg',
    title: 'Interaction with Wooden Furniture Cluster Beneficiaries, Rajahmundry',
    alt: 'Interaction with Wooden Furniture Cluster Beneficiaries, Rajahmundry'
  },
  {
    image: 'assets/images/gallery/g8.jpeg',
    thumbImage: 'assets/images/gallery/g8.jpeg',
    title: 'Orientation programme for Printing Cluster, Vijayawada',
    alt: 'Orientation programme for Printing Cluster, Vijayawada'
  },
  {
    image: 'assets/images/gallery/g10.jpeg',
    thumbImage: 'assets/images/gallery/g10.jpeg',
    title: 'Interaction with Pulses Cluster members, Machavaram (Konaseema)',
    alt: 'Interaction with Pulses Cluster members, Machavaram (Konaseema)'
  },
  ];
  constructor(config: NgbCarouselConfig) { 
    config.interval = 3000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = false;    
    window.scrollTo(0, 0)
   }

   
   // owl starts
   
   customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
   // owl ends
   ngOnInit(): void {
    AOS.init();
  }


  onSlide(slideEvent: NgbSlideEvent) {
    console.log(slideEvent.source);
    console.log(NgbSlideEventSource.ARROW_LEFT);
    console.log(slideEvent.paused);
    console.log(NgbSlideEventSource.INDICATOR);
    console.log(NgbSlideEventSource.ARROW_RIGHT);
  }

}

<section id="process" class="process">
    <!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css /> -->
    <div class="container">
    
        <div class="row">
        
            <div class="col-md-3 col-sm-6">
            
                <div class="serviceBox">
                
                    <div class="service-count">01</div>
                
                    <div class="service-icon">
                        <span><i class="fa fa-globe"></i></span>
                    </div>
                
                    <h3 class="title">Web Design</h3>
                
                    <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                    <a href="" class="read-more">read more</a>
                </div>
                </div>
        
            <div class="col-md-3 col-sm-6">
            
                <div class="serviceBox purple">
                
                    <div class="service-count">02</div>
                
                    <div class="service-icon">
                        <span><i class="fa fa-rocket"></i></span>
                    </div>
                
                    <h3 class="title">Web Development</h3>
                
                    <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                    <a href="" class="read-more">read more</a>
                </div>
                </div>
            </div>
    </div>
</section>

<div class="container p-3">
    <h3 class="notify mt-3">NOTIFICATIONS</h3>
    <ul class="nav nav-pills tab mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link tablinks active" id="pills-home-tab"
                data-toggle="pill" href="#pills-home" role="tab"
                aria-controls="pills-home" aria-selected="true">GO's</a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link tablinks" id="pills-profile-tab"
                data-toggle="pill" href="#pills-profile" role="tab"
                aria-controls="pills-profile" aria-selected="false">TENDERS</a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link tablinks" id="pills-contact-tab"
                data-toggle="pill" href="#pills-contact" role="tab"
                aria-controls="pills-contact" aria-selected="false">VACANCIES</a>
        </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
            aria-labelledby="pills-home-tab">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th class="text-nowrap">GO No.</th>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>RT.No. 315 </td>
                        <td>21-11-2019 </td>
                        <td>Industries, Infrastructure, Investment and Commerce
                            Department -Appointment of Sri R. Pavana Murthy,
                            ITS., as Chief Executive Officer, A.P. MSME
                            Development Corporation - Orders - Issued </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>MS.No. 108 </td>
                        <td class="text-nowrap">11-12-2019 </td>
                        <td>Industries, Infrastructure, Investment & Commerce
                            Department – Structure of A.P. Micro, Small and
                            Medium Enterprises Development Corporation –Orders -
                            Issued </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>RT.No. 2194 </td>
                        <td>07-10-2019 </td>
                        <td>CCS Officer – Transfer and Postings – Transfer and
                            Posting of Sri R. Pavana Murthy, ITS - Orders –
                            Notified </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>MS.No. 130 </td>
                        <td>16-11-2018 </td>
                        <td>Industries & Commerce Department – A.P. Micro Small
                            and Medium Enterprises Development Corporation –
                            Creation of post of Chief Executive Officer(CEO) to
                            A.P. MSME Development Corporation- Amendment Orders-
                            Issued </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>MS.No. 109 </td>
                        <td>19-09-2018 </td>
                        <td>Industries and Commerce Department – A.P. Micro
                            Small and Medium Enterprise Development Corporation
                            –Creation of certain temporary posts in various
                            categories in A.P. Micro Small and Medium Enterprise
                            Development Corporation Orders Issued </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>MS.No. 109</td>
                        <td>19-09-2018 </td>
                        <td>Industries & Commerce Department – A.P. Micro Small
                            and Medium Enterprises Development Corporation –
                            Creation of post of Chief Executive Officer(CEO) to
                            A.P. MSME Development Corporation- Orders - Issued
                        </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>MS.No. 72 </td>
                        <td>07-06-2018 </td>
                        <td>Incorporation of the A.P. MSME Development
                            Corporation -Orders –Issued </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>MS.No. 72 </td>
                        <td>07-06-2018 </td>
                        <td>Incorporation of the A.P. MSME Development
                            Corporation -Orders –Issued </td>
                        <td class="view">View</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>MS.No. 71 </td>
                        <td>01-06-2018 </td>
                        <td>Industries and Commerce Department – A.P. Micro
                            Small and Medium Enterprise Development Corporation
                            –Creation of certain temporary posts in various
                            categories in A.P. Micro Small and Medium Enterprise
                            Development Corporation Orders Issued </td>
                        <td class="view">View</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
            aria-labelledby="pills-profile-tab"><h5 class="link">Latest Tenders</h5>
            <div class="d-flex mt-3">

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                    fill="currentColor" class="bi bi-check2-square" viewBox="0 0
                    16 16">
                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3
                        1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0
                        .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0
                        1-1.5 1.5H3z" />
                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293
                            5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708
                            0z" />
                        </svg>
                        <p class="ml-2">There are many vacancies of There are
                            many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable.</p>
                    </div>
                    <div class="d-flex mt-3 mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20"
                            height="20" fill="currentColor" class="bi
                            bi-check2-square" viewBox="0 0 16 16">
                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0
                                1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0
                                0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0
                                .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5
                                1.5H3z" />
                                <path d="m8.354 10.354 7-7a.5.5 0 0
                                    0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1
                                    0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                </svg>
                                <p class="ml-2"> All the Lorem Ipsum generators
                                    on the Internet tend to repeat predefined
                                    chunks as necessary, making this the first
                                    true generator on the Internet</p>
                            </div>
                            <div class="d-flex mt-3 mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="20" fill="currentColor"
                                    class="bi bi-check2-square" viewBox="0 0 16
                                    16">
                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5
                                        1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5
                                        0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0
                                        0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0
                                        1-1.5 1.5H3z" />
                                        <path d="m8.354 10.354 7-7a.5.5 0 0
                                            0-.708-.708L8 9.293 5.354 6.646a.5.5
                                            0 1 0-.708.708l3 3a.5.5 0 0 0 .708
                                            0z" />
                                        </svg>
                                        <p class="ml-2">
                                            It uses a dictionary of over 200
                                            Latin words, combined with a handful
                                            of model sentence structures, to
                                            generate Lorem Ipsum which looks
                                            reasonable.
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-contact"
                                    role="tabpanel"
                                    aria-labelledby="pills-contact-tab"> <h5
                                        class="link">List Of Vacancies</h5>
                                    <div class="d-flex mt-3">

                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="30" height="30"
                                            fill="currentColor" class="bi
                                            bi-check2-square" viewBox="0 0 16
                                            16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5
                                                13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5
                                                0 0 1 0 1H3a.5.5 0 0
                                                0-.5.5v10a.5.5 0 0 0
                                                .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5
                                                0 0 1 1 0v5a1.5 1.5 0 0 1-1.5
                                                1.5H3z" />
                                                <path d="m8.354 10.354 7-7a.5.5
                                                    0 0 0-.708-.708L8 9.293
                                                    5.354 6.646a.5.5 0 1
                                                    0-.708.708l3 3a.5.5 0 0 0
                                                    .708 0z" />
                                                </svg>
                                                <p class="ml-2">They are many
                                                    vacancies of There are many
                                                    variations of passages of
                                                    Lorem Ipsum available, but
                                                    the majority have suffered
                                                    alteration in some form, by
                                                    injected humour, or
                                                    randomised words which don't
                                                    look even slightly
                                                    believable.</p>
                                            </div>
                                            <div class="d-flex mt-3 mb-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="20"
                                                    fill="currentColor"
                                                    class="bi bi-check2-square"
                                                    viewBox="0 0 16 16">
                                                    <path d="M3 14.5A1.5 1.5 0 0
                                                        1 1.5 13V3A1.5 1.5 0 0 1
                                                        3 1.5h8a.5.5 0 0 1 0
                                                        1H3a.5.5 0 0
                                                        0-.5.5v10a.5.5 0 0 0
                                                        .5.5h10a.5.5 0 0 0
                                                        .5-.5V8a.5.5 0 0 1 1
                                                        0v5a1.5 1.5 0 0 1-1.5
                                                        1.5H3z" />
                                                        <path d="m8.354 10.354
                                                            7-7a.5.5 0 0
                                                            0-.708-.708L8 9.293
                                                            5.354 6.646a.5.5 0 1
                                                            0-.708.708l3 3a.5.5
                                                            0 0 0 .708 0z" />
                                                        </svg>
                                                        <p class="ml-2"> All the
                                                            Lorem Ipsum
                                                            generators on the
                                                            Internet tend to
                                                            repeat predefined
                                                            chunks as necessary,
                                                            making this the
                                                            first true generator
                                                            on the Internet</p>
                                                    </div>
                                                    <div class="d-flex mt-3
                                                        mb-3">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            fill="currentColor"
                                                            class="bi
                                                            bi-check2-square"
                                                            viewBox="0 0 16 16">
                                                            <path d="M3 14.5A1.5
                                                                1.5 0 0 1 1.5
                                                                13V3A1.5 1.5 0 0
                                                                1 3 1.5h8a.5.5 0
                                                                0 1 0 1H3a.5.5 0
                                                                0 0-.5.5v10a.5.5
                                                                0 0 0
                                                                .5.5h10a.5.5 0 0
                                                                0 .5-.5V8a.5.5 0
                                                                0 1 1 0v5a1.5
                                                                1.5 0 0 1-1.5
                                                                1.5H3z" />
                                                                <path d="m8.354
                                                                    10.354
                                                                    7-7a.5.5 0 0
                                                                    0-.708-.708L8
                                                                    9.293 5.354
                                                                    6.646a.5.5 0
                                                                    1
                                                                    0-.708.708l3
                                                                    3a.5.5 0 0 0
                                                                    .708 0z" />
                                                                </svg>
                                                                <p class="ml-2">
                                                                    It uses a
                                                                    dictionary
                                                                    of over 200
                                                                    Latin words,
                                                                    combined
                                                                    with a
                                                                    handful of
                                                                    model
                                                                    sentence
                                                                    structures,
                                                                    to generate
                                                                    Lorem Ipsum
                                                                    which looks
                                                                    reasonable.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>






                                                </div>

                                               
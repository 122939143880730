<section class="bodyBreadcrumbs">

    <div class="container">

        <div class="row breadcrumbsrow align-items-center">
            <div class="col-md-12">
                <div class="bodyHeading breadcrumbheading" style="color: #fff">
                    <h1>PMEGP</h1>
                    <ol class="breadcrumb p-0">
                        <li><a routerLink="/home">Home</a></li>
                        <li><a style="color: #fff;">Schemes</a></li>
                        <li><a style="color: #fff;">Prime Minister Employment Generation Programme (PMEGP)</a></li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="section_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="content">
                    <ul>
                        <li>
                            IS A Credit linked  Central sector subsidy scheme aims at employment generation
                        </li>
                        <li>
                            Extended up to 2025-26
                        </li>
                        <li>
                            DICs, KVIC, KVIB are the implementing agencies
                        </li>
                        <li>
                            Manufacturing , Service and Transport sectors are eligible
                        </li>
                        <li>
                            Eligibility Conditions for Beneficiaries for New units
                            <ul class="py-2" style="list-style-type: none;">
                                <li>
                                    1.	Above 18 years of age
                                </li>
                                <li>
                                    2.	No income ceiling
                                </li>
                                <li>
                                    3.	For Manufacturing units above Rs 10 lakhs or service units above Rs 5 lakhs 8th pass is mandatory
                                </li>
                                <li>
                                    4.	Only one person per one family eligible for assistance under PMEGP
                                </li>
                            </ul>
                        </li>
                        <li>
                            Eligibility Conditions for Beneficiaries for  Expansion  units
                            <ul class="py-2" style="list-style-type: none;">
                                <li>
                                    1.	The original unit should be Profit Making
                                </li>
                                <li>
                                    2.	Margin Money has to be  adjusted in to the  unit’s  account after successful completion  of 3 years lock in period
                                </li>
                                <li>
                                    3.	First Loan has to be repaid in time
                                </li>
                            </ul>
                        </li>
                        <li>
                            The funding Pattern is as follows
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered" style="width: 100%;">
                        <thead>
                            <tr>
                                <th>Type of unit</th>
                                <th>
                                    Manufacturing Sector
                                </th>
                                <th>Service sector</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>New unit</td>
                                <td>Max Project cost is Rs. 50 lakhs</td>
                                <td>Max Project cost is Rs. 20 lakhs</td>
                            </tr>
                            <tr>
                                <td>Expansion unit</td>
                                <td>Max Project cost is Rs.100 lakhs</td>
                                <td>Max Project cost is Rs. 25 lakhs</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            <div class="col-md-12">
                <div class="content">
                    <ul>
                        <li>
                            The Subsidy details  are as follows
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2">Social Category</th>
                                <th rowspan="2">Beneficiary Contribution for  New unit</th>
                                <th rowspan="2">Beneficiary Contribution for Expansion  unit</th>
                                <th colspan="6">
                                    Eligible Subsidy for New units
                                </th>
                                <th colspan="6">Eligible Subsidy for  Expansion  units</th>
                            
                                
                            </tr>
                            <tr>

                                <th colspan="3">Rural Area</th>
                                <th colspan="3">Urban Area</th>
                                <th colspan="3">Rural Area</th>
                                <th colspan="3">Urban Area</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>General</td>
                                <td>10%</td>
                                <td>10%</td>
                                <td colspan="3">25%</td>
                                <td colspan="3">15%</td>
                                <td colspan="3">15%</td>
                                <td colspan="3">15%</td>
                            </tr>
                            <tr>
                                <td>Special</td>
                                <td>5%</td>
                                <td>10%</td>
                                <td colspan="3">35%</td>
                                <td colspan="3">25%</td>
                                <td colspan="3">15%</td>
                                <td colspan="3">15%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12">
                <div class="content">
                    <ul>
                        <li>All activities except those in the negative list are eligible</li>
                        <li>
                            For further details visit, PMEGP e-portal  (or ) <a href="https://www.kviconline.gov.in/" target="_blank">www.kviconline.gov.in</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
